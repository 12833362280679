import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { useTranslation } from "hooks/use-translate";
import { numberAsSwedishCurrency } from "libs/number-format";
import { useServiceMatrix } from "libs/service-matrix";
import { WarningMessage } from "models/offer/ServiceLine";

type Props = {
  handleCloseAdjustPriceModal: () => void;
  showPriceAdjustmentModal: string;
}

export default function PriceAdjustmentModal ({
  handleCloseAdjustPriceModal,
  showPriceAdjustmentModal
}:Props) {
const { translate } = useTranslation();

  const {
    GetLowestPossiblePrice,
    GetIsPriceAutoAdjusted,
  } = useServiceMatrix();

 const headerText = GetIsPriceAutoAdjusted() ? "ADJUST_PRICE" : "WARNING";
 const lowestPrice = GetLowestPossiblePrice();

const getPriceAdjustmentModalContent = () => {
    return (
      <>
        {showPriceAdjustmentModal === WarningMessage.AUTO_ADJUST && (
          <div>
            <p className="fw-600 fpx-18">
              {translate("ADJUST_PRICE_MODAL.PRICE_CANNOT_BE_LOWER")}
              <span className="text-color-yellow-dark fpx-24">
                {` ${numberAsSwedishCurrency(lowestPrice ?? 0)}`}
              </span>
            </p>
            <p className="fpx-16">
              {translate("ADJUST_PRICE_MODAL.PRICE_IS_AUTO_ADJUSTED")}
            </p>
          </div>
        )}
        {showPriceAdjustmentModal === WarningMessage.HIGHER_PRICE && (
          <p className="fpx-16">
            {translate("ADJUST_PRICE_MODAL.PRICE_IS_HIGHER_THAN_PROPOSED")}
          </p>
        )}
        {showPriceAdjustmentModal === WarningMessage.LOWER_PRICE && (
          <p className="fpx-16">
            {translate("SUMMARY_PAGE.LOWEST_POSSIBLE_PRICE", [
              numberAsSwedishCurrency(lowestPrice ?? 0),
            ])}
          </p>
        )}
      </>
    );
  };

  return (
    <Modal
    header={<ModalHeader headerTitleText={headerText} />}
    footer={
      <ModalFooter
        hideSubmit
        labelSubmit=""
        onSave={() => {}}
        onCancel={handleCloseAdjustPriceModal}
        labelCancel="OK"
      />
    }
    isOpen={showPriceAdjustmentModal !== ""}
    onDismiss={handleCloseAdjustPriceModal}
  >
    {getPriceAdjustmentModalContent()}
  </Modal>
  )
}