import { useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { RootState } from "state";
import { useAppDispatch } from "state/use-app-redux";
import { updateServicesAndPostDealData } from "state/offer/offersThunks";
import PendingChangesModal from "components/modal/PendingChangesModal";
import { updateCurrentOffer } from "state/offer/offersSlice";
import { Frequency } from "models/offer/Frequency";
import { ServiceValidity } from "models/offer/ServiceValidity";
import {
  Service,
  ServiceArea,
  ServiceCategory,
  ServiceGroupItem,
} from "models/offer/ServiceLine";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import FinalPriceModal from "views/createNew/offer/wizard/CurrentAccounting/components/FinalPriceModal";
import { ServicesContext } from "views/createNew/offer/wizard/Services/ServicesContextProvider";
import { SERVICE_GROUP_CURRENT_ACCOUNTING } from "views/createNew/offer/wizard/consts/offer-contst";
import { useRoutingForOffer } from "views/createNew/offer/components/wizardSection/useRoutingForOffer";
import { OfferRouteHelper } from "views/createNew/offer/routes/offerRoutes";
import {
  TOGGLE_DATE,
  TOGGLE_INT,
  TOGGLE_MULTI_SELECT,
  TOGGLE_SINGLE_SELECT,
  TOGGLE_SINGLE_SELECT_LOOKUP,
  TOGGLE_STRING,
} from "constants/offer/input-form-types";
import useValidateSmallRecurringCustomersServices from "hooks/createNew/offer/use-validate-small-recurring-customers-services";
import { FREQUENCY_ARRAY } from "views/createNew/offer/wizard/components/service-details-header/index";
import { CurrentAccountingForm } from "views/createNew/offer/wizard/CurrentAccountingWrapper/CurrentAccountingWrapper";
import CurrentAccountingServiceDetailsForm from "views/createNew/offer/wizard/CurrentAccountingWrapper/components/CurrentAccountingServiceDetailsForm";

type Props = {
  groupedServices: ServiceGroupItem[];
  pendingChangesModalOpen: boolean;
  setPendingChangesModalOpen: (pendingChangesModalOpen: boolean) => void;
  getServiceLineWithCurrentAccountingData: (data?: CurrentAccountingForm) => {
    service_areas: ServiceArea[];
  };
  setIsDirty: (value: React.SetStateAction<boolean>) => void;
  currentAccountingServices: Service[];
  currentAccountingData: CurrentAccountingForm;
  currentAccountingInCurrentOffer: ServiceCategory;
  updateServiceCategoryData: (
    formHeaderData?: Partial<CurrentAccountingForm>
  ) => void;
  setCurrentAccountingServices: React.Dispatch<React.SetStateAction<Service[]>>;
  serviceGroup: string;
  servicesValidity: ServiceValidity[];
  setServicesValidity: (servicesValidity: ServiceValidity[]) => void;
  selectedHeader: string;
  setSelectedHeader: (selectedHeader: string) => void;
  modalOpen: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentAccountingData: React.Dispatch<
    React.SetStateAction<CurrentAccountingForm>
  >;
};

export default function CurrentAccountingServiceDetails({
  groupedServices,
  pendingChangesModalOpen,
  setPendingChangesModalOpen,
  getServiceLineWithCurrentAccountingData,
  setIsDirty,
  currentAccountingServices,
  currentAccountingData,
  currentAccountingInCurrentOffer,
  updateServiceCategoryData,
  servicesValidity,
  selectedHeader,
  setSelectedHeader,
  modalOpen,
  setOpenModal,
  setCurrentAccountingServices,
  setServicesValidity,
  setCurrentAccountingData,
  serviceGroup,
}: Props) {
  const { data: currentOffer, status: loadingStatus } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  if (!currentOffer) {
    throw new Error("No active offer");
  }
  const location = useLocation();
  const dispatch = useAppDispatch();
  const routing = useRoutingForOffer(location.pathname, currentOffer);
  const { updateServiceGroups } = useContext(ServicesContext);
  const navigate = useNavigate();
  const { edit } = useParams<{ edit: string }>();
  const initialCurrentOffer = useRef(currentOffer);
  const { validateNumericInput, validateTextInput } =
    useValidateSmallRecurringCustomersServices();

  useEffect(() => {
    setServicesValidity(
      currentAccountingServices.map((service) => {
        const isFrequencyValid = service.is_pog_service_frequency
          ? !!service.pog_service_frequency
          : true;

        let isValueValid = true;

        switch (service.input_form_type) {
          case TOGGLE_SINGLE_SELECT:
          case TOGGLE_MULTI_SELECT:
          case TOGGLE_SINGLE_SELECT_LOOKUP:
          case TOGGLE_DATE:
            isValueValid = !!service.input_form_type_value;
            break;

          case TOGGLE_INT:
            if (!service.input_form_type_value) {
              isValueValid = false;
            } else {
              isValueValid = validateNumericInput(
                service.input_form_type_value,
                service
              );
            }
            break;

          case TOGGLE_STRING:
            if (!service.input_form_type_value) {
              isValueValid = false;
            } else {
              isValueValid = validateTextInput(
                service.input_form_type_value,
                service
              );
            }
            break;

          default:
        }

        return {
          serviceMatrixId: service.service_matrix_id,
          serviceHeader: service.header,
          isValueValid,
          isFrequencyValid,
        };
      })
    );

    updateHeaderFrequency();

    // Sets the highest Frequency occurrence found in all the services.
    // "highest" in a way that it occurs more frequently.
    function updateHeaderFrequency() {
      let headerFreq = "";

      currentAccountingServices
        .map((service) => service.pog_service_frequency)
        .filter((serviceFreq) => serviceFreq)
        .forEach((serviceFreq) => {
          if (!headerFreq) {
            headerFreq = serviceFreq;
          } else {
            const headerFreqIndex = FREQUENCY_ARRAY.indexOf(
              headerFreq as Frequency
            );
            const serviceFreqIndex = FREQUENCY_ARRAY.indexOf(
              serviceFreq as Frequency
            );

            if (serviceFreqIndex < headerFreqIndex) {
              headerFreq = serviceFreq;
            }
          }
        });

      // The greatest allowed Frequency occurrence in the header is 'quarterly'.
      if (headerFreq === "yearly") {
        headerFreq = "quarterly";
      }

      // The lowest allowed Frequency occurrence in the header is 'monthly'.
      // 'monthly' is also the default value, should for some reason the value be empty.
      if (!headerFreq || headerFreq === "weekly") {
        headerFreq = "monthly";
      }

      setCurrentAccountingData((data) => {
        if (data.frequency !== headerFreq) {
          setIsDirty(true);

          return {
            ...data,
            frequency: headerFreq as Frequency,
          };
        }

        return data;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccountingServices]);

  const updateServiceContext = () => {
    const serviceGroupToUpdate =
      getServiceLineWithCurrentAccountingData().service_areas[0].service_lines[0].service_groups.find(
        (group) => group.name === SERVICE_GROUP_CURRENT_ACCOUNTING
      );

    if (serviceGroupToUpdate) {
      updateServiceGroups(serviceGroupToUpdate);
    }
  };

  const handleSave = async () => {
    await dispatch(
      updateServicesAndPostDealData(getServiceLineWithCurrentAccountingData())
    );

    updateServiceContext();

    setIsDirty(false);
    setPendingChangesModalOpen(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const handleDiscard = () => {
    dispatch(updateCurrentOffer(initialCurrentOffer.current));
    setIsDirty(false);
    setPendingChangesModalOpen(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const saveOffer = async (newPrice = 0) => {
    setOpenModal(false);

    const newServiceCategoryData = {
      ...currentAccountingData,
      finalPrice: newPrice,
    };

    updateServiceCategoryData(newServiceCategoryData);

    await dispatch(
      updateServicesAndPostDealData(
        getServiceLineWithCurrentAccountingData(newServiceCategoryData)
      )
    );
  };

  const handlePriceCancelModal = (newPrice: number) => {
    saveOffer(newPrice);
    setIsDirty(false);
  };

  const handleProceedToSummary = async (newPrice: number) => {
    await saveOffer(newPrice);
    updateServiceContext();
    navigate(OfferRouteHelper.getSummary());
  };

  return (
    <>
      <PendingChangesModal
        isOpen={pendingChangesModalOpen}
        onCancel={() => setPendingChangesModalOpen(false)}
        onSave={() => handleSave()}
        onDiscard={() => handleDiscard()}
        isValid={
          !!currentAccountingServices.length &&
          !!currentAccountingData.frequency
        }
        isLoading={loadingStatus === LoadingStatusEnum.PENDING}
      />

      <CurrentAccountingServiceDetailsForm
        groupedServices={groupedServices}
        servicesValidity={servicesValidity}
        selectedHeader={selectedHeader}
        setSelectedHeader={setSelectedHeader}
        currentAccountingServices={currentAccountingServices}
        setCurrentAccountingServices={setCurrentAccountingServices}
        currentAccountingInCurrentOffer={currentAccountingInCurrentOffer}
        serviceGroup={serviceGroup}
        setIsDirty={setIsDirty}
      />
      {modalOpen && (
        <FinalPriceModal
          finalPrice={currentAccountingInCurrentOffer.final_price}
          setPriceModalOpen={setOpenModal}
          handlePriceCancelModal={handlePriceCancelModal}
          handleProceedToSummary={handleProceedToSummary}
        />
      )}
    </>
  );
}
