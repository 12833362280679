import { ServiceLine } from "./ServiceLine";

export class CustomerDealServiceAreas {
  id: string;

  deal_id: string;

  name: string;

  service_lines: ServiceLine[];
}
