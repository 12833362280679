import { SolidAccordion } from "components/accordion/solidAccordion";
import { Appendix } from "models/offer/CustomerDeal";
import ContractTexts from "./ContractTexts";

type ContractTextsSectionProps = {
  title: string;
  contractTexts: Appendix[];
};

export default function ContractTextsSection({
  title,
  contractTexts,
}: ContractTextsSectionProps) {
  const subtitles = contractTexts.map(
    (contractText) => contractText.appendix_title
  );
  return (
    <SolidAccordion
      title={title}
      subtitles={subtitles}
      limitSubtitles={subtitles.length}
    >
      <ContractTexts contractTexts={contractTexts} />
    </SolidAccordion>
  );
}
