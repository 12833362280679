import { Spinner } from "@fluentui/react-components";
import { Edit20Regular, Warning20Regular } from "@fluentui/react-icons";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import ContextualMenu from "components/contextualMenu/ContextualMenu";
import { Dropdown } from "components/dropdown";
import { SpinnerSize } from "components/spinner";
import Switch from "components/switch";
import { useTranslation } from "hooks";
import { isPlaceholderEmail } from "libs/generate-placeholder-email";
import { isValidSwedishSSN } from "libs/is-valid-ssn";
import { useServiceMatrix } from "libs/service-matrix";
import {
  DealContact,
  SigningMethod,
  SigningMethodToPrettyString,
} from "models/offer/DealContact";
import DealContactDetails from "models/offer/DealContactDetails";
import { updateOfferContact } from "state/offer/offersSlice";
import { AppDispatch } from "state/use-app-redux";
import { RootState } from "state";

type ContactsListProps = {
  contactDetails: DealContactDetails;
  onPressEdit: () => void;
  loading: boolean;
};

export default function ContactListItem({
  contactDetails,
  onPressEdit,
  loading,
}: ContactsListProps) {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { IsSignerValid } = useServiceMatrix();

  const currentOfferContacts = useSelector(
    (state: RootState) => state.offers.currentOffer.data?.contacts
  );

  const currentOfferContactSigner = currentOfferContacts?.find(
    (currentOfferContact) => currentOfferContact.is_signer
  );

  const contact =
    contactDetails.email &&
    contactDetails.email === currentOfferContactSigner?.contact.email
      ? currentOfferContactSigner
      : undefined;

  function renderIcon(icon: JSX.Element) {
    return icon;
  }

  const actionsItems = [
    {
      text: translate("EDIT"),
      icon: () => renderIcon(<Edit20Regular />),
      onClick: () => onPressEdit(),
    },
  ];

  const contactHasInvalidEmail = contactDetails.email
    ? isPlaceholderEmail(contactDetails.email)
    : true;

  const updateDealContact = (
    isSigner: boolean,
    signingMethod: SigningMethod = SigningMethod.BANKID
  ) => {
    const tempDealContact = new DealContact({
      id: contact?.id,
      is_signer: isSigner,
      signing_method: contactHasInvalidEmail
        ? SigningMethod.PHYSICAL
        : signingMethod,
      contact: contactDetails,
    });

    dispatch(updateOfferContact(tempDealContact));
  };

  const handleToggleSigner = (isChecked: boolean | undefined) => {
    // If we're missing required info to toggle signer, open modal
    if (
      !contactDetails.social_security_number &&
      isValidSwedishSSN(contactDetails.social_security_number)
    ) {
      onPressEdit();
      return;
    }

    updateDealContact(!!isChecked);
  };

  const handleSigningMethod = (signingMethod: SigningMethod) => {
    updateDealContact(true, signingMethod);
  };

  const isContactValid = () => {
    if (contact?.is_signer) {
      return IsSignerValid(contact);
    }

    return true;
  };

  const getSigningOptions = () => {
    const bankIdOption = {
      value: SigningMethod.BANKID,
      label: SigningMethodToPrettyString(SigningMethod.BANKID),
    };

    const physicalOption = {
      value: SigningMethod.PHYSICAL,
      label: SigningMethodToPrettyString(SigningMethod.PHYSICAL),
    };

    return [...(!contactHasInvalidEmail ? [bankIdOption] : []), physicalOption];
  };

  return (
    <div className="border-bottom py-2">
      <Row className="align-items-center">
        <Col md={2} className="stringLength">
          {`${contactDetails.first_name} ${contactDetails.last_name}`}
        </Col>
        <Col md={3} className="stringLength">
          {contactDetails.email}
        </Col>
        <Col md={2}>
          {isContactValid() ? (
            contactDetails.social_security_number
          ) : (
            <span>
              <Warning20Regular className="text-color-red mr-xs" />
              {translate("MISSING_SSN")}
            </span>
          )}
        </Col>
        <Col md={1}>{contactDetails.phone_number}</Col>
        <Col md={1}>{contactDetails.mobile_phone}</Col>
        <Col md={1}>
          <Switch
            label=""
            checked={contact && isContactValid() ? contact.is_signer : false}
            onToggleMethod={(e, { checked }) => handleToggleSigner(checked)}
          />
        </Col>
        <Col md={1}>
          {contact && isContactValid() && (
            <Dropdown
              className="ms-2"
              variant="inline"
              value={
                contactHasInvalidEmail
                  ? SigningMethod.PHYSICAL
                  : contact.signing_method
              }
              onChange={(signingMethod) => {
                handleSigningMethod(signingMethod);
              }}
              options={getSigningOptions()}
            />
          )}
        </Col>
        <Col xs={1} className="d-flex justify-content-center">
          {loading ? (
            <Spinner className="p-sm" size={SpinnerSize.ExtraSmall} />
          ) : (
            <ContextualMenu menuItems={actionsItems} />
          )}
        </Col>
      </Row>
    </div>
  );
}
