import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { DateRangeType } from "@fluentui/react-calendar-compat";
import { DatePicker } from "components/date/DatePicker";
import { Dropdown as CustomDropdown } from "components/dropdown";
import { Dropdown } from "components/dropdown/Dropdown";
import { Input } from "components/input";
import {
  TOGGLE_DATE,
  TOGGLE_INT,
  TOGGLE_MULTI_SELECT,
  TOGGLE_SINGLE_SELECT,
  TOGGLE_SINGLE_SELECT_LOOKUP,
  TOGGLE_STRING,
} from "constants/offer/input-form-types";
import { useTranslation } from "hooks/use-translate";
import { Service, ServiceContentLookupOption } from "models/offer/ServiceLine";
import { prependWarningIcon } from "../service-row";

interface ServiceRowValueFieldProps {
  service: Service;
  serviceIsToggled: boolean;
  updateService: (key: string, value: string | number) => void;
  validateNumericInput: (value: string, service: Service) => boolean;
  validateTextInput: (value: string, service: Service) => boolean;
  isInputFocused: boolean;
  onInputBlur: () => void;
}

export default function ServiceRowValueField({
  service,
  serviceIsToggled,
  updateService,
  validateNumericInput,
  validateTextInput,
  isInputFocused,
  onInputBlur,
}: ServiceRowValueFieldProps) {
  const { translate, dateFormatter } = useTranslation();

  const [intValue, setIntValue] = useState(service.input_form_type_value);

  const [firstAccountingMonth, setFirstAccountingMonth] = useState<
    Date | null | undefined
  >(null);

  const {
    register,
    formState: { errors },
    setValue,
    reset,
    trigger,
  } = useForm<Service>({
    mode: "all",
  });

  useEffect(() => {
    if (serviceIsToggled) {
      setValue("input_form_type_value", service.input_form_type_value, {
        shouldValidate: true,
      });
    }
  }, [serviceIsToggled, service.input_form_type_value, setValue]);

  useEffect(() => {
    if (serviceIsToggled) {
      trigger();
    } else {
      reset();
    }
  }, [serviceIsToggled, reset, trigger]);

  let min: number | undefined;
  let max: number | undefined;

  if (
    service.input_form_type === TOGGLE_INT &&
    service.input_form_type_content
  ) {
    const splitContent = service.input_form_type_content.split(":");

    /**
     * If the received content is not in the expected format of "number:number",
     * then we don't know how to handle it.
     */
    if (splitContent.length === 2) {
      min = parseInt(splitContent[0], 10);
      max = parseInt(splitContent[1], 10);
    }
  }

  const generateNumericInputInfo = () => {
    const placeholder = translate("ENTER_NUMBER");

    if (!min && max) {
      return `${placeholder} ${translate("LESSER_OR_EQUAL_TO", [
        max.toString(),
      ])}`;
    }
    if (min && !max) {
      return `${placeholder} ${translate("GREATER_OR_EQUAL_TO", [
        min.toString(),
      ])}`;
    }
    if (min && max) {
      return `${placeholder} ${translate("BETWEEN_AND", [
        min.toString(),
        max.toString(),
      ])}`;
    }

    return placeholder;
  };

  const convertStringToArray = (content: string) => {
    const jsonString = content.replace(/'/g, '"');

    return JSON.parse(jsonString);
  };

  const getLookupOptions = (serviceContent: string) => {
    const lookupOptions: ServiceContentLookupOption[] =
      convertStringToArray(serviceContent);

    return lookupOptions.map((option: ServiceContentLookupOption) => ({
      value: option.output,
      label: option.value,
    }));
  };

  const updateServiceValue = (value: string, currentService?: Service) => {
    if (currentService?.input_form_type === TOGGLE_SINGLE_SELECT_LOOKUP) {
      const lookupOptions = getLookupOptions(service.input_form_type_content);
      const output = lookupOptions.find(
        (option) => option.value === value
      )?.label;
      if (output) {
        // for TOGGLE_SINGLE_SELECT_LOOKUP we need to set the value as unit_price and the label as the input_form_type_value
        setValue("input_form_type_value", output, {
          shouldValidate: true,
        });
        updateService("input_form_type_value", output);
        setValue("unit_price", parseInt(value, 10));
        updateService("unit_price", parseInt(value, 10));
      }
    } else {
      setValue("input_form_type_value", value, {
        shouldValidate: true,
      });

      updateService("input_form_type_value", value);
    }
  };

  const handleOnInputChange = (
    e: string | React.ChangeEvent<HTMLInputElement>,
    currentService?: Service
  ) => {
    const val = typeof e === "string" ? e : e.target.value;
    setIntValue(val);

    updateServiceValue(val, currentService);
  };

  const handleOnDateInputChange = (
    date: Date | null | undefined,
    currentService?: Service
  ) => {
    setFirstAccountingMonth(date);
    updateServiceValue(date?.toISOString() ?? "", currentService);
  };

  const handleOnMultiselectChange = (
    isSelected: boolean,
    optionValue?: string
  ) => {
    if (!optionValue) {
      return;
    }

    const currentValues = service.input_form_type_value.split(";");

    let newValues = "";

    if (isSelected) {
      newValues = [...currentValues, optionValue as string]
        .filter((value) => value)
        .join(";");
    } else {
      newValues = currentValues
        .filter((value) => value && value !== optionValue)
        .join(";");
    }

    updateServiceValue(newValues);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !/^[0-9]$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight" &&
      e.key !== "Tab"
    ) {
      e.preventDefault();
    }
  };

  const getServiceMinDate = (serviceContent: string) => {
    const serviceContentObj = JSON.parse(serviceContent);
    const [month, year] = serviceContentObj.min_date.split("/").map(Number);
    const minDate = new Date(year, month - 1, 1);
    return minDate;
  };

  const getServiceMaxDate = (serviceContent: string) => {
    const serviceContentObj = JSON.parse(serviceContent);
    const [month, year] = serviceContentObj.max_date.split("/").map(Number);
    const minDate = new Date(year, month - 1, 1);
    return minDate;
  };

  switch (service.input_form_type) {
    case TOGGLE_INT:
      return (
        <Input
          key={`${service.service_matrix_id}${serviceIsToggled}Int`}
          type="number"
          step="1"
          placeholder={generateNumericInputInfo()}
          isFocused={isInputFocused}
          onBlur={onInputBlur}
          disabled={!serviceIsToggled}
          min={min || undefined}
          max={max || undefined}
          value={intValue}
          errorMessage={errors.input_form_type_value?.message}
          formRegister={register("input_form_type_value", {
            required: prependWarningIcon(translate("FORM_FIELD_REQUIRED")),
            validate: (value) =>
              validateNumericInput(value, service) ||
              prependWarningIcon(generateNumericInputInfo()),
          })}
          onChange={handleOnInputChange}
          onKeyDown={handleKeyDown}
        />
      );

    case TOGGLE_STRING:
      return (
        <Input
          key={`${service.service_matrix_id}${serviceIsToggled}String`}
          type="text"
          placeholder={translate("ENTER_ALPHANUMERIC_CHARACTERS")}
          isFocused={isInputFocused}
          onBlur={onInputBlur}
          disabled={!serviceIsToggled}
          value={service.input_form_type_value}
          errorMessage={errors.input_form_type_value?.message}
          formRegister={register("input_form_type_value", {
            required: prependWarningIcon(translate("FORM_FIELD_REQUIRED")),
            validate: (value) =>
              validateTextInput(value, service) ||
              prependWarningIcon(translate("ENTER_ALPHANUMERIC_CHARACTERS")),
          })}
          onChange={handleOnInputChange}
        />
      );

    case TOGGLE_SINGLE_SELECT:
      return (
        <CustomDropdown
          key={`${service.service_matrix_id}${serviceIsToggled}SingleSelect`}
          className="px-0 mx-0"
          placeholder={translate("DROPDOWN_PLACEHOLDER")}
          isFocused={isInputFocused}
          onBlur={onInputBlur}
          disabled={!serviceIsToggled}
          value={service.input_form_type_value}
          options={
            service.input_form_type_content
              ? service.input_form_type_content
                  .split(";")
                  .filter((value) => value)
                  .map((value) => {
                    return {
                      value,
                      label: value,
                    };
                  })
              : []
          }
          onChange={(e) => handleOnInputChange(e)}
          errorMessage={errors.input_form_type_value?.message}
          formRegister={register("input_form_type_value", {
            required: prependWarningIcon(translate("FORM_FIELD_REQUIRED")),
          })}
        />
      );

    case TOGGLE_MULTI_SELECT:
      return (
        <Dropdown
          key={`${service.service_matrix_id}${serviceIsToggled}MultiSelect`}
          placeholder={translate("DROPDOWN_PLACEHOLDER")}
          autoFocus={isInputFocused}
          onBlur={onInputBlur}
          disabled={!serviceIsToggled}
          selectedOptions={service.input_form_type_value?.split(";")}
          options={
            service.input_form_type_content
              ? service.input_form_type_content
                  .split(";")
                  .filter((value) => value)
                  .map((value) => {
                    return {
                      text: value,
                      value,
                    };
                  })
              : []
          }
          onOptionSelect={(_, { optionValue }, isSelected) =>
            handleOnMultiselectChange(isSelected, optionValue)
          }
          errorMessage={errors.input_form_type_value?.message}
          formRegister={register("input_form_type_value", {
            required: prependWarningIcon(translate("FORM_FIELD_REQUIRED")),
          })}
        />
      );
    case TOGGLE_SINGLE_SELECT_LOOKUP: {
      return (
        // for lookup fields, the option value is stored as unit_price
        // input_form_type_content keeps the whole lookup list
        // input_form_type_value keeps the label of the option
        <CustomDropdown
          key={`${service.service_matrix_id}${serviceIsToggled}SingleSelect`}
          className="px-0 mx-0"
          placeholder={translate("DROPDOWN_PLACEHOLDER")}
          isFocused={isInputFocused}
          onBlur={onInputBlur}
          disabled={!serviceIsToggled}
          value={service.unit_price.toString()}
          options={getLookupOptions(service.input_form_type_content)}
          onChange={(e) => handleOnInputChange(e, service)}
          errorMessage={errors.input_form_type_value?.message}
          formRegister={register("input_form_type_value", {
            required: prependWarningIcon(translate("FORM_FIELD_REQUIRED")),
          })}
        />
      );
    }
    case TOGGLE_DATE: {
      return (
        <DatePicker
          className="w-70"
          dateFormatter={dateFormatter}
          calendarProps={{
            dateRangeType: DateRangeType.Month,
            isDayPickerVisible: false,
          }}
          placeholder={translate("SELECT_MONTH")}
          onSelectDate={(date) => handleOnDateInputChange(date, service)}
          showGoToToday={false}
          minDate={getServiceMinDate(service.input_form_type_content)}
          maxDate={getServiceMaxDate(service.input_form_type_content)}
          selectedDate={
            service.input_form_type_value
              ? new Date(service.input_form_type_value)
              : firstAccountingMonth
          }
          formRegister={register("input_form_type_value", {
            required: prependWarningIcon(translate("FORM_FIELD_REQUIRED")),
          })}
          errorMessage={errors.input_form_type_value?.message}
        />
      );
    }

    default:
      return null;
  }
}
