import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

import { RootState } from "state";
import { useTranslation } from "hooks/use-translate";
import DealContactDetails from "models/offer/DealContactDetails";
import ContactListItem from "./ContactsListItem";

type ContactsListProps = {
  onPressEditContact: (contact: DealContactDetails) => void;
  loadingDataFor: string;
  activePage: number;
  itemsShownPerPage: number;
};

export function ContactsList({
  onPressEditContact,
  loadingDataFor,
  activePage,
  itemsShownPerPage,
}: ContactsListProps) {
  const { translate } = useTranslation();
  const offers = useSelector((state: RootState) => state.offers);
  const companyContacts = offers.availableCompanyContacts.data;
  const currentOffer = offers.currentOffer.data;

  if (!currentOffer) {
    return <>No offer found</>;
  }

  const activePageObjects = companyContacts.slice(
    (activePage - 1) * itemsShownPerPage,
    activePage * itemsShownPerPage
  );

  const renderHeader = () => (
    <div className="border-bottom py-2">
      <Row>
        <Col md={2} className="fw-bold">
          {translate("NAME")}
        </Col>
        <Col md={3} className="fw-bold">
          {translate("EMAIL")}
        </Col>
        <Col md={2} className="fw-bold">
          {translate("SSN")}
        </Col>
        <Col md={1} className="fw-bold">
          {translate("PHONE")}
        </Col>
        <Col md={1} className="fw-bold">
          {translate("MOBILE")}
        </Col>
        <Col md={1} className="fw-bold" onClick={() => {}}>
          {translate("CONTRACT_SIGNER")}
        </Col>
        <Col md={1} className="fw-bold" onClick={() => {}}>
          {translate("SIGNING_METHOD")}
        </Col>
        <Col md={1} className="fw-bold d-flex justify-content-center">
          {translate("ACTIONS_SECTION")}
        </Col>
      </Row>
    </div>
  );

  return (
    <Col md={12}>
      {renderHeader()}
      {activePageObjects &&
        activePageObjects.map((contactDetails) => (
          <ContactListItem
            key={contactDetails.id}
            contactDetails={contactDetails}
            onPressEdit={() => onPressEditContact(contactDetails)}
            loading={contactDetails.id === loadingDataFor}
          />
        ))}
    </Col>
  );
}
