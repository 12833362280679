import {
  ArrowLeft20Regular,
  ArrowRight20Regular,
  Info20Regular,
} from "@fluentui/react-icons";
import { plainToClass } from "class-transformer";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Spinner } from "@fluentui/react-components";
import { RHFInput } from "components/input/RHFInput";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { SpinnerSize } from "components/spinner";
import {
  ACCOUNTING_SERVICE_LINE,
  EKONOMI_BUSINESS_AREA,
} from "constants/servicesConsts";
import { parseApplicationError } from "errors/errors";
import { usePogOffer } from "hooks/pogOffers/use-pog-offer";
import { useTranslation } from "hooks/use-translate";
import { capitalize } from "libs/capitalize";
import { hasValidOfferURL } from "libs/is-pog-deal";
import { useServiceMatrix } from "libs/service-matrix";
import { Deal } from "models/deals/deal";
import { DealCustomer } from "models/offer/Customer";
import { ServiceGroup } from "models/offer/ServiceLine";
import { RootState } from "state";
import {
  SearchBy,
  createOrUpdateCustomer,
  fetchCompanyInfoFromSales,
  fetchCustomerAndAddToOffer,
} from "state/offer/companyThunks";
import {
  addOfferSelectedServiceLine,
  addServiceLineGroup,
  setSelectedCustomerSource,
  updateCurrentOffer,
  updateOfferCustomer,
} from "state/offer/offersSlice";
import { fetchOfferTemplate, resetOffer } from "state/offer/offersThunks";
import {
  fetchDealById,
  fetchDealProperties,
  fetchProducts,
  resetCurrentDeal,
  setCurrentDeal,
} from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";
import { availableTogglesMeta } from "views/createNew/offer/components/servicePicker/ServicePickerHelper";
import { OfferRouteHelper } from "views/createNew/offer/routes/offerRoutes";

type CreatePogOfferFromHSDealProps = {
  onDismiss: () => void;
};

type HubspotDealToPogForm = {
  dealId: string;
};

export function CreatePogOfferFromHSDealModal({
  onDismiss,
}: CreatePogOfferFromHSDealProps) {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors, isValid },
    setValue,
  } = useForm<HubspotDealToPogForm>({
    mode: "all",
  });

  const {
    getServiceCategoryWithDefaultServices,
    createIncomeTaxObjectServices,
    createOrUpdateHSContactInPogOffer,
  } = usePogOffer();

  const [createPogOfferLoading, setCreatePogOfferLoading] = useState(false);
  const [isVerifyingDeal, setIsVerifyingDeal] = useState(false);
  const [hubspotDeal, setHubspotDeal] = useState<Deal>();
  const [dealExistInPog, setDealExistInPog] = useState(false);

  const { currentUser } = useSelector((state: RootState) => state.users);
  const {
    sales: {
      products: { data: products },
      dealOptions,
    },
  } = useSelector((state: RootState) => state);
  const { data: offerTemplate } = useSelector(
    (state: RootState) => state.offers.offerTemplate
  );
  const { data: currentOffer } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );

  useEffect(() => {
    const init = async () => {
      dispatch(fetchProducts());
      dispatch(fetchDealProperties());
    };

    init();
  }, [dispatch]);

  useEffect(() => {
    const getOfferTemplate = async () => {
      await dispatch(fetchOfferTemplate());
    };
    if (!offerTemplate) {
      getOfferTemplate();
    }
    dispatch(resetOffer());
  }, [offerTemplate, dispatch]);

  const offerCustomer = currentOffer?.customer;
  const dealCustomerUpdatedByUser = !offerCustomer?.created_by_username
    ? {
        created_by_username: currentUser.email,
        changed_by_username: currentUser.email,
      }
    : {
        changed_by_username: currentUser.email,
      };

  const dealsBusinessAreas = dealOptions.data.find(
    (dealOption) => dealOption.name === "affarsomrade_deal_"
  );

  const { GetAllServiceGroupsFlattened, GetAllServiceLinesFlattened } =
    useServiceMatrix();

  const serviceGroups = GetAllServiceGroupsFlattened(offerTemplate);
  const serviceLines = GetAllServiceLinesFlattened(offerTemplate);

  const mappedServiceGroups = serviceGroups
    .filter((group) => group.name)
    .map((group) => group.name.toLowerCase());

  const getMappedDealProducts = (deal: Deal) => {
    return deal.productIds.map(
      (productId) =>
        products
          ?.find((product) => product.id === productId)
          ?.description?.toLowerCase() ?? ""
    );
  };

  const dealIdValue = register("dealId", {
    validate: async (value) => {
      if (!value) {
        setHubspotDeal(undefined);
        dispatch(resetCurrentDeal());
        return false;
      }

      if (value.length < 10) {
        return translate("INVALID_VALUE");
      }

      setIsVerifyingDeal(true);
      try {
        const deal = await dispatch(fetchDealById(value));
        setHubspotDeal(deal);
        // needed in the POG flow
        dispatch(setCurrentDeal(deal));
        setDealExistInPog(hasValidOfferURL(deal));
        setIsVerifyingDeal(false);

        if (disableCreatePogOfferButton(deal)) {
          return translate("CREATE_POG_OFFER_DISABLED_TOOLTIP");
        }
        if (hasIncompatibleProductsSelected(deal)) {
          return translate("CREATE_POG_OFFER_INCOMPATIBLE_TOOLTIP");
        }
      } catch (error) {
        setIsVerifyingDeal(false);
        const parsedError = parseApplicationError(error as Error);
        if (parsedError?.args?.length && parsedError.args[0].includes("404")) {
          return translate("DEAL_DOES_NOT_EXIST_HUBSPOT");
        }
        throw error;
      }

      return true;
    },
  });

  const isDealWithEkonomiBusinessArea = (deal: Deal) => {
    return (
      dealsBusinessAreas?.options.find(
        // eslint-disable-next-line no-underscore-dangle
        (businessArea) => businessArea.value === deal.affarsomrade_deal_
      )?.value === EKONOMI_BUSINESS_AREA
    );
  };

  const areAllDealProductsSupportedInServiceMatrix = (deal: Deal) => {
    return getMappedDealProducts(deal).every((productDescription) =>
      mappedServiceGroups.includes(productDescription.toLowerCase())
    );
  };

  // pog offer creation is enabled only for Ekonomi business area and services
  // that are supported in the service-matrix since we can not create Pog offers
  // with unimplemented services
  const disableCreatePogOfferButton = (deal: Deal) => {
    return (
      !isDealWithEkonomiBusinessArea(deal) ||
      (deal.productIds.length > 0 &&
        !areAllDealProductsSupportedInServiceMatrix(deal))
    );
  };

  const dealHasAPackageServiceSelected = (deal: Deal) => {
    return getMappedDealProducts(deal).some(
      (productDescription) =>
        availableTogglesMeta[capitalize(productDescription)] &&
        availableTogglesMeta[capitalize(productDescription)].isPackage
    );
  };

  const dealHasANonPackageServiceSelected = (deal: Deal) => {
    return getMappedDealProducts(deal).some(
      (productDescription) =>
        availableTogglesMeta[capitalize(productDescription)] &&
        !availableTogglesMeta[capitalize(productDescription)].isPackage
    );
  };

  // pog offer creation should be disabled if business area is Ekonomi but there
  // are selected services which are excluding like Income tax and Small business
  const hasIncompatibleProductsSelected = (deal: Deal) => {
    return (
      isDealWithEkonomiBusinessArea(deal) &&
      dealHasAPackageServiceSelected(deal) &&
      dealHasANonPackageServiceSelected(deal)
    );
  };

  // adding the serviceLine and service groups to the offer
  // (eg. Accounting as service area, Income tax as service group etc.)
  const addServiceLinesAndGroupsToPogOffer = (
    offerDealCustomer: DealCustomer,
    deal: Deal
  ) => {
    const accountingServiceLine = serviceLines.find(
      (serviceLine) => serviceLine.name === ACCOUNTING_SERVICE_LINE
    );
    if (accountingServiceLine) {
      dispatch(addOfferSelectedServiceLine(accountingServiceLine));
    }

    const dealServiceGroups = serviceGroups.filter((group) =>
      getMappedDealProducts(deal).includes(group.name.toLowerCase())
    );
    dealServiceGroups.forEach((serviceGroup) => {
      const groupCatagories = serviceGroup.service_categories
        .filter((category) => category.name)
        .map((category) => {
          const serviceCategory =
            getServiceCategoryWithDefaultServices(category);
          return serviceCategory;
        });

      if (groupCatagories.length > 0) {
        const allServices = groupCatagories.flatMap((sc) => sc.services);

        createIncomeTaxObjectServices(allServices, offerDealCustomer);
        const newGroup: ServiceGroup = {
          name: serviceGroup.name,
          service_categories: groupCatagories,
          sorting_number: serviceGroup.sorting_number,
          skip_price_calculation: serviceGroup.skip_price_calculation,
        };
        dispatch(addServiceLineGroup(newGroup));
      }
    });
  };

  const startCreatingPogOffer = async () => {
    const deal = plainToClass(Deal, hubspotDeal);

    setCreatePogOfferLoading(true);
    await dispatch(resetOffer());
    try {
      const company = await dispatch(
        fetchCompanyInfoFromSales({
          searchByParam: SearchBy.OrgNumber,
          customerId: deal.organisationsnummer,
        })
      ).unwrap();

      if (company && company.org_number) {
        const customerHasAdressCityAndZip =
          !!company.city && !!company.zip && !!company.address;

        const dealCustomerPartial = DealCustomer.fromCompanyInfo(
          company,
          currentUser
        );
        await dispatch(fetchCustomerAndAddToOffer(dealCustomerPartial));

        const offerDealCustomer = plainToClass(DealCustomer, {
          ...dealCustomerPartial,
        });

        const tempDealCustomerUpdatedByUser =
          !dealCustomerPartial.created_by_username
            ? {
                created_by_username:
                  dealCustomerPartial.created_by_username ?? currentUser.email,
                changed_by_username: currentUser.email,
              }
            : {
                changed_by_username: currentUser.email,
              };

        // only if all required field are defined we can update the customer
        if (customerHasAdressCityAndZip) {
          // this is needed to get the tax objects for the customer
          await dispatch(
            createOrUpdateCustomer(
              plainToClass(DealCustomer, {
                ...offerDealCustomer,
                ...tempDealCustomerUpdatedByUser,
              })
            )
          );
        }

        // adding the serviceLine and service groups to the offer
        // (eg. Accounting as service area, Income tax as service group etc.)
        addServiceLinesAndGroupsToPogOffer(offerDealCustomer, deal);

        // create or update pog contact with deal's contact
        await createOrUpdateHSContactInPogOffer(deal);

        // set the hubspot_deal_id to the offer to link the new offer with the HS deal
        dispatch(
          updateCurrentOffer({
            hubspot_deal_id: deal.id,
          })
        );
        dispatch(setSelectedCustomerSource(deal.deal_source));

        setCreatePogOfferLoading(false);
        if (customerHasAdressCityAndZip) {
          // navigate the user to the offer contacts to select a signer and proceed
          navigate(OfferRouteHelper.getContactInfo());
        } else {
          // navigate the user to the customer details to populate the missing required fields
          navigate(OfferRouteHelper.getRegisterCompany());
        }
      }
    } catch (e) {
      dispatch(
        updateCurrentOffer({
          hubspot_deal_id: deal.id,
          new_customer: true,
        })
      );
      dispatch(
        updateOfferCustomer(
          plainToClass(DealCustomer, {
            ...offerCustomer,
            ...dealCustomerUpdatedByUser,
            legal_form: deal.company ? "ab" : "pp",
          })
        )
      );
      dispatch(setSelectedCustomerSource(deal.deal_source));
      setCreatePogOfferLoading(false);
      // navigate to offers/customer-type -> register flow
      navigate(OfferRouteHelper.getRegisterCompany());
    }
  };

  const goToPogOffer = () => {
    if (hubspotDeal) {
      navigate(
        `${OfferRouteHelper.getSummary()}/${
          hubspotDeal.organisationsnummer
        }/offer/${hubspotDeal.pog_quote_url.split("/").pop()}`
      );
    }
  };

  return (
    <Modal
      size="medium"
      header={
        <ModalHeader
          headerTitleText="DEAL_TO_POG_HEADER"
          subheaderText="DEAL_TO_POG_INFO"
          subheaderIcon={<Info20Regular className="mx-sm text-color-blue" />}
        />
      }
      onDismiss={onDismiss}
      isOpen
      footer={
        <ModalFooter
          onCancel={onDismiss}
          onSave={dealExistInPog ? goToPogOffer : startCreatingPogOffer}
          labelSubmit={dealExistInPog ? "TO_POG_OFFER" : "CREATE_POG_OFFER"}
          isDisabled={!isValid || isVerifyingDeal || createPogOfferLoading}
          saveButtonTooltip={
            dealExistInPog ? { content: "POG_OFFER_EXISTS" } : undefined
          }
          isLoading={isVerifyingDeal || createPogOfferLoading}
          cancelButtonIcon={<ArrowLeft20Regular className="mr-sm" />}
          sendButtonIcon={<ArrowRight20Regular className="mr-sm" />}
        />
      }
    >
      <Row className="minHeight-100">
        <div className="d-flex align-items-center pb-xl">
          <div className="w-100">
            <Row className="text-center m-auto search-customer w-100 pt-lg">
              <Col md={3} className="m-auto">
                <div className="horizontal-divider" />
              </Col>
              <Col md={6}>
                <div className="text-left">
                  <label className="p-0 fw-bold">
                    {translate("DEAL_ID")} *
                  </label>
                  <br />
                  <RHFInput
                    type="number"
                    disabled={isVerifyingDeal || createPogOfferLoading}
                    register={dealIdValue}
                    placeholder={translate("DEAL_ID_PLACEHOLDER")}
                    onChange={(e) => {
                      setValue("dealId", e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    errorMessage={errors.dealId?.message}
                  />
                </div>
              </Col>
              <Col md={3} className="m-auto">
                <div className="horizontal-divider" />
              </Col>
            </Row>
          </div>
        </div>
      </Row>
      <Row className="d-flex">
        {createPogOfferLoading && (
          <Spinner
            size={SpinnerSize.Tiny}
            label={translate("CREATE_POG_OFFER_LOADER")}
          />
        )}
      </Row>
    </Modal>
  );
}
