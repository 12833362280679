/* eslint-disable camelcase */
import { Label } from "@fluentui/react-components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Dropdown } from "components/dropdown";
import Modal from "components/modal";
import Breadcrumb, { BreadcrumbDefinitionItem } from "components/breadcrumb";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import Switch from "components/switch";
import { useTranslation } from "hooks/use-translate";
import { getYearsOptions } from "libs/date/date-format";
import { getMonthsOfOneYear } from "libs/date/generate-month";
import { isEqualSSN } from "libs/is-equal-ssn";
import { uniqByFilter } from "libs/uniqe-filter";
import { useServiceMatrix } from "libs/service-matrix";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import {
  getServiceCategoryFromEconomyServiceArea,
  getFilteredAndSortedServices,
  Service,
  ServiceCategory,
} from "models/offer/ServiceLine";
import { TaxObjectDetails } from "models/offer/TaxObject";
import { RootState } from "state";
import { updateCurrentOffer } from "state/offer/offersSlice";
import { sendOfferForCalculation } from "state/offer/offersThunks";
import { useRoutingForOffer } from "views/createNew/offer/components/wizardSection/useRoutingForOffer";
import { WizardSection } from "views/createNew/offer/components/wizardSection";
import ServiceDetailsHeader from "views/createNew/offer/wizard/components/service-details-header";
import { TaxObjectForm } from "views/createNew/offer/wizard/IncomeTax/TaxObjectForm";
import {
  getNextButtonText,
  OfferRouteHelper,
} from "views/createNew/offer/routes/offerRoutes";
import ServicesFormData from "views/createNew/offer/wizard/Services/ServicesFormData";
import { SERVICE_GROUP_SMALL_BUSINESS_EF } from "views/createNew/offer/wizard/consts/offer-contst";
import { useGetBreadcrumbs } from "views/createNew/offer/components/wizardSection/useGetBreadcrumbs";
import { ServicesContext } from "views/createNew/offer/wizard/Services/ServicesContextProvider";
import "../Services/ServiceForm.scss";
import useSaveTaxObjectsData from "hooks/createNew/offer/use-save-tax-objects-data";
import ServiceFormWithTaxObjects from "../../components/serviceFormWithTaxObjects/serviceFormWithTaxObjects";

export type SmallBusinessForm = ServicesFormData & {
  yearEndYear: string | null;
  yearEndMonth: string;
  services: Service[];
};

interface SmallBusinessProps {
  serviceLine: string;
  serviceGroup: string;
  serviceCategory: string;
}

function SmallBusiness({
  serviceLine,
  serviceGroup,
  serviceCategory,
}: SmallBusinessProps) {
  const { translate, ts } = useTranslation();
  const { getBreadcrumbs } = useGetBreadcrumbs();
  const navigate = useNavigate();
  const { edit } = useParams<{ edit: string }>();
  const dispatch = useDispatch();
  const location = useLocation();
  const { updateServiceGroups, updateTaxObjects, taxObjects } =
    useContext(ServicesContext);

  const { isNewCustomerAccounting } = useServiceMatrix();

  const serviceAreas = useSelector(
    (state: RootState) => state.offers.offerTemplate.data?.service_areas
  );
  const user = useSelector((state: RootState) => state.users.currentUser);

  const [showTaxObjects, setShowTaxObjects] = useState(false);
  const [showServiceDetails, setShowServiceDetails] = useState(true);

  const { data: currentOffer, status: loadingStatus } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );

  const taxObjectsBackBtnlabel =
    serviceGroup === SERVICE_GROUP_SMALL_BUSINESS_EF
      ? "TAX_OBJECTS.BUTTON.GO_BACK_TO_SMALL_BUSINESS_EF"
      : "TAX_OBJECTS.BUTTON.GO_BACK_TO_SMALL_BUSINESS";

  if (!currentOffer) {
    throw new Error("No active offer");
  }

  if (!serviceAreas) {
    throw new Error("Missing service areas");
  }
  const { service_areas: currentServiceAreas } = currentOffer;

  useEffect(() => {
    const updatedTaxObjects =
      serviceGroup === SERVICE_GROUP_SMALL_BUSINESS_EF
        ? taxObjects.smallBusinessEF
        : taxObjects.smallBusiness;

    if (updatedTaxObjects) {
      dispatch(
        updateCurrentOffer({
          tax_objects: updatedTaxObjects,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, taxObjects]);

  const routing = useRoutingForOffer(location.pathname, currentOffer);
  const initialCurrentOffer = useRef(currentOffer);

  const [isDirty, setIsDirty] = useState(false);
  const [modalOpen, setOpenModal] = useState(false);

  const yearEndSelectionDisabled =
    serviceGroup === SERVICE_GROUP_SMALL_BUSINESS_EF;

  const smallBusinessTemplate =
    getServiceCategoryFromEconomyServiceArea(
      serviceLine,
      serviceGroup,
      serviceCategory,
      serviceAreas
    ) ?? ({} as ServiceCategory);

  smallBusinessTemplate.services = getFilteredAndSortedServices(
    smallBusinessTemplate,
    isNewCustomerAccounting()
  );

  const smallBusinessInCurrentOffer =
    getServiceCategoryFromEconomyServiceArea(
      serviceLine,
      serviceGroup,
      serviceCategory,
      currentServiceAreas
    ) ?? ({} as ServiceCategory);

  const { customer } = currentOffer;

  if (!customer) {
    throw new Error("Missing customer");
  }
  const customerTaxObjects = customer.tax_objects ?? [];
  const allIncomeTaxServices = Service.getIncomeTaxServices(
    smallBusinessTemplate.services
  );

  const customerTaxObject =
    customerTaxObjects.find((ctObj) =>
      isEqualSSN(ctObj.social_security_number, customer.org_number)
    ) ?? TaxObjectDetails.getTaxObjectFromCustomerData(customer, user);

  // return a list of uniq headers in the services array
  const serviceHeaders = uniqByFilter(
    smallBusinessTemplate.services.map((s) => s.header)
  );

  const servicesForHeader = (header: string) =>
    Service.sortServices(
      smallBusinessTemplate.services.filter((s) => s.header === header)
    );
  const headersWithSortedServices = serviceHeaders.map((header) => ({
    header,
    services: servicesForHeader(header),
  }));

  const {
    start_date,
    end_date,
    project_manager,
    frequency,
    services: activeServices,
    approved_by_email,
    year_end_year,
    year_end_month,
  } = smallBusinessInCurrentOffer;

  const [smallBusinessData, setSmallBusinessData] = useState<SmallBusinessForm>(
    {
      services: activeServices,
      startDate: start_date !== "" ? start_date : getMonthsOfOneYear()[0],
      endDate: end_date,
      projectManager: project_manager !== "" ? project_manager : user.email,
      yearEndYear: year_end_year,
      yearEndMonth: year_end_month || "12",
      frequency: frequency ?? smallBusinessTemplate.frequency,
    }
  );

  const filteredActiveServices = activeServices.filter(
    (activeService) => !activeService.pog_income_tax
  );

  const { saveFormData, saveDataToServiceLine } = useSaveTaxObjectsData({
    serviceCategoryInCurrentOffer: smallBusinessInCurrentOffer,
    formData: smallBusinessData,
    serviceCategoryServices: allIncomeTaxServices,
    setIsDirty,
  });

  async function saveSmallBusinessHeaderDataToServiceLine(
    smallBusinessDataForm?: SmallBusinessForm
  ) {
    const newSmallBusinessData: ServiceCategory = {
      ...smallBusinessInCurrentOffer,
      project_manager:
        smallBusinessDataForm?.projectManager ??
        smallBusinessData.projectManager,
      start_date:
        smallBusinessDataForm?.startDate ?? smallBusinessData.startDate,
      frequency:
        smallBusinessDataForm?.frequency ?? smallBusinessData.frequency,
      year_end_year:
        smallBusinessDataForm?.yearEndYear ?? smallBusinessData.yearEndYear,
      year_end_month:
        smallBusinessDataForm?.yearEndMonth ?? smallBusinessData.yearEndMonth,
    };

    const newServiceAreas = ServiceCategory.updateServiceCategoryInServiceArea(
      currentServiceAreas,
      newSmallBusinessData
    );

    setIsDirty(true);
    await dispatch(updateCurrentOffer({ service_areas: newServiceAreas }));
  }

  function isSelectedService(service: Service): boolean {
    return activeServices.some(
      (selectedServices) =>
        selectedServices.service_matrix_id === service.service_matrix_id
    );
  }

  const isFormDisabled =
    !!approved_by_email && loadingStatus === LoadingStatusEnum.PENDING;
  function renderToggles(services: Service[]): React.ReactNode {
    return services.map((service) => (
      <Switch
        disabled={isFormDisabled || service.input_is_disabled}
        className="m-1"
        key={`${service.service_matrix_id}yearEndService`}
        label={service.name}
        checked={isSelectedService(service)}
        onToggleMethod={(_, { checked }) => {
          if (isSelectedService(service) && !checked) {
            const filteredServices = activeServices.filter(
              (s) => s.service_matrix_id !== service.service_matrix_id
            );
            saveDataToServiceLine(filteredServices);
          } else {
            const activeService: Service = { ...service, units: 1 };
            saveDataToServiceLine([...activeServices, activeService]);
          }
        }}
      />
    ));
  }

  function updateServiceCategoryData(
    formHeaderData: Partial<SmallBusinessForm>
  ) {
    const newSmallBusinessFormData = {
      ...smallBusinessData,
      ...formHeaderData,
    };
    setSmallBusinessData(newSmallBusinessFormData);
    saveSmallBusinessHeaderDataToServiceLine(newSmallBusinessFormData);
  }

  function hasFiscalYearSelected(): boolean {
    return !!smallBusinessData.yearEndYear;
  }

  const handleOnBeforePrevious = () => {
    if (isDirty) {
      setOpenModal(isDirty);
      return;
    }

    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
  };

  const handleDiscard = () => {
    dispatch(updateCurrentOffer(initialCurrentOffer.current));
    setIsDirty(false);
    setOpenModal(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const updateServiceContext = () => {
    const serviceGroupToUpdate =
      currentOffer.service_areas[0].service_lines[0].service_groups.find(
        (group) => group.name === serviceGroup
      );

    if (serviceGroupToUpdate) {
      updateServiceGroups(serviceGroupToUpdate);
    }
    const taxObjectsUpdate =
      serviceGroup === SERVICE_GROUP_SMALL_BUSINESS_EF
        ? { smallBusinessEF: currentOffer.tax_objects }
        : { smallBusiness: currentOffer.tax_objects };

    updateTaxObjects(taxObjectsUpdate);
  };

  const handleSave = async () => {
    await saveSmallBusinessHeaderDataToServiceLine();
    await dispatch(sendOfferForCalculation());
    updateServiceContext();
    setIsDirty(false);
    setOpenModal(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const serviceFormHeader = (
    <ServiceDetailsHeader
      startDateOnChange={(value) => {
        updateServiceCategoryData({
          ...smallBusinessData,
          startDate: value,
        });
      }}
      startDate={smallBusinessData.startDate}
      projectManagerOnChange={(personId) => {
        updateServiceCategoryData({
          ...smallBusinessData,
          projectManager: personId ?? "",
        });
      }}
      projectManager={smallBusinessData.projectManager}
      frequencyOnChange={(value) => {
        updateServiceCategoryData({
          ...smallBusinessData,
          frequency: value,
        });
      }}
      frequencySelectionDisabled={!!smallBusinessTemplate.frequency}
      frequency={smallBusinessData.frequency}
      yearEndData={smallBusinessData}
      yearEndInCurrentOffer={smallBusinessInCurrentOffer}
      yearEndSelectionDisabled={yearEndSelectionDisabled}
      updateServiceCategoryData={(value) => {
        updateServiceCategoryData({
          ...smallBusinessData,
          yearEndMonth: value,
        });
      }}
    />
  );

  const renderServiceDetailsForm = () => (
    <div>
      <Row className="py-xl">
        <Col md={3} className=" mb-sm">
          <Row className="pt-xs p-xs">
            <Col>
              <Label className="p-0">{translate("FISCAL_YEAR")} *</Label>
              <Dropdown<string>
                disabled={!!smallBusinessInCurrentOffer.approved_by_email}
                className="px-0"
                placeholder={translate("DROPDOWN_PLACEHOLDER")}
                onChange={(value) => {
                  updateServiceCategoryData({
                    ...smallBusinessData,
                    yearEndYear: value,
                  });
                  setIsDirty(true);
                }}
                options={getYearsOptions()}
                value={smallBusinessData.yearEndYear || undefined}
                errorMessage={
                  hasFiscalYearSelected()
                    ? undefined
                    : translate("SELECT_CORRECT_FISCAL_YEAR")
                }
              />
            </Col>
          </Row>
        </Col>

        <Col md={1} className="d-flex justify-content-center">
          <div className="d-flex">
            <div className="vertical-divider" />
          </div>
        </Col>

        {headersWithSortedServices.map(
          (headerData, index, allServiceHeaders) => {
            const { header } = headerData;
            const { services } = headerData;
            const isLastServiceHeader = index + 1 === allServiceHeaders.length;
            return (
              <React.Fragment key={header}>
                <Col>
                  <Row>
                    <Col>
                      <label className="fw-bold">{header}</label>
                    </Col>
                  </Row>
                  {services.some((s) => s.pog_income_tax) ? (
                    <TaxObjectForm
                      isForCompany
                      onSave={(taxObject, taxObjectServices) =>
                        saveFormData(
                          taxObject,
                          taxObjectServices,
                          filteredActiveServices
                        )
                      }
                      dealTaxServices={currentOffer.tax_objects}
                      taxObject={customerTaxObject}
                      showInModal={false}
                      isFormDisabled={isFormDisabled}
                      incomeTaxServicesTemplate={Service.getIncomeTaxServicesForCompany(
                        smallBusinessTemplate.services,
                        isNewCustomerAccounting()
                      )}
                    />
                  ) : (
                    <div className="pt-lg">{renderToggles(services)}</div>
                  )}
                </Col>

                {!isLastServiceHeader && (
                  <Col md={1} className="d-flex justify-content-center">
                    <div className="d-flex">
                      <div className="vertical-divider" />
                    </div>
                  </Col>
                )}
              </React.Fragment>
            );
          }
        )}
      </Row>
    </div>
  );

  const onBeforeNext = async () => {
    await saveSmallBusinessHeaderDataToServiceLine();
    await dispatch(sendOfferForCalculation());
    updateServiceContext();
    return true;
  };

  const renderServiceForm = () => (
    <WizardSection
      onBeforeNext={onBeforeNext}
      isNextHidden={!hasFiscalYearSelected()}
      onBeforePrevious={handleOnBeforePrevious}
      loadingStatus={loadingStatus}
      hideNavigation={showTaxObjects}
      titleBreadCrumb={
        showTaxObjects ? translate("TAX_OBJECT_LABEL") : undefined
      }
      hasDivider
      content={serviceFormHeader}
      nextButtonText={translate("SERVICE_PAGE.CALCULATE_PRICE_BUTTON")}
    >
      <ServiceFormWithTaxObjects
        modalOpen={modalOpen}
        setOpenModal={setOpenModal}
        showServiceDetails={showServiceDetails}
        setShowServiceDetails={setShowServiceDetails}
        handleSave={handleSave}
        handleDiscard={handleDiscard}
        isValid={hasFiscalYearSelected()}
        showTaxObjects={showTaxObjects}
        setShowTaxObjects={setShowTaxObjects}
        renderServiceDetailsForm={renderServiceDetailsForm}
        incomeTaxServices={allIncomeTaxServices}
        saveFormData={saveFormData}
        label={taxObjectsBackBtnlabel}
        smallBusinessServices={filteredActiveServices}
      />
    </WizardSection>
  );

  return edit !== undefined ? (
    <Modal
      className="service-modal"
      isOpen
      onDismiss={() => {
        if (isDirty) {
          setOpenModal(isDirty);
          return;
        }
        setOpenModal(false);
        navigate(-1);
      }}
      size="fit-content-min"
      header={
        <ModalHeader
          headerTitleContent={
            <div className="header-blue-stripe align-items-center">
              <Breadcrumb
                className="ml-sm"
                items={
                  getBreadcrumbs(
                    "",
                    true,
                    undefined,
                    showTaxObjects ? translate("TAX_OBJECT_LABEL") : undefined
                  ) as BreadcrumbDefinitionItem[]
                }
              />
            </div>
          }
        />
      }
      footer={
        <ModalFooter
          labelSubmit={ts(
            getNextButtonText(location.pathname, currentOffer, undefined)
          )}
          onSave={() => {
            onBeforeNext();
            navigate(OfferRouteHelper.getSummary());
          }}
          isDisabled={!hasFiscalYearSelected()}
          isLoading={loadingStatus === LoadingStatusEnum.PENDING}
        />
      }
    >
      <>
        <div className="contentHeader">{serviceFormHeader}</div>
        <ServiceFormWithTaxObjects
          modalOpen={modalOpen}
          setOpenModal={setOpenModal}
          showServiceDetails={showServiceDetails}
          setShowServiceDetails={setShowServiceDetails}
          handleSave={handleSave}
          handleDiscard={handleDiscard}
          isValid={hasFiscalYearSelected()}
          showTaxObjects={showTaxObjects}
          setShowTaxObjects={setShowTaxObjects}
          renderServiceDetailsForm={renderServiceDetailsForm}
          incomeTaxServices={allIncomeTaxServices}
          saveFormData={saveFormData}
          label={taxObjectsBackBtnlabel}
          smallBusinessServices={filteredActiveServices}
        />
      </>
    </Modal>
  ) : (
    renderServiceForm()
  );
}

export default SmallBusiness;
