import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Tab, TabList } from "@fluentui/react-components";

import { Button } from "components/button";
import { useTranslation } from "hooks";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { LoadingStatus, RootState } from "state";
import { dismissMessage } from "state/notifications";
import { SearchBy } from "state/offer/companyThunks";
import { AppDispatch } from "state/use-app-redux";
import "./CustomerSearchComponent.scss";
import SearchInput from "components/input/search";

type CustomerSearchProps = {
  searchCustomerErrorMessage: string | JSX.Element;
  searchErrorHasOccurred: boolean;
  searchCustomerBy: SearchBy;
  fetchCompanyData: () => void;
  goToRegisterCustomer: () => void;
  clearInputErrorMsg: () => void;
  setSearchCustomerBy: (searchBy: SearchBy) => void;
  setCustomerIdValue: (customerId: string) => void;
};

export default function CustomerSearchComponent({
  searchCustomerErrorMessage,
  searchErrorHasOccurred,
  searchCustomerBy,
  fetchCompanyData,
  goToRegisterCustomer,
  clearInputErrorMsg,
  setSearchCustomerBy,
  setCustomerIdValue,
}: CustomerSearchProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { translate } = useTranslation();

  const { status } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  const { status: billingStatus } = useSelector(
    (state: RootState) => state.offers.customerBillings
  );

  const { data: notifications } = useSelector(
    (state: RootState) => state.notifications
  );

  const [combinedStatus, setCombinedStatus] = useState<LoadingStatusEnum>();
  const latestNotification = notifications[notifications.length - 1];

  const checkStatuses = (statuses: LoadingStatus[]) => {
    const failed = statuses.some(
      (loadingStatus) => loadingStatus === LoadingStatusEnum.FAILED
    );
    const pending = statuses.some(
      (loadingStatus) => loadingStatus === LoadingStatusEnum.PENDING
    );
    switch (true) {
      case failed:
        return LoadingStatusEnum.FAILED;
      case pending:
        return LoadingStatusEnum.PENDING;
      default:
        return LoadingStatusEnum.IDLE;
    }
  };

  const isLoading = combinedStatus === LoadingStatusEnum.PENDING;

  useEffect(() => {
    const combinedStatuses = checkStatuses([status, billingStatus]);
    setCombinedStatus(combinedStatuses);
  }, [status, billingStatus]);

  const searchInputOnBlur = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    if (
      e.relatedTarget instanceof HTMLElement &&
      (e.relatedTarget.getAttribute("role") === "button" ||
        e.relatedTarget.tagName === "BUTTON")
    ) {
      e.preventDefault();
      return;
    }

    const { value } = e.target;

    const shouldFetchCompany =
      value !== "" && searchCustomerErrorMessage === "";

    if (shouldFetchCompany) {
      clearInputErrorMsg();
      fetchCompanyData();
    }
  };

  const searchInputOnClear = () => {
    setCustomerIdValue("");

    if (searchErrorHasOccurred && latestNotification) {
      dispatch(dismissMessage(latestNotification));
    }

    clearInputErrorMsg();
  };

  const searchInputOnSearch = (newValue?: string) => {
    setCustomerIdValue(newValue?.trim() || "");
    clearInputErrorMsg();

    if (searchErrorHasOccurred && latestNotification) {
      dispatch(dismissMessage(latestNotification));
    }
  };

  return (
    <Row className="minHeight-100">
      <div className="d-flex align-items-center pb-lg">
        <div className="w-100">
          <Row className="text-center m-auto search-customer w-100 pt-lg">
            <Col md={4} className="pt-lg m-auto">
              <div className="horizontal-divider" />
            </Col>
            <Col md={4}>
              <div className="mx-auto mb-md pivot-wrapper">
                <TabList
                  selectedValue={searchCustomerBy}
                  onTabSelect={(_, item) => {
                    if (searchErrorHasOccurred && latestNotification) {
                      dispatch(dismissMessage(latestNotification));
                    }
                    clearInputErrorMsg();
                    setSearchCustomerBy(item?.value as SearchBy);
                  }}
                >
                  <Tab
                    key={SearchBy.OrgNumber}
                    value={SearchBy.OrgNumber}
                    disabled={isLoading}
                  >
                    {translate("ORG_NUM")}
                  </Tab>
                  <Tab
                    key={SearchBy.CustomerNumber}
                    value={SearchBy.CustomerNumber}
                    disabled={isLoading}
                  >
                    {translate("CUSTOMER_NUMBER")}
                  </Tab>
                </TabList>
              </div>
              <div>
                <SearchInput
                  disabled={isLoading}
                  placeholder={
                    searchCustomerBy === SearchBy.OrgNumber
                      ? translate("OFFER_COMPANY_SEARCH_ORG_PLACEHOLDER")
                      : translate("OFFER_COMPANY_SEARCH_CUSTOMER_PLACEHOLDER")
                  }
                  onChange={(_, newValue) =>
                    newValue.value
                      ? searchInputOnSearch(newValue.value)
                      : searchInputOnClear()
                  }
                  onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                      ev.preventDefault();
                      fetchCompanyData();
                    }
                  }}
                  onBlur={(e) => searchInputOnBlur(e)}
                  errorMessage={searchCustomerErrorMessage}
                  data-testid="search-input"
                  fullWidth
                />
              </div>
            </Col>
            <Col md={4} className="pt-lg m-auto">
              <div className="horizontal-divider" />
            </Col>
          </Row>
          <div className="d-flex justify-content-center align-items-center pt-lg">
            {translate("DONT_HAVE_NUMBER")} -{" "}
            <Button
              className="no-padding ml-xs"
              variant="outline"
              size="md"
              disabled={isLoading}
              onClick={goToRegisterCustomer}
            >
              <span className="blue-link-underline">
                {translate("CLICK_HERE")}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </Row>
  );
}
