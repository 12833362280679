import { CalendarProps, DayOfWeek } from "@fluentui/react-calendar-compat";
import {
  DatePicker as FluentUIDatePicker,
  DatePickerProps as FluentUIDatePickerProps,
} from "@fluentui/react-datepicker-compat";
import { UseFormRegisterReturn } from "react-hook-form";

import { useTranslation } from "hooks";
import { customDatePickerStrings } from "libs/date/date-format";

export type DatePickerProps = {
  selectedDate?: Date | null;
  onSelectDate: (date?: Date | null) => void;
  dateFormatter: (date: Date) => string;
  placeholder?: string;
  calendarProps?: Partial<CalendarProps>;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  firstDayOfWeek?: DayOfWeek;
  allowTextInput?: boolean;
  showGoToToday?: boolean;
  fullWidth?: boolean;
  borderless?: boolean;
  formRegister?: UseFormRegisterReturn;
  errorMessage?: string;
  className?: string;
} & Omit<
  FluentUIDatePickerProps,
  "formatDate" | "highlightSelectedMonth" | "strings"
>;

export function DatePicker({
  selectedDate,
  onSelectDate,
  dateFormatter,
  placeholder,
  disabled,
  minDate,
  maxDate,
  firstDayOfWeek,
  allowTextInput,
  showGoToToday,
  calendarProps,
  fullWidth,
  borderless,
  formRegister,
  errorMessage,
  className,
  ...rest
}: DatePickerProps) {
  const { translate } = useTranslation();

  return (
    <>
      <FluentUIDatePicker
        {...rest}
        value={selectedDate}
        onSelectDate={onSelectDate}
        formatDate={(date) => (date ? dateFormatter(date) : "no value")}
        placeholder={placeholder}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        firstDayOfWeek={firstDayOfWeek}
        allowTextInput={allowTextInput}
        showGoToToday={showGoToToday}
        highlightSelectedMonth
        strings={customDatePickerStrings(translate)}
        calendar={calendarProps}
        className={`${fullWidth ? "w-100" : ""} ${className || ""}`}
        borderless={borderless}
      />
      {errorMessage ? (
        <div className="error-message">
          <span className="text-color-red">
            <span>{errorMessage}</span>
          </span>
        </div>
      ) : undefined}
    </>
  );
}
