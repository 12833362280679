/* eslint-disable camelcase */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { MessageBar, Radio, RadioGroup } from "@fluentui/react-components";
import { addHours, addYears, startOfMonth, startOfYear } from "date-fns";

import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { ModalFooter } from "components/modal/ModalFooter";
import { Button } from "components/button";
import { DatePicker } from "components/date/DatePicker";
import { OverlaySpinner } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import { TranslationKey } from "i18n";
import { dateLanguageFormatter } from "libs/date/date-format";
import { subtractMonthsFromTodaysDate } from "libs/date/date-subtract-months";
import {
  CustomerDelivery,
  CustomerPackage,
  DeliveryRecurrence,
} from "models/deliveryPlan";
import {
  CREATED_USING_DP,
  createGeneralAccountingDelivery,
  GENERAL_ACCOUNTING_DELIVERY,
  updateGeneralAccountingDelivery,
} from "state/customers/actions";
import { useAppSelector } from "state/use-app-redux";
import { DeadlineCalendar } from "views/conveyorBelt/components/serviceActivities/components/DeadlineCalendar";
import { DeliveryChangeProps } from ".";

const ONE_YEAR_AHEAD = addYears(new Date(), 1);

const DeliveryPackageOptionsWithRecurrences = [
  { name: "AccPackMstd", recurrence: "MONTHLY" },
  { name: "AccPackMspecial", recurrence: "MONTHLY" },
  { name: "AccPackQstd", recurrence: "QUARTERLY" },
  { name: "AccPackQspecial", recurrence: "QUARTERLY" },
  { name: "AccPackYstd", recurrence: "YEARLY" },
  { name: "AccPackYeu", recurrence: "YEARLY" },
] as const;

const DeliveryPackageOptions = DeliveryPackageOptionsWithRecurrences.map(
  (packageOption) => packageOption.name
);

type GeneralAccountingDeliveryPackageOptions =
  typeof DeliveryPackageOptionsWithRecurrences[number]["name"];

const VatPackages = [
  { key: "YEARLY", value: "VatY" },
  { key: "MONTHLY", value: "VatM" },
  { key: "QUARTERLY", value: "VatQ" },
  { key: "VAT_FREE", value: "" },
] as const;
const VatPeriodOptions = VatPackages.map((vatPackage) => vatPackage.value);
type VATPeriod = typeof VatPackages[number]["value"];

type GeneralAccountingOptions = {
  startDate: Date;
  deliveryDate: Date;
  recurrence: DeliveryRecurrence;
  vatPeriod: VATPeriod;
  deliveryPackage: GeneralAccountingDeliveryPackageOptions;
};

function getCustomerPackagesFromOptions(
  options: GeneralAccountingOptions
): CustomerPackage[] {
  const packages: CustomerPackage[] = [];

  packages.push({
    package_key_str: options.deliveryPackage,
  });

  const vatPackage = VatPackages.find((kv) => kv.value === options.vatPeriod);

  if (vatPackage && vatPackage.value) {
    packages.push({
      package_key_str: vatPackage.value,
    });
  }

  return packages;
}

function getDefaultSelection(existingDelivery?: CustomerDelivery) {
  const defaultDeliveryPackage = (
    existingDelivery
      ? existingDelivery.packages?.find((p) =>
          DeliveryPackageOptions.includes(
            p.package_key_str as GeneralAccountingDeliveryPackageOptions
          )
        )?.package_key_str
      : DeliveryPackageOptionsWithRecurrences[0].name
  ) as GeneralAccountingDeliveryPackageOptions;

  const defaultVatPeriod = (existingDelivery?.packages?.find((p) =>
    VatPeriodOptions.includes(p.package_key_str as VATPeriod)
  )?.package_key_str || "") as VATPeriod;

  return {
    deliveryPackage: defaultDeliveryPackage,
    vatPeriod: defaultVatPeriod,
    startPeriod: existingDelivery?.start_date,
    deliveryDates: existingDelivery?.delivery_dates[0],
    recurrence: existingDelivery
      ? (existingDelivery.recurrence as DeliveryRecurrence)
      : DeliveryPackageOptionsWithRecurrences[0].recurrence,
  };
}

export function GeneralAccountingPackageConfiguration({
  customer,
  onChange,
  remove,
  existingDelivery,
  preSelectedStartDate,
  loadingDeliveries,
}: DeliveryChangeProps) {
  const dispatch = useDispatch();
  const defaultOptions = getDefaultSelection(existingDelivery);
  const { translate, language } = useTranslation();
  const formatter = dateLanguageFormatter(language, "yyyy-MM-dd");
  const [options, setOptions] = useState<GeneralAccountingOptions>({
    startDate: addHours(
      startOfYear(
        preSelectedStartDate || defaultOptions.startPeriod || new Date()
      ),
      12
    ),
    deliveryDate: addHours(
      startOfYear(
        preSelectedStartDate || defaultOptions.deliveryDates || new Date()
      ),
      12
    ),
    vatPeriod: defaultOptions.vatPeriod,
    deliveryPackage: defaultOptions.deliveryPackage,
    recurrence: defaultOptions.recurrence,
  });

  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const customerState = useAppSelector((state) => state.customers.data).find(
    (c) => c.customer.customer_number === customer.customer_number
  );

  if (!customerState) {
    return <>{translate("DP.MISSING_CUSTOMER")}</>;
  }

  const earliest_onboarding_date = customer.deliveryPlan.find(
    (cd) =>
      cd.delivery_name === GENERAL_ACCOUNTING_DELIVERY && cd.ends_at != null
  )?.ends_at;

  const isLoading = customerState && customerState.isLoading;

  return (
    <>
      {loadingDeliveries && (
        <OverlaySpinner label={translate("REMOVING_DELIVERIES")} />
      )}
      <div className="d-flex mt-2 mb-4 fg-4">
        <div>
          <h4>{translate("DELIVERY_PACKAGE")}</h4>
          <RadioGroup
            onChange={(_, option) => {
              const selected = DeliveryPackageOptionsWithRecurrences.find(
                (p) => p.name === option?.value
              );

              if (!selected) {
                throw new Error("Could not match recurrence");
              }

              setOptions({
                ...options,
                deliveryPackage:
                  option?.value as GeneralAccountingDeliveryPackageOptions,
                recurrence: selected.recurrence,
              });
            }}
            value={options.deliveryPackage}
          >
            {DeliveryPackageOptionsWithRecurrences.map((option) => (
              <Radio
                value={option.name}
                label={translate(option.name as TranslationKey)}
              />
            ))}
          </RadioGroup>
        </div>

        <div>
          <h4>{translate("VAT_PERIOD")}</h4>
          <RadioGroup
            onChange={(_, option) =>
              setOptions({
                ...options,
                vatPeriod: option?.value as VATPeriod,
              })
            }
            value={options.vatPeriod || ""}
          >
            {VatPackages.map((option) => {
              return (
                <Radio
                  value={option.value}
                  label={translate(option.key as TranslationKey)}
                />
              );
            })}
          </RadioGroup>
        </div>

        <div>
          <h4>{translate("SET_START_DATE")}</h4>
          <DatePicker
            placeholder={translate("SET_START_DATE")}
            selectedDate={options.startDate}
            onSelectDate={(date) =>
              setOptions({
                ...options,
                ...(date && { startDate: addHours(startOfMonth(date), 12) }),
              })
            }
            borderless
            dateFormatter={(date) => formatter(date, "MMMM yyyy")}
            calendarProps={{
              isDayPickerVisible: false,
              showGoToToday: false,
              highlightSelectedMonth: true,
            }}
          />
          {options.recurrence === "YEARLY" && (
            <section>
              <p />
              <h4>{translate("SET_DELIVERY_DATE")}</h4>
              <DatePicker
                placeholder={translate("SET_DELIVERY_DATE")}
                selectedDate={options.deliveryDate}
                onSelectDate={(date) =>
                  setOptions({
                    ...options,
                    ...(date && {
                      deliveryDate: addHours(startOfMonth(date), 12),
                    }),
                  })
                }
                dateFormatter={(date) => formatter(date, "MMMM yyyy")}
                calendarProps={{
                  isDayPickerVisible: false,
                  showGoToToday: false,
                  highlightSelectedMonth: true,
                }}
                borderless
              />
            </section>
          )}
        </div>
      </div>

      {earliest_onboarding_date &&
        earliest_onboarding_date > options.startDate && (
          <MessageBar intent="warning" className="mb-2">
            {translate("DP.ONGOING_ACCOUNTING_DISCLAIMER", [
              new Date(earliest_onboarding_date).toString(),
            ])}
          </MessageBar>
        )}

      <Button
        disabled={
          isLoading ||
          !options.deliveryPackage ||
          !options.startDate ||
          (earliest_onboarding_date &&
            earliest_onboarding_date > options.startDate)
        }
        onClick={() => {
          const packages = getCustomerPackagesFromOptions(options);

          if (existingDelivery) {
            dispatch(
              updateGeneralAccountingDelivery({
                customer,
                packages,
                startYearDate: options.startDate,
                yearlyDeliveryDate: options.startDate,
                recurrence: options.recurrence,
                deliveryType: "General accounting",
                deliveryDates: [options.deliveryDate],
                createdUsing: CREATED_USING_DP,
                lock_in: false,
                uuid: existingDelivery.uuid,
              })
            );
          } else {
            dispatch(
              createGeneralAccountingDelivery({
                customer,
                packages,
                startYearDate: options.startDate,
                yearlyDeliveryDate: options.startDate,
                recurrence: options.recurrence,
                deliveryType: "General accounting",
                deliveryDates: [options.deliveryDate],
                createdUsing: CREATED_USING_DP,
                lock_in: false,
              })
            );
          }

          onChange(); // to actually execute the action in the parent
        }}
      >
        {existingDelivery ? translate("CHANGE") : translate("COMPLETE")}
      </Button>

      {existingDelivery && remove && (
        <Button
          className="ms-2"
          variant="danger"
          onClick={() => {
            setShowConfirmRemove(true);
          }}
        >
          {translate("REMOVE")}
        </Button>
      )}

      <Modal
        isOpen={showConfirmRemove}
        header={<ModalHeader headerTitleText="REMOVE_PACKAGE" />}
        footer={
          <ModalFooter
            labelSubmit="CONFIRM"
            onCancel={() => setShowConfirmRemove(false)}
            onSave={() => {
              setShowConfirmRemove(false);
              if (remove) {
                remove(endDate);
              }
            }}
            saveButtonVariant="danger"
          />
        }
        onDismiss={() => setShowConfirmRemove(false)}
      >
        <label>{translate("SELECT_END_DATE")}</label>
        <DeadlineCalendar
          minDate={subtractMonthsFromTodaysDate(3)}
          language={language}
          translate={translate}
          maxDate={ONE_YEAR_AHEAD}
          value={endDate}
          onSelectedDate={(date) => setEndDate(date)}
        />

        <p>{translate("CONFIRM_DELETE_PACKAGE_DESCRIPTION")}</p>
      </Modal>
    </>
  );
}
