import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import copy from "copy-to-clipboard";
import { Copy16Regular } from "@fluentui/react-icons";

import { TooltipInfoIcon } from "components/icon";
import Modal from "components/modal";
import { OverlaySpinner } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import {
  canUploadPhysicalDeal,
  isDealEditable,
} from "libs/customerdeal-helpers";
import { useServiceMatrix } from "libs/service-matrix";
import { ServiceCategory } from "models/offer/ServiceLine";
import { RootState } from "state";
import { appendError, appendToastMessage } from "state/notifications";
import { updateServicesAndPostDealData } from "state/offer/offersThunks";
import { AppDispatch } from "state/use-app-redux";
import useGetSummaryModal from "hooks/createNew/offer/use-get-summary-modal";
import useValidateSmallRecurringCustomersServices from "hooks/createNew/offer/use-validate-small-recurring-customers-services";
import { useRoutingForOffer } from "views/createNew/offer/components/wizardSection/useRoutingForOffer";
import ServiceInfoTable from "views/createNew/offer/wizard/Summary/components/ServiceInfoTable";
import { WizardSection } from "views/createNew/offer/components/wizardSection";
import { OfferRouteHelper } from "views/createNew/offer/routes/offerRoutes";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { UserRoles } from "models/user";
import FinalPriceSection from "./components/FinalPrice";
import ContractTextsSection from "./components/ContractTexts";
import PriceAdjustmentModal from "./components/modals/PriceAdjustmentModal";

import "./summary.scss";

function Summary() {
  const dispatch: AppDispatch = useDispatch();
  const { translate } = useTranslation();
  const { offerId, orgId } = useParams<{ orgId: string; offerId: string }>();
  const navigate = useNavigate();
  const {
    GetLowestPossiblePrice,
    GetIsFinalPriceLowerThanLowestPossible,
    GetIsPriceAutoAdjusted,
    isSmallRecurring,
    GetAllCategoriesFlattened,
    GetWarningMessage,
  } = useServiceMatrix();

  const { validateServices } = useValidateSmallRecurringCustomersServices();

  const {
    currentOffer: {
      data: currentOffer,
      status: loadingStatus,
      isPriceCalculated,
    },
  } = useSelector((state: RootState) => state.offers);

  const { currentUser: user } = useSelector((state: RootState) => state.users);

  const [showPriceAdjustmentModal, setShowPriceAdjustmentModal] = useState("");

  // This checkbox is added as per https://lrfkonsult.atlassian.net/browse/POG-1006
  // as a temporary replacement for the Approval function, and is only on FE
  // To be removed when https://lrfkonsult.atlassian.net/browse/POG-837 is implemented
  const [isApproved, setIsApproved] = useState(false);

  const [isPriceValid, setIsPriceValid] = useState(true);

  const priceAdjustmentAccordionRef = useRef<HTMLDivElement>(null);

  const lowestPrice = GetLowestPossiblePrice();

  const location = useLocation();
  const routing = useRoutingForOffer(location.pathname, currentOffer);

  const {
    showModal,
    renderUnapprovedModal,
    closeModal,
    renderExternalContent,
    sendDealForSigning,
  } = useGetSummaryModal(offerId, orgId);

  const showPriceAdjustmentWarningModal = () => {
    if (
      !isSmallRecurring() ||
      !isLoading() ||
      !isPriceCalculated ||
      loadingStatus === LoadingStatusEnum.FAILED
    ) {
      setShowPriceAdjustmentModal("");
      return;
    }
    setIsApproved(false);
    setIsPriceValid(!GetIsFinalPriceLowerThanLowestPossible());
    setShowPriceAdjustmentModal(GetWarningMessage());
  };

  const checkIfDealIsValid = () => {
    if (!isLoading()) {
      return;
    }
    const categories = GetAllCategoriesFlattened(currentOffer);
    const services = categories.flatMap((category) => category.services);

    const servicesValidity = validateServices(services);

    const isValid = () => {
      return servicesValidity.every(
        (sv) => sv.isValueValid && sv.isFrequencyValid
      );
    };

    const isYearEndMonthValid = () => {
      return categories.every((category) => !!category.year_end_month);
    };

    if ((!isValid() || !isYearEndMonthValid()) && routing.previous?.path) {
      navigate(routing.previous?.path);
    }
  };

  useEffect(() => {
    showPriceAdjustmentWarningModal();
    checkIfDealIsValid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOffer, loadingStatus]);

  const handleCloseAdjustPriceModal = () => {
    setShowPriceAdjustmentModal("");

    if (priceAdjustmentAccordionRef.current) {
      priceAdjustmentAccordionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const serviceLines = (currentOffer?.service_areas ?? []).flatMap(
    (sa) => sa.service_lines
  );

  const allServiceCategories = serviceLines
    .flatMap((sl) => sl.service_groups)
    .flatMap((sg) => sg.service_categories);

  if (!allServiceCategories) {
    throw new Error("No service categories found");
  }

  const copyToClipBoard = async () => {
    if (!currentOffer?.customer?.org_number || !currentOffer?.id) {
      dispatch(
        appendError("OFFER_LINK_COPY_FAILED", {
          message: "Offer id or customer number missing",
        } as Error)
      );
      return;
    }

    const link = `${
      window.location.origin +
      OfferRouteHelper.getExistingSummary(
        currentOffer.customer.org_number,
        currentOffer.id
      )
    }`;
    try {
      await navigator.clipboard.writeText(link);
    } catch (err) {
      if (!copy(link)) {
        dispatch(appendError("OFFER_LINK_COPY_FAILED", err as Error));
      }
    } finally {
      dispatch(appendToastMessage("OFFER_LINK_COPIED", "success"));
    }
  };

  const toggleApproveServiceCategory = (serviceCategory: ServiceCategory) => {
    if (!currentOffer?.service_areas) {
      return;
    }

    const updatedAreas = ServiceCategory.updateServiceCategoryInServiceArea(
      currentOffer.service_areas,
      { ...serviceCategory, approved_by_email: user.email }
    );

    if (serviceCategory.approved_by_email) {
      renderUnapprovedModal(() => {
        dispatch(
          updateServicesAndPostDealData({
            service_areas: updatedAreas,
          })
        );
      });
      return;
    }

    dispatch(
      updateServicesAndPostDealData({
        service_areas: updatedAreas,
      })
    );
  };

  const isLoading = () => currentOffer?.id;

  return (
    <WizardSection
      loadingStatus={isLoading() ? "idle" : "pending"}
      className="mr-0 ml-0"
      isBackHidden={!isDealEditable(currentOffer)}
      isNextHidden={
        (!isDealEditable(currentOffer) &&
          !canUploadPhysicalDeal(currentOffer)) ||
        (isSmallRecurring() && !isApproved)
      }
      nextButtonText={translate("SUMMARY_PAGE.SEND_OFFER")}
      onBeforeNext={async () => sendDealForSigning()}
      content={
        <div className="w-100 d-flex justify-content-end">
          <TooltipInfoIcon
            tooltipIcon={
              <Copy16Regular
                className="cursor-pointer text-color-blue"
                onClick={copyToClipBoard}
              />
            }
            tooltip={{ content: "COPY_LINK_TO_OFFER" }}
          />
        </div>
      }
      approvalCheckbox={
        isSmallRecurring()
          ? {
              isApproved,
              setIsApproved,
              isDisabled: !isPriceValid && !user.hasRole(UserRoles.USERS_PILOT),
            }
          : undefined
      }
    >
      {!!renderExternalContent && <>{renderExternalContent} </>}
      <Modal
        header={showModal.header}
        footer={showModal.footer}
        isOpen={showModal.content !== ""}
        onDismiss={() => closeModal()}
        isWarning={showModal.warning}
        size="medium"
      >
        {showModal.content}
      </Modal>
      <Row>
        <Col xs={12}>
          <ServiceInfoTable
            serviceLines={serviceLines}
            onToggleApproveServiceCategory={toggleApproveServiceCategory}
            isLoading={loadingStatus === "pending"}
          />
        </Col>
        <Col xs={12} ref={priceAdjustmentAccordionRef}>
          <FinalPriceSection
            title={translate("PRICE_ADJUSTMENT")}
            loadingStatus={loadingStatus}
            shouldOpen={GetIsPriceAutoAdjusted()}
            lowestPossiblePrice={lowestPrice}
          />
        </Col>
        <Col xs={12}>
          <ContractTextsSection
            contractTexts={currentOffer?.contract_texts ?? []}
            title={translate("OFFER_ASSIGNMENT_AGREEMENT")}
          />
        </Col>
      </Row>

      {currentOffer?.state === "offer" && loadingStatus === "pending" ? (
        <OverlaySpinner />
      ) : null}

      {showPriceAdjustmentModal &&
        loadingStatus !== LoadingStatusEnum.PENDING && (
          <PriceAdjustmentModal
            handleCloseAdjustPriceModal={handleCloseAdjustPriceModal}
            showPriceAdjustmentModal={showPriceAdjustmentModal}
          />
        )}
    </WizardSection>
  );
}

export default Summary;
