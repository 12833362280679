import { Tab, TabList } from "@fluentui/react-components";
import { Col, Row } from "react-bootstrap";

import Tooltip from "components/tooltip/Tooltip";
import { useTranslation } from "hooks/use-translate";
import {
  Service,
  ServiceCategory,
  ServiceGroupItem,
} from "models/offer/ServiceLine";
import { ServiceValidity } from "models/offer/ServiceValidity";
import CurrentAccountingHeaderServices from "./CurrentAccountingHeaderServices";

type Props = {
  groupedServices: ServiceGroupItem[];
  servicesValidity: ServiceValidity[];
  selectedHeader: string;
  setSelectedHeader: (selectedHeader: string) => void;
  currentAccountingServices: Service[];
  setCurrentAccountingServices: React.Dispatch<React.SetStateAction<Service[]>>;
  currentAccountingInCurrentOffer: ServiceCategory;
  serviceGroup: string;
  setIsDirty: (value: React.SetStateAction<boolean>) => void;
};

export default function CurrentAccountingServiceDetailsForm({
  groupedServices,
  servicesValidity,
  selectedHeader,
  setSelectedHeader,
  currentAccountingServices,
  setCurrentAccountingServices,
  currentAccountingInCurrentOffer,
  serviceGroup,
  setIsDirty,
}: Props) {
  const { translate } = useTranslation();

  return (
    <div className="current-accounting-content mb-3">
      <Row className="current-accounting-service-header align-items-center mb-lg">
        <Col md="auto">{translate("SERVICE_HEADER")}:</Col>
        <Col>
          {groupedServices.length > 0 && (
            <TabList
              className="p-0 fw-semibold"
              selectedValue={selectedHeader}
              onTabSelect={(e, data) => setSelectedHeader(data.value as string)}
            >
              {groupedServices.map((headerData) => {
                const { header } = headerData;

                const isRequired = servicesValidity.some(
                  (sv) =>
                    sv.serviceHeader === header &&
                    (!sv.isValueValid || !sv.isFrequencyValid)
                );

                return (
                  <Tooltip
                    content={`${isRequired ? "* " : ""}${header}`}
                    notTranslatable
                    childrenClassName="current-accounting-tab"
                  >
                    <Tab
                      className="current-accounting-tab-content"
                      value={header}
                      key={header}
                    >
                      {`${isRequired ? "* " : ""}${header}`}
                    </Tab>
                  </Tooltip>
                );
              })}
            </TabList>
          )}
        </Col>
      </Row>
      <CurrentAccountingHeaderServices
        colSize={[70, undefined, "3", "3", "2"]}
        currentAccountingServices={currentAccountingServices}
        setCurrentAccountingServices={setCurrentAccountingServices}
        groupedServices={groupedServices}
        selectedHeader={selectedHeader}
        currentAccountingInCurrentOffer={currentAccountingInCurrentOffer}
        serviceGroup={serviceGroup}
        setIsDirty={setIsDirty}
        servicesValidity={servicesValidity}
      />
    </div>
  );
}
