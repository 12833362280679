/* eslint-disable no-underscore-dangle */
import { useSelector } from "react-redux";

import { useTranslation } from "hooks/use-translate";
import { isValidOrganizationNumberOrSSN } from "libs/is-valid-organization-number-or-ssn";
import { Deal } from "models/deals/deal";
import { Stage } from "models/deals/stage";
import { RootState } from "state";
import { FieldGroup } from "models/deals/fieldTypes";
import { Persona } from "components/people";
import { excludeBusinessAreas } from "constants/businessOpportunityConsts";
import {
  DealType,
  FieldGroupKeys,
} from "constants/enums/myBusinessOpportunities.enum";
import { useGetStaticField } from "hooks/myBusinessOpportunities/use-get-static-deal-fields";
import DealTextArea from "views/myBusinessOpportunities/components/configuration/components/DealTextArea";
import DealDropdown from "views/myBusinessOpportunities/components/configuration/components/DealDropdown";
import DealCheckbox from "views/myBusinessOpportunities/components/configuration/components/DealCheckbox";
import DealMultiSelectProductDropdown from "views/myBusinessOpportunities/components/configuration/components/DealMultiSelectProductDropdown";
import DealInput from "views/myBusinessOpportunities/components/configuration/components/DealInput";
import DealDatePicker from "views/myBusinessOpportunities/components/configuration/components/DealDatePicker";
import { ViewType } from "models/mgt";

// # Stages required fields

// To setup the configuration of a stage, go into the stages config and add/remove fields from the stage `requiredFields` array.

// # Fields data collection

// If the field itself is missing an implementation (a description of how to gather the data) add that implementation in the fields configuration.

/**
 * Configures how to order fields, in which group to show fields, which fields are required etc.
 * @returns
 */
export function useFieldsConfiguration() {
  const { translate } = useTranslation();
  const { getStaticField } = useGetStaticField();

  const sales = useSelector((state: RootState) => state.sales);
  if (sales.dealOptions.data.length === 0) {
    return { fieldGroups: [], fields: [] };
  }

  const dealsOptions = sales.dealOptions;
  const dealsBusinessAreas = dealsOptions.data.find(
    (d) => d.name === "affarsomrade_deal_"
  );
  const dealsStatuses = dealsOptions.data.find((d) => d.name === "deal_status");
  const dealsLostReasonOptions = dealsOptions.data.find(
    (d) => d.name === "lost_reason"
  );

  const fieldGroups: FieldGroup[] = [
    {
      name: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.PROCESSING"),
      key: FieldGroupKeys.PROCESSING,
      order: 0,
      fields: [
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.INTERNAL_NOTES"),
          property: "meddelande_salj",
          order: 0,
          showForDealTypes: "any",
          placeholder: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.INTERNAL_NOTES"
          ),
          render(deal: Deal, stage?: Stage) {
            return (
              <DealTextArea deal={deal} configuration={this} stage={stage} />
            );
          },
        },
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.PRIORITY"),
          property: "hs_priority",
          order: 0,
          showForDealTypes: "any",
          isValid: (deal: Deal) => !!deal.hs_priority,
          options: [
            {
              label: translate(
                "MY_BUSINESS_OPPORTUNITIES.PANEL.PRIORITY_DROPDOWN.OPTION.HIGH"
              ),
              value: "high",
            },
            {
              label: translate(
                "MY_BUSINESS_OPPORTUNITIES.PANEL.PRIORITY_DROPDOWN.OPTION.MEDIUM"
              ),
              value: "medium",
            },
            {
              label: translate(
                "MY_BUSINESS_OPPORTUNITIES.PANEL.PRIORITY_DROPDOWN.OPTION.LOW"
              ),
              value: "low",
            },
          ],
          placeholder: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.PRIORITY.PLACEHOLDER"
          ),
          render(deal: Deal, stage?: Stage) {
            return (
              <DealDropdown deal={deal} configuration={this} stage={stage} />
            );
          },
        },
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.PROCCESONG_STATUS"),
          property: "deal_status",
          order: 0,
          showForDealTypes: "any",
          options: dealsStatuses?.options,
          placeholder: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.PROCCESONG_STATUS"
          ),
          isValid: (deal: Deal) => !!deal.deal_status,
          render(deal: Deal, stage?: Stage) {
            return (
              <DealDropdown deal={deal} configuration={this} stage={stage} />
            );
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.CONDUCTED_FREE_CONSULTING"
          ),
          property: "free_consulting",
          order: 0,
          showForDealTypes: [DealType["Kostnadsfritt rådgivningsmöte"]],
          render(deal: Deal) {
            return <DealCheckbox deal={deal} configuration={this} option={1} />;
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.CARRIED_FREE_CONSULTING"
          ),
          property: "free_consulting",
          order: 0,
          showForDealTypes: [DealType["Kostnadsfri värdebedömning"]],
          render(deal: Deal) {
            return <DealCheckbox deal={deal} configuration={this} option={0} />;
          },
        },
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.BUSINESS_AREA"),
          property: "affarsomrade_deal_",
          order: 0,
          showForDealTypes: "any",
          placeholder: `${translate("SELECT")} ${translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.BUSINESS_AREA"
          )}`,
          options: dealsBusinessAreas?.options.filter(
            (o) => !excludeBusinessAreas.includes(o.label)
          ),
          isValid: (deal: Deal) => !!deal.affarsomrade_deal_,
          render(deal: Deal, stage?: Stage) {
            return (
              <DealDropdown
                deal={deal}
                configuration={this}
                disabled={deal.pog_created}
                stage={stage}
              />
            );
          },
        },
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.SERVICES"),
          property: "productIds",
          order: 0,
          showForDealTypes: "any",
          placeholder: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.SERVICES.PLACEHOLDER"
          ),
          isValid: (deal: Deal) => deal.productIds.length > 0,
          render(deal: Deal, stage?: Stage) {
            return (
              <DealMultiSelectProductDropdown
                deal={deal}
                configuration={this}
                disabled={deal.pog_created}
                stage={stage}
              />
            );
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.ESTIMATED_BUSINESS_VALUE"
          ),
          property: "amount",
          order: 0,
          showForDealTypes: "any",
          placeholder: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.ESTIMATED_BUSINESS_VALUE.PLACEHOLDER"
          ),
          isValid: (deal: Deal) => !!deal.amount,
          render(deal: Deal, stage?: Stage) {
            return (
              <DealInput
                configuration={this}
                deal={deal}
                type="number"
                disabled={deal.pog_created}
                stage={stage}
              />
            );
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.ESTIMATED_CLOSE_DATE"
          ),
          property: "closedate",
          order: 0,
          showForDealTypes: "any",
          isValid: (deal: Deal) => !!deal.closedate,
          render(deal: Deal, stage?: Stage) {
            return (
              <DealDatePicker deal={deal} configuration={this} stage={stage} />
            );
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.BUSINESS_LOSS_CAUSE"
          ),
          property: "lost_reason",
          order: 0,
          showForDealTypes: "any",
          placeholder: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.BUSINESS_LOSS_CAUSE.PLACEHOLDER"
          ),
          options: dealsLostReasonOptions?.options,
          isValid: (deal: Deal) => !!deal.lost_reason,
          render(deal: Deal, stage?: Stage) {
            return (
              <DealDropdown deal={deal} configuration={this} stage={stage} />
            );
          },
        },
      ],
    },
    {
      name: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY"),
      order: 2,
      key: FieldGroupKeys.COMPANY,
      fields: [
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.ORGANIZATION_NUMBER"
          ),
          property: "organisationsnummer",
          order: 0,
          showForDealTypes: "any",
          isValid: (deal) => {
            if (deal.organisationsnummer?.length > 11) {
              return false;
            }
            return isValidOrganizationNumberOrSSN(
              deal.organisationsnummer,
              true
            );
          },
          render(deal: Deal, stage?: Stage) {
            return (
              <DealInput
                deal={deal}
                configuration={this}
                disabled={sales.isLoading}
                stage={stage}
              />
            );
          },
        },
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_NAME"),
          property: "company",
          order: 0,
          showForDealTypes: "any",
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_FORM"),
          property: "bolagsform",
          order: 0,
          showForDealTypes: "any",
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.NEAREST_OFFICE"),
          property: "narmaste_kontor_deal",
          order: 0,
          showForDealTypes: "any",
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.CUSTOMER_STATUS"),
          property: "customer_status",
          order: 1,
          showForDealTypes: "any",
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.REVENUE"),
          property: "oms_ttning",
          order: 1,
          showForDealTypes: "any",
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
      ],
    },
    {
      name: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.ABOUT_THE_REQUEST"),
      order: 1,
      key: FieldGroupKeys.REQUEST,
      fields: [
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.REQUEST_CREATED_AT"
          ),
          property: "createdAt",
          order: -2,
          showForDealTypes: "any",
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.REQUEST_CREATED_BY"
          ),
          property: "km_deal_created_by_user_email",
          order: -2,
          showForDealTypes: "any",
          render(deal) {
            return deal.km_deal_created_by_user_email ? (
              <Persona
                userId={deal.km_deal_created_by_user_email}
                view={ViewType.oneline}
              />
            ) : (
              <div>-</div>
            );
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.OTHER_PROPERTY_INFO"
          ),
          property: "ovrig_info_fastighet",
          order: 0,
          showForDealTypes: [DealType["Kostnadsfri värdebedömning"]],
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.MUNICIPALITY_THE_PROPERTY_IS_LOCATED_IN"
          ),
          property: "state",
          order: -1,
          showForDealTypes: [DealType["Kostnadsfri värdebedömning"]],
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.FINANCE_SERVICES"),
          property: "efterfr_gade_tj_nster_ekonomi",
          order: -1,
          showForDealTypes: [DealType["Offertförfrågan ekonomi"]],
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.COUNTRY_OF_PROPERTY"
          ),
          property: "vardeindiktator_lan",
          order: 0,
          showForDealTypes: [DealType["Värdeindikator - fastighetsvärdering"]],
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.PROPERTY_SIZE_ARABLE_LAND"
          ),
          property: "vardeindikator_storlek_akermark_hektar_",
          order: 0,
          showForDealTypes: [DealType["Värdeindikator - fastighetsvärdering"]],
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.VALUE_ACCORDING_VALUE_INDICATOR"
          ),
          property: "v_rdeindikator_resultat",
          order: 0,
          showForDealTypes: [DealType["Värdeindikator - fastighetsvärdering"]],
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.PROPERTY_SIZE_FOREST_LAND"
          ),
          property: "vardeindikator_storlek_skogsmark_hektar_",
          order: 0,
          showForDealTypes: [DealType["Värdeindikator - fastighetsvärdering"]],
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.PROPERTY_SIZE_FOREST_LAND_M3SK"
          ),
          property: "v_rdeindikator_storlek_skogsmark_m3sk_",
          order: 0,
          showForDealTypes: [DealType["Värdeindikator - fastighetsvärdering"]],
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.VALUE_INDICATOR_PROPERTY_COMPARISON_YIELD_CAPACITY"
          ),
          property:
            "vardeindiktator_fastighet_i_jamforelse_med_andra_fastigheter_i_ditt_omr_de_avkastningsf_rm_ga_arron",
          order: 0,
          showForDealTypes: [DealType["Värdeindikator - fastighetsvärdering"]],
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.VALUE_INDICATOR_PROPERTY_COMPARED_TO_OTHER"
          ),
          property:
            "vardeindiktator_fastighet_i_jamforelse_med_andra_fastigheter_i_ditt_omrade",
          order: 0,
          showForDealTypes: [DealType["Värdeindikator - fastighetsvärdering"]],
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.PANEL.VALUE_INDICATOR_PROPERTY_DESIGNATION"
          ),
          property: "fastighetsbeteckning",
          order: -1,
          showForDealTypes: [DealType["Kostnadsfri värdebedömning"]],
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.CONTACT_MESSAGE"),
          property: "message",
          order: 2,
          showForDealTypes: "any",
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.SENT_FROM"),
          property: "recent_conversion_event_name",
          order: -1,
          showForDealTypes: "any",
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.REQUEST_TYPE"),
          property: "deal_type",
          order: -1,
          showForDealTypes: [],
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.SOURCE"),
          property: "deal_source",
          order: 2,
          showForDealTypes: "any",
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.ACTIVITY_EVENT"),
          property: "aktivitet",
          order: 3,
          showForDealTypes: "any",
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
        {
          label: translate(
            "MY_BUSINESS_OPPORTUNITIES.FILTERS.INBOUND_OUTBOUND"
          ),
          property: "inbound_outbound",
          order: 3,
          showForDealTypes: "any",
          render(deal: Deal) {
            return getStaticField(this.property, deal);
          },
        },
      ],
    },
  ];

  return {
    fieldGroups,
    fields: fieldGroups.flatMap((g) => g.fields),
  };
}
