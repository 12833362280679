import { DrawerBody, Spinner } from "@fluentui/react-components";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import OverlayDrawer from "components/OverlayDrawer";
import { CustomerDetailsParams, ServicesParams } from "routes";
import { dateLanguageFormatter } from "libs/date/date-format";
import { RootState } from "state";
import {
  fetchFormAnswers,
  resetFormAnswers,
  setFormAnswers,
} from "state/activities/actions";
import { useTranslation } from "hooks";
import { ActivityAppendix } from "models/activities/ActivityAppendix";
import {
  Activity,
  ActivityInstance,
  FormAnswer,
  FormQuestionAnswerType,
} from "models/activities/activity";
import { ActivityFormEnum } from "constants/enums/activityForms.enum";
import { DatePicker } from "components/date/DatePicker";
import DrawerHeader from "components/drawer/drawerHeader/DrawerHeader";
import { ShimmerCell } from "components/shimmer";
import { SpinnerSize } from "components/spinner";
import Switch from "components/switch";
import { YearlyEvaluationKYCFormRowReadonly } from "views/conveyorBelt/components/customerPage/customerPageKYCYearlyEvaluation/YearlyEvaluationKYCFormRowReadonly";
import { AdditionalTaxDocuments } from "views/conveyorBelt/components/serviceActivities/components/AdditionalTaxDocuments";
import "views/conveyorBelt/components/serviceActivities/components/ActivitiesTable.scss";
import { Dropdown } from "components/dropdown/Dropdown";

type DetailsPanelProps = {
  onDismiss: () => void;
  showActivitiesPanel: boolean;
  selectedActivity?: Activity;
};

export function ActivitiesDetailsPanel({
  showActivitiesPanel,
  onDismiss,
  selectedActivity,
}: DetailsPanelProps) {
  const { language, translate } = useTranslation();
  const { dataMap: definitionsMap } = useSelector(
    (state: RootState) => state.activities.definitions
  );
  const dispatch = useDispatch();

  const descriptions = useSelector(
    (state: RootState) => state.servicesDescriptions
  );
  const activities = useSelector((state: RootState) => state.activities);

  const formAnswers = useSelector(
    (state: RootState) => state.activities.formAnswers
  );
  const formatter = dateLanguageFormatter(language);

  const { customerId } = useParams<CustomerDetailsParams>();
  const { serviceId } = useParams<ServicesParams>();
  const customers = useSelector((state: RootState) => state.customers);

  const customerState = customers.data.find(
    (c) => c.customer.customer_number === customerId
  );

  const customerActivities = activities.data.find(
    (c) => c.org_number === customerState?.customer?.company_registration_number
  );

  const selectedActivityInstance: ActivityInstance | undefined =
    customerActivities?.services
      .find((s) => s.service_box_id === serviceId)
      ?.activities.find(
        (a) =>
          a.original_id === (selectedActivity as ActivityInstance)?.original_id
      );

  const service = customerActivities?.services.find(
    (s) => s.service_box_id === serviceId
  );

  useEffect(() => {
    if (
      !formAnswers.length &&
      selectedActivityInstance &&
      customerState?.customer &&
      selectedActivityInstance.form_id !== null &&
      service &&
      !!selectedActivityInstance.completed_at &&
      showActivitiesPanel
    ) {
      dispatch(
        fetchFormAnswers(
          customerState.customer,
          selectedActivityInstance,
          service?.year,
          service.service_box_id
        )
      );
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setFormAnswers([]));
    };
  }, [dispatch]);

  const renderShimmer = () => {
    return (
      <>
        <ShimmerCell />
        <ShimmerCell />
      </>
    );
  };

  if (!selectedActivity) {
    return null;
  }

  const activityServiceMappings = descriptions.data.filter((d) =>
    d.isMatchingActivity(selectedActivity)
  );

  const renderedAnswers = (tempFormAnswers: FormAnswer[]) => {
    return tempFormAnswers.map((answer, index) => {
      return (
        <Col xs={12} key={answer.question_id}>
          {answer.answer_type === FormQuestionAnswerType.DatePicker && (
            <>
              <div className="disabled">{answer.question}</div>
              <DatePicker
                disabled
                dateFormatter={(date) => formatter(date, "yyyy-MM-dd")}
                onSelectDate={() => {}}
                selectedDate={
                  answer.answer ? new Date(answer.answer as string) : new Date()
                }
                placeholder={answer.answer as string}
              />
            </>
          )}

          {answer.answer_type === FormQuestionAnswerType.Boolean &&
            Switch({
              checked: answer.answer === "true",
              label: answer.question,
              onToggleMethod: () => {},
              disabled: true,
            })}

          {answer.answer_type === FormQuestionAnswerType.Appendice &&
          answer.answer ? (
            <AdditionalTaxDocuments
              value={answer.answer as unknown as ActivityAppendix[]}
              onChange={(newValue) => {}}
              disabled
            />
          ) : null}

          {answer.answer_type === FormQuestionAnswerType.BooleanWithText && (
            <YearlyEvaluationKYCFormRowReadonly
              formAnswer={answer}
              index={index}
            />
          )}

          {answer.answer_type === FormQuestionAnswerType.Dropdown && (
            <Dropdown
              key={`${answer.question_id}MultiSelect`}
              label={answer.question}
              selectedOptions={[answer.answer as string]}
              options={[{text: answer.answer as string, value: answer.answer as string}]}
              onOptionSelect={() => {}}
              disabled
            />
          )}
        </Col>
      );
    });
  };

  const renderOneTimeDates = () => {
    const hasExistingDates = selectedActivity.existing_dates
      ? selectedActivity.existing_dates.length > 0
      : false;

    if (!hasExistingDates) {
      return null;
    }

    return (
      <div className="mb-xs">
        <span>
          {`* ${translate(
            "CB.ACTIVITY.DETAILS_PANEL.EXISTING_ONETIME_DATES"
          )}: `}
        </span>
        <span>
          {selectedActivity.existing_dates
            ?.map((d) => d.start_date)
            .sort()
            .join("; ")}
        </span>
      </div>
    );
  };

  return (
    <OverlayDrawer
      showPanel={showActivitiesPanel}
      modalType="non-modal"
      size={
        selectedActivityInstance &&
        selectedActivityInstance?.form_id ===
          ActivityFormEnum.FORM_5_KYC_YEARLY &&
        formAnswers.length > 0
          ? "large"
          : "medium"
      }
    >
      <DrawerHeader
        onDismiss={() => {
          dispatch(resetFormAnswers());
          onDismiss();
        }}
        hasJSXContent
        header={
          <div className="title-with-separator">
            {selectedActivity && (
              <div className="activity-panel-header">
                <span>
                  {selectedActivity.getTitle(
                    language,
                    definitionsMap[selectedActivity.activity_type]
                  )}
                </span>
                <span className="sub-header">
                  {(selectedActivity as ActivityInstance).activity_id}
                </span>
              </div>
            )}
          </div>
        }
      />
      <DrawerBody>
        {!activityServiceMappings.length &&
          descriptions.isLoading &&
          renderShimmer()}
        {renderOneTimeDates()}
        {selectedActivity &&
          !activityServiceMappings.length &&
          !descriptions.isLoading && (
            <p>
              {`Kunde ej hitta någon tjänst med typen '
              ${selectedActivity?.activity_type}' i ServiceMatrisen.`}
            </p>
          )}

        {activityServiceMappings.length > 0 &&
          activityServiceMappings.map((description) => (
            <div className="mb-4">
              <h3>{description.getProcessName(language)}</h3>
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: description.getDescription(language),
                }}
              />

              {description.getUrl() && (
                <a href={description.getUrl()} rel="noreferrer" target="_blank">
                  {translate("READ_MORE")}
                </a>
              )}
            </div>
          ))}

        {/* add check for completed_at */}
        {selectedActivityInstance?.form_id &&
          !!selectedActivityInstance.completed_at && (
            <Row className="gy-md">
              <Col xs={12}>
                <div className="horizontal-divider my-lg" />
              </Col>

              {!formAnswers.length && (
                <Col xs={12}>
                  <Spinner size={SpinnerSize.ExtraSmall} />
                </Col>
              )}

              {formAnswers.length > 0 && (
                <>
                  <Col xs={12}>
                    <h5 className="">
                      {translate("DEDUCTION_WARNING_HEADER")}
                    </h5>
                  </Col>

                  {renderedAnswers(formAnswers)}
                </>
              )}
            </Row>
          )}
      </DrawerBody>
    </OverlayDrawer>
  );
}
