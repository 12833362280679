import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ErrorCircle20Regular } from "@fluentui/react-icons";

import { RootState } from "state";
import { SearchBy, searchForCustomer } from "state/offer/companyThunks";
import {
  fetchDeal,
  patchOffer,
  sendOfferForSigning,
} from "state/offer/offersThunks";
import { AppDispatch } from "state/use-app-redux";
import { useTranslation } from "hooks/use-translate";
import { useServiceMatrix } from "libs/service-matrix";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { isPlaceholderEmail } from "libs/generate-placeholder-email";
import { SigningMethod } from "models/offer/DealContact";
import { OfferRouteHelper } from "views/createNew/offer/routes/offerRoutes";
import InvalidSigningMethodModal from "views/createNew/offer/wizard/Summary/components/modals/InvalidSigningMethodModal";
import UnapprovedServicesModal from "views/createNew/offer/wizard/Summary/components/modals/UnapprovedServicesModal";
import MissingProjectManagerModal from "views/createNew/offer/wizard/Summary/components/modals/MissingProjectManagerModal";
import MissingSmallBusinessYear from "views/createNew/offer/wizard/Summary/components/modals/MissingSmallBusinessYear";
import { appendError } from "state/notifications";
import { ServicesContext } from "views/createNew/offer/wizard/Services/ServicesContextProvider";

export default function useGetDealSummaryModal(
  offerId?: string,
  orgId?: string
) {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const {
    currentOffer: { data: currentOffer },
  } = useSelector((state: RootState) => state.offers);

  const {
    GetCategoriesWithMissingPM,
    GetCategoriesWithMissingYear,
    IsSignerValid,
    GetSigner,
  } = useServiceMatrix();

  const [showModal, setShowModal] = useState<{
    header: ReactNode;
    content: ReactNode;
    footer?: ReactNode;
    warning?: boolean;
  }>({ header: "", content: "" });
  const [renderExternalContent, setRenderExternalContent] =
    useState<ReactNode>("");

  const { updateServiceGroups, setTaxObjectsInContext } =
    useContext(ServicesContext);

  const fetchDealAndSaveToContext = useCallback(
    async (dealId: string, orgNum: string) => {
      const fetchedDeal = await dispatch(fetchDeal({ dealId, orgId: orgNum }))
        .unwrap()
        .catch((e) => {
          dispatch(
            appendError("FAILED_TO_FETCH_DEAL", {
              cause: e,
            } as unknown as Error)
          );
        });

      if (fetchedDeal) {
        fetchedDeal.service_areas.forEach((serviceArea) => {
          serviceArea.service_lines.forEach((serviceLine) => {
            serviceLine.service_groups.forEach((serviceGroup) => {
              updateServiceGroups(serviceGroup);
              const serviceGroupName = serviceGroup.name;
              const taxObjects = fetchedDeal.tax_objects;
              setTaxObjectsInContext(serviceGroupName, taxObjects);
            });
          });
        });
      }
    },
    [dispatch, setTaxObjectsInContext, updateServiceGroups]
  );

  useEffect(() => {
    if (orgId && offerId && !currentOffer?.id) {
      dispatch(
        searchForCustomer({
          searchData: {
            customerId: orgId,
            searchByParam: SearchBy.OrgNumber,
          },
          checkCustomerBlocked: false,
        })
      );
      fetchDealAndSaveToContext(offerId, orgId);
    }
  }, [orgId, offerId, dispatch, currentOffer?.id, fetchDealAndSaveToContext]);

  const closeModal = () => {
    setShowModal({ header: "", content: "" });
  };

  const handleSendDigitalDeal = async () => {
    if (currentOffer?.id) {
      closeModal();

      const response = await dispatch(
        sendOfferForSigning({ dealId: currentOffer.id })
      );

      if (response.meta.requestStatus === "fulfilled") {
        navigate(OfferRouteHelper.getCompletion());
      }

      if (response.meta.requestStatus === "rejected") {
        showFailedSendForSigningModal();
      }
    }
  };

  const handleSendPhysicalDeal = async () => {
    if (currentOffer?.id) {
      closeModal();

      if (currentOffer && currentOffer.state === "contract") {
        navigate(OfferRouteHelper.getManualSignKyc());
        return;
      }

      const response = await dispatch(patchOffer({ state: "contract" }));

      if (response.meta.requestStatus === "fulfilled") {
        navigate(OfferRouteHelper.getManualSignKyc());
      }
    }
  };

  const showFailedSendForSigningModal = () => {
    setShowModal({
      header: <ModalHeader headerTitleText="WARNING" />,
      content: (
        <div className="d-flex px-lg pb-xs text-color-red align-items-center">
          <ErrorCircle20Regular className="mr-sm" />
          <span className="p-xxs ">{translate("FAILS_TO_SEND_OFFER")}</span>
        </div>
      ),
      warning: true,
    });
  };

  const renderUnapprovedModal = (onApprove: () => void) => {
    setShowModal({
      header: <ModalHeader headerTitleText="WARNING" />,
      footer: (
        <ModalFooter
          onSave={() => {
            onApprove();
            closeModal();
          }}
          onCancel={closeModal}
          labelSubmit="YES"
          labelCancel="NO"
        />
      ),
      content: (
        <div className="d-flex flex-column justify-content-center">
          <div className="p-xs d-flex ">
            {translate("UNAPPROVE_MODAL_MESSAGE")}
          </div>
        </div>
      ),

      warning: true,
    });
  };

  const showSendDigitalDealConfirmationModal = () => {
    setShowModal({
      header: <ModalHeader headerTitleText="CONFIRMATION_MODAL_TITLE" />,
      footer: (
        <ModalFooter
          labelCancel="NO_CANCEL"
          onCancel={closeModal}
          labelSubmit="YES_SEND_OFFER"
          onSave={handleSendDigitalDeal}
        />
      ),
      content: (
        <div className="d-flex flex-column p-xxs">
          <div className="d-flex flex-column p-xxs pb-lg">
            <div className="fw-bold d-flex justify-content-center">
              {translate("CONFIRMATION_MODAL_TEXT_1")}
            </div>
            <div className="d-flex justify-content-center mt-md">
              {translate("CONFIRMATION_MODAL_TEXT_2")}
            </div>
          </div>
        </div>
      ),
    });
  };

  const showSendPhysicalDealConfirmationModal = () => {
    setShowModal({
      header: (
        <ModalHeader headerTitleText="CONFIRMATION_PHYSICAL_MODAL_TITLE" />
      ),
      footer: (
        <ModalFooter
          labelCancel="NO_CANCEL"
          onCancel={closeModal}
          labelSubmit="YES_PROCEED"
          onSave={handleSendPhysicalDeal}
        />
      ),
      content: (
        <div className="d-flex flex-column p-xxs">
          <div className="d-flex flex-column p-xxs">
            <div className="fw-bold d-flex justify-content-center">
              {translate("CONFIRMATION_PHYSICAL_MODAL_TEXT_1")}
            </div>
            <div className="d-flex justify-content-center mt-md">
              {translate("CONFIRMATION_PHYSICAL_MODAL_TEXT_2")}
            </div>
          </div>
        </div>
      ),
    });
  };

  const sendDealForSigning = async () => {
    const isDealMissingContactData = !(currentOffer?.contacts || []).length;

    if (!IsSignerValid() || isDealMissingContactData) {
      setRenderExternalContent(
        <InvalidSigningMethodModal
          onCancel={() => setRenderExternalContent("")}
        />
      );
      return false;
    }

    const signerContact = GetSigner();
    let isSigningMethodPhysical = false;
    if (
      signerContact?.is_signer &&
      signerContact.signing_method === SigningMethod.PHYSICAL
    ) {
      // If we have selected a physical signing method we send the deal for signing in the next step
      isSigningMethodPhysical = true;
    }

    // check if all selected services are approved by manager
    if (!currentOffer?.id) {
      setRenderExternalContent(
        <UnapprovedServicesModal
          onCancel={() => setRenderExternalContent("")}
        />
      );

      return false;
    }

    // check if there is a service category that is missing project manager
    const servicesMissingProjectManager = GetCategoriesWithMissingPM();
    if (servicesMissingProjectManager.length) {
      setRenderExternalContent(
        <MissingProjectManagerModal
          missingPMforServices={servicesMissingProjectManager.map(
            (s) => s.name
          )}
          onCancel={() => setRenderExternalContent("")}
        />
      );
      return false;
    }

    const servicesMissingYear = GetCategoriesWithMissingYear();
    if (servicesMissingYear.length) {
      setRenderExternalContent(
        <MissingSmallBusinessYear
          onCancel={() => setRenderExternalContent("")}
        />
      );

      return false;
    }

    // check if the signing method has valid data for the signing method
    if (!isSigningMethodPhysical) {
      const contactHasInvalidEmail =
        isPlaceholderEmail(signerContact?.contact.email ?? "") ||
        signerContact?.contact.email === "";

      if (contactHasInvalidEmail) {
        setRenderExternalContent(
          <InvalidSigningMethodModal
            onCancel={() => setRenderExternalContent("")}
          />
        );
        return false;
      }
    }

    if (isSigningMethodPhysical) {
      showSendPhysicalDealConfirmationModal();
    } else {
      showSendDigitalDealConfirmationModal();
    }

    return false;
  };

  return {
    showModal,
    setShowModal,
    renderUnapprovedModal,
    showSendDigitalDealConfirmationModal,
    showSendPhysicalDealConfirmationModal,
    closeModal,
    renderExternalContent,
    setRenderExternalContent,
    sendDealForSigning,
  };
}
