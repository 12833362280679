import { Col, Row } from "react-bootstrap";

import { useTranslation } from "hooks/use-translate";
import { FormQuestion } from "models/activities/activity";
import { YearlyEvaluationKYCFormRow } from "./YearlyEvaluationKYCFormRow";
import "./YearlyEvaluationKYCForm.scss";

type YearlyEvaluationKYCFormContentProps = {
  formQuestions: FormQuestion[];
};

export function YearlyEvaluationKYCFormContent({
  formQuestions,
}: YearlyEvaluationKYCFormContentProps) {
  const { translate } = useTranslation();

  return (
    <div className="justify-content-center pt-sm kyc-content">
      <Row className="px-lg py-sm  align-items-center justify-content-between fw-600">
        <Col md={1}>
          <span>#</span>
        </Col>
        <Col md={6}>
          <span>{translate("QUESTION")}</span>
        </Col>
        <Col md={5}>
          <span>{translate("ANSWER")} *</span>
        </Col>
      </Row>

      {formQuestions
        .sort((a, b) => a.sort_order - b.sort_order)
        .map((question, index) => (
          <div key={question.question_id}>
            <YearlyEvaluationKYCFormRow question={question} index={index} />
            <div className="horizontal-divider my-sm" />
          </div>
        ))}
    </div>
  );
}
