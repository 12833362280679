/* eslint-disable no-continue */
import { Dispatch, SetStateAction } from "react";
import { Col, Row } from "react-bootstrap";

import { useTranslation } from "hooks/use-translate";
import {
  Service,
  ServiceCategory,
  ServiceGroupItem,
} from "models/offer/ServiceLine";
import { ServiceValidity } from "models/offer/ServiceValidity";
import useValidateSmallRecurringCustomersServices from "hooks/createNew/offer/use-validate-small-recurring-customers-services";
import { TOGGLE } from "constants/offer/input-form-types";
import ServiceColumn, { ColSize } from "views/createNew/offer/wizard/components/service-column/index";
import ServiceRow from "views/createNew/offer/wizard/components/service-row/index";

type Props = {
  colSize: ColSize[];
  currentAccountingServices: Service[];
  setCurrentAccountingServices: Dispatch<SetStateAction<Service[]>>;
  groupedServices: ServiceGroupItem[];
  selectedHeader: string;
  currentAccountingInCurrentOffer: ServiceCategory;
  serviceGroup: string;
  setIsDirty: (value: React.SetStateAction<boolean>) => void;
  servicesValidity: ServiceValidity[]
};

export default function CurrentAccountingHeaderServices({
  colSize,
  currentAccountingServices,
  setCurrentAccountingServices,
  groupedServices,
  selectedHeader,
  currentAccountingInCurrentOffer,
  serviceGroup,
  setIsDirty,
  servicesValidity
}: Props) {
  const { translate } = useTranslation();

    const { validateNumericInput, validateTextInput } =
      useValidateSmallRecurringCustomersServices();

  const tableColumns = [
    { id: 1, title: translate("ON_OFF"), colSize: 0 },
    {
      id: 2,
      title: `${translate("SERVICE")} / ${translate("SETTINGS")}`,
      colSize: 1,
    },
    { id: 3, title: `${translate("VALUE")} *`, colSize: 2 },
    { id: 4, title: `${translate("FREQUENCY")} *`, colSize: 3 },
    { id: 5, title: translate("RESPONSIBLE"), colSize: 4 },
  ];

  const isServiceToggled = (service: Service) => {
    return currentAccountingServices.some(
      (toggledServices) =>
        toggledServices.service_matrix_id === service.service_matrix_id
    );
  };

  const group = groupedServices.find((g) => g.header === selectedHeader);
  /**
   * Iterates over services in the selected group, identifying the first
   * with an invalid value or, failing that, the first with an invalid frequency.
   *
   * Returns the matrix ID of the first invalid service, or undefined.
   */
  const getFirstInvalidServiceInputId = () => {
    if (!group) {
      return;
    }
    for (const service of group!.services) {
      const serviceValidity = servicesValidity.find(
        (sv) => sv.serviceMatrixId === service.service_matrix_id
      );

      if (!serviceValidity) {
        continue;
      }

      const serviceWithValueIsPresent = currentAccountingServices.some(
        (s) =>
          s.header === group!.header &&
          isServiceToggled(s) &&
          s.input_form_type !== TOGGLE
      );

      if (serviceWithValueIsPresent) {
        if (!serviceValidity.isValueValid) {
          return service.service_matrix_id;
        }

        continue;
      }

      if (!serviceValidity.isFrequencyValid) {
        return service.service_matrix_id;
      }
    }
  };

  return (
    <Row>
      <Col className="px-lg">
        <Row className="fw-semibold mb-4">
          {tableColumns.map((column) => (
            <ServiceColumn colSize={colSize[column.colSize]} key={column.id}>
              {column.title}
            </ServiceColumn>
          ))}
        </Row>
        {group && group.services.map((service, index, services) => {
          const isLast = services.length === index + 1;

          const activeService = currentAccountingServices.find(
            (currService) =>
              currService.service_matrix_id === service.service_matrix_id
          );

          return (
            <ServiceRow
              key={`${service.service_matrix_id}${service.name}`}
              colSize={colSize}
              isLast={isLast}
              service={activeService || service}
              offerApprovedByEmail={
                currentAccountingInCurrentOffer.approved_by_email
              }
              serviceIsInitiallyActive={
                service.input_form_type_state && service.input_is_disabled
              }
              serviceIsToggled={isServiceToggled(service)}
              triggerInputFocus={
                getFirstInvalidServiceInputId() === service.service_matrix_id
              }
              serviceGroup={serviceGroup}
              setIsDirty={setIsDirty}
              setCurrentAccountingServices={setCurrentAccountingServices}
              validateNumericInput={validateNumericInput}
              validateTextInput={validateTextInput}
            />
          );
        })}
      </Col>
    </Row>
  );
}
