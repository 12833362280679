/* eslint-disable camelcase */
import { useSelector } from "react-redux";

import AppConfig from "app-config";
import { useAuth } from "auth/use-auth";
import { CustomerDeal } from "models/offer/CustomerDeal";
import { CustomerDealSlim } from "models/offer/CustomerDealSlim";
import { DealContact } from "models/offer/DealContact";
import {
  PricePrerequisite,
  Service,
  ServiceCategory,
} from "models/offer/ServiceLine";
import { RootState } from "state";
import {
  SERVICE_CATEGORY_CURRENT_ACCOUNTING_SMALL_FIXED,
  SERVICE_CATEGORY_INCOME_TAX,
  SERVICE_GROUP_CURRENT_ACCOUNTING,
} from "views/createNew/offer/wizard/consts/offer-contst";
import { ACCOUNTING_SERVICE_LINE } from "constants/servicesConsts";
import { isValidEmail } from "./is-valid-email";
import { isValidSwedishSSN } from "./is-valid-ssn";

enum DeliveryMethod {
  Digital = "Digital",
  Flexible = "Flexibel",
}

export enum FinalPriceComparisonType {
  EQUAL = "equal",
  HIGHER = "higher",
}

export const useServiceMatrix = () => {
  const { user } = useAuth();

  const {
    currentOffer: { data: currentOffer },
  } = useSelector((state: RootState) => state.offers);

  const GetAllCategoriesFlattened = (
    offer?: CustomerDeal | CustomerDealSlim
  ) => {
    if (offer && offer instanceof CustomerDealSlim) {
      return [];
    }
    const serviceAreas = (offer ?? currentOffer)?.service_areas;
    if (!serviceAreas) {
      return [];
    }

    const serviceLines = serviceAreas.flatMap((sa) => sa.service_lines);

    const serviceGroups = serviceLines.flatMap((sl) => sl.service_groups);

    const serviceCategories = serviceGroups.flatMap(
      (sg) => sg.service_categories
    );

    return serviceCategories;
  };

  const GetAllServiceGroupsFlattened = (offer?: CustomerDeal) => {
    const serviceAreas = (offer ?? currentOffer)?.service_areas;
    if (!serviceAreas) {
      return [];
    }

    const serviceLines = serviceAreas.flatMap((sa) => sa.service_lines);

    const serviceGroups = serviceLines.flatMap((sl) => sl.service_groups);

    return serviceGroups;
  };

  const GetAllServiceLinesFlattened = (offer?: CustomerDeal) => {
    const serviceAreas = (offer ?? currentOffer)?.service_areas;
    if (!serviceAreas) {
      return [];
    }

    const serviceLines = serviceAreas.flatMap((sa) => sa.service_lines);

    return serviceLines;
  };

  const GetTotalIndexedPrice = (offer?: CustomerDealSlim) => {
    const categories = GetAllCategoriesFlattened(offer);
    const indexedPrices = categories.flatMap((sc) => sc.indexed_prices);
    // the biggest year is always the current pricing year
    // (ex. if currently its 2023 but there are indexed prices with 2024, then 2024 is the current pricing year)
    if (indexedPrices.length > 0) {
      const currentYear = indexedPrices.reduce((a, b) =>
        a.pricing_year > b.pricing_year ? a : b
      ).pricing_year;
      const currentIndexedPrices = indexedPrices.filter(
        (indexPrice) => indexPrice.pricing_year === currentYear
      );
      return currentIndexedPrices.reduce(
        // eslint-disable-next-line camelcase
        (n, { index_price }) => n + index_price,
        0
      );
    }
    return 0;
  };

  const GetCurrentPricingYear = (offer?: CustomerDealSlim) => {
    const categories = GetAllCategoriesFlattened(offer);
    const indexedPrices = categories.flatMap((sc) => sc.indexed_prices);
    // the biggest year is always the current pricing year
    // (ex. if currently its 2023 but there are indexed prices with 2024, then 2024 is the current pricing year)
    if (indexedPrices.length > 0) {
      return indexedPrices.reduce((a, b) =>
        a.pricing_year > b.pricing_year ? a : b
      ).pricing_year;
    }
    return "";
  };

  type ServicesGroupedByHeaderType = { header: string; services: Service[] };
  const GetServicesGroupedByHeader = ({
    offer,
    groupByNameToo = false,
  }: {
    offer?: CustomerDealSlim;
    groupByNameToo?: boolean;
  }): ServicesGroupedByHeaderType[] => {
    const categories = GetAllCategoriesFlattened(offer);

    const result: ServicesGroupedByHeaderType[] = [];
    categories.forEach((serviceCategory) => {
      let tempServices: Service[] = [...serviceCategory.services];
      //
      // Group all services by name (because same service can be in different service categories)
      //
      if (groupByNameToo) {
        const tempServicesMap = new Map<string, Service>();
        serviceCategory.services.forEach((service) => {
          const prevService = tempServicesMap.get(service.name);
          const newService = { ...service };

          if (prevService) {
            tempServicesMap.set(service.name, {
              ...prevService,
              units: prevService.units + newService.units,
            });
          } else {
            tempServicesMap.set(service.name, newService);
          }
        });

        tempServices = Array.from(tempServicesMap.values());
      }

      //
      // Group all services by header
      //
      const serviceHeaders = new Map<string, Service[]>();
      tempServices.forEach((service) => {
        const prevServices = serviceHeaders.get(service.header);
        const newService = { ...service };

        if (prevServices) {
          serviceHeaders.set(service.header, [...prevServices, newService]);
        } else {
          serviceHeaders.set(service.header, [newService]);
        }
      });

      // Map serviceHeaders (type Map) to result (type array)
      serviceHeaders.forEach((services, header) => {
        result.push({ header, services });
      });
    });

    return result;
  };

  const GetFinalPrice = (
    serviceCategory?: ServiceCategory,
    offer?: CustomerDeal
  ) => {
    if (serviceCategory) {
      return serviceCategory.final_price ?? 0;
    }

    const categories = GetAllCategoriesFlattened(offer);
    return categories?.reduce((acc, sg) => acc + sg.final_price, 0) ?? 0;
  };

  const GetMonthlyFinalPrice = (
    serviceCategory?: ServiceCategory,
    offer?: CustomerDealSlim
  ) => {
    if (serviceCategory) {
      return serviceCategory.final_price_monthly ?? 0;
    }

    const categories = GetAllCategoriesFlattened(offer);
    return (
      categories?.reduce(
        (acc, sg) => acc + (sg?.final_price_monthly ?? 0),
        0
      ) ?? 0
    );
  };

  const GetEstimatedCost = (
    serviceCategory?: ServiceCategory,
    offer?: CustomerDeal
  ) => {
    if (serviceCategory) {
      return serviceCategory.estimated_cost ?? 0;
    }

    const categories = GetAllCategoriesFlattened(offer);
    return (
      categories?.reduce((acc, sg) => acc + (sg.estimated_cost ?? 0), 0) ?? 0
    );
  };

  const isSmallRecurring = (offer?: CustomerDealSlim) => {
    const categories = GetAllCategoriesFlattened(offer ?? currentOffer);
    return (
      categories.length > 0 &&
      categories.every(
        (sg) => sg.name === SERVICE_CATEGORY_CURRENT_ACCOUNTING_SMALL_FIXED
      )
    );
  };

  const getPricePrerequisiteLowestPrice = (
    pricePrerequisite: PricePrerequisite
  ): number => {
    const { min_discounted_price = 0, lowest_possible_price = 0 } =
      pricePrerequisite;
    return min_discounted_price > lowest_possible_price
      ? min_discounted_price
      : lowest_possible_price;
  };

  const GetLowestPossiblePrice = (serviceCategory?: ServiceCategory) => {
    if (isSmallRecurring()) {
      if (serviceCategory && serviceCategory.price_prerequisite) {
        return getPricePrerequisiteLowestPrice(
          serviceCategory.price_prerequisite
        );
      }
      const categories = GetAllCategoriesFlattened(currentOffer);
      return (
        categories?.reduce((acc, sg) => {
          return (
            acc +
            (sg?.price_prerequisite
              ? getPricePrerequisiteLowestPrice(sg.price_prerequisite)
              : 0)
          );
        }, 0) ?? 0
      );
    }
  };

  const GetMinPriceAdjustment = () => {
    const categories = GetAllCategoriesFlattened(currentOffer);
    const minPriceAdjustment =
      categories?.reduce((acc, sg) => {
        const priceAdjustment =
          sg.price_prerequisite?.min_price_adjustment ?? 0;
        return acc + priceAdjustment;
      }, 0) ?? 0;
    return minPriceAdjustment;
  };

  // The check if price is auto adjusted for Existing customers
  // is done by getting the min_price_adjustment value from price_prerequisite
  // if min_price_adjustment is greater than 0, means that the price has been auto adjusted
  const GetIsPriceAutoAdjusted = (serviceCategory?: ServiceCategory) => {
    if (serviceCategory && serviceCategory.price_prerequisite) {
      return serviceCategory.price_prerequisite.min_price_adjustment > 0;
    }
    const categories = GetAllCategoriesFlattened(currentOffer);
    const isPriceAdjusted =
      categories?.every(
        (sg) =>
          (sg.price_prerequisite?.min_price_adjustment &&
            sg.price_prerequisite.min_price_adjustment > 0 &&
            sg.price_prerequisite.min_price_adjustment ===
              GetTotalAdjustments()) ??
          false
      ) ?? false;
    return isPriceAdjusted;
  };

  // The check if price is lower than lowest possible price for Existing customers
  // is done by comparing the proposed_price from price_prerequisite and final_price
  // the final_price should always be equal or greater than proposed_price
  // for new customers, final_price cannot be lower than lowest_possible_price
  const GetIsFinalPriceLowerThanLowestPossible = (
    serviceCategory?: ServiceCategory
  ) => {
    const lowestPossiblePrice = GetLowestPossiblePrice(serviceCategory) ?? 0;
    return GetFinalPrice() < lowestPossiblePrice;
  };

  const GetWarningMessage = (serviceCategory?: ServiceCategory) => {
    if (serviceCategory && serviceCategory.price_prerequisite) {
      return serviceCategory.price_prerequisite.warning_message ?? "";
    }
    const categories = GetAllCategoriesFlattened(currentOffer);
    const warningMessage = categories[0].price_prerequisite?.warning_message;
    return warningMessage ?? "";
  };

  const GetIsDigitalDeliveryMethod = () => {
    const categories = GetAllCategoriesFlattened(currentOffer);
    const isDigital =
      categories?.every(
        (sg) =>
          sg.price_prerequisite?.analog_customer === DeliveryMethod.Digital
      ) ?? false;
    return isDigital;
  };

  const GetCalculatedPrice = (serviceCategory?: ServiceCategory) => {
    if (serviceCategory) {
      return serviceCategory?.calculated_price ?? 0;
    }

    const categories = GetAllCategoriesFlattened();
    return categories.reduce((acc, sg) => acc + sg.calculated_price, 0) ?? 0;
  };

  const GetTotalDiscount = (serviceCategory?: ServiceCategory) => {
    if (serviceCategory) {
      return serviceCategory?.discount ?? 0;
    }

    const categories = GetAllCategoriesFlattened();
    return categories?.reduce((acc, sg) => acc + sg.discount, 0) ?? 0;
  };

  // The total suggested price is calculated by subtracting the min_price_adjustment
  // from the calculated_price only if the price_adjustment on category level is 0 or lower
  // than the price_prerequisite.min_price_adjustment
  const GetTotalSuggestedPrice = (serviceCategory?: ServiceCategory) => {
    if (serviceCategory) {
      if (
        serviceCategory.price_prerequisite?.min_price_adjustment &&
        serviceCategory.price_adjustment <=
          serviceCategory.price_prerequisite.min_price_adjustment
      ) {
        return (
          serviceCategory.calculated_price -
          (serviceCategory.price_prerequisite.min_price_adjustment ?? 0)
        );
      }
      return (
        serviceCategory.calculated_price -
        (serviceCategory.price_adjustment ?? 0)
      );
    }

    const categories = GetAllCategoriesFlattened();
    const minPriceAdjustment = GetMinPriceAdjustment();

    const priceAdjustment =
      categories.reduce((acc, sg) => acc + (sg.price_adjustment ?? 0), 0) ?? 0;

    if (minPriceAdjustment && priceAdjustment <= minPriceAdjustment) {
      return GetCalculatedPrice() - minPriceAdjustment;
    }

    return (
      categories?.reduce(
        (acc, sg) => acc + sg.calculated_price - sg.price_adjustment,
        0
      ) ?? 0
    );
  };

  const GetTotalAdjustments = (serviceCategory?: ServiceCategory) => {
    if (serviceCategory) {
      return serviceCategory.price_adjustment ?? 0;
    }

    const categories = GetAllCategoriesFlattened();
    return categories?.reduce((acc, sg) => acc + sg.price_adjustment, 0) ?? 0;
  };

  const GetCategoriesWithMissingPM = () => {
    const categories = GetAllCategoriesFlattened();
    return categories.filter((sc) => !sc.project_manager);
  };

  const GetCategoriesWithMissingYear = () => {
    const categories = GetAllCategoriesFlattened();

    return categories.filter((sc) => {
      // This serviceCategory doesn't have year end specified
      if (
        sc.name === SERVICE_CATEGORY_INCOME_TAX ||
        sc.name === SERVICE_GROUP_CURRENT_ACCOUNTING ||
        sc.name === SERVICE_CATEGORY_CURRENT_ACCOUNTING_SMALL_FIXED
      ) {
        return false;
      }

      return !sc.year_end_year;
    });
  };

  const GetRolesThatCanApproveOffer = () =>
    AppConfig.OFFER.COMPETENT_SYSTEM_ROLES.filter(
      (role) => role.maxApprovalAmount >= GetFinalPrice()
    ).map((r) => r.systemRole);

  const IsUserAuthorizedToApprove = () => {
    const rolesThatCanApproveThisOffer = GetRolesThatCanApproveOffer();

    const result =
      user &&
      user.systemRoles &&
      user.systemRoles.some((userRole) =>
        rolesThatCanApproveThisOffer.includes(userRole)
      );

    return result;
  };

  const GetSigner = () => {
    return currentOffer?.contacts.find(
      (tempDealContact) => tempDealContact.is_signer
    );
  };

  const IsSignerValid = (dealContact?: DealContact) => {
    const signer =
      dealContact ||
      currentOffer?.contacts.find(
        (tempDealContact) => tempDealContact.is_signer
      );

    if (!signer) {
      return false;
    }

    if (!isValidSwedishSSN(signer.contact.social_security_number)) {
      return false;
    }

    if (!isValidEmail(signer.contact.email)) {
      return false;
    }

    return true;
  };

  /**
   *
   * @param options.serviceCategory If you omit options or option.serviceCategory then this will check the all serviceCategories in the offer
   * @param options.checkIfApprovedByEmail Checks if a user has approved the offer
   */
  const CanSendForSigning = (options?: {
    serviceCategory?: ServiceCategory;
    checkIfUserHasApproved?: boolean;
  }) => {
    const serviceCategory = options?.serviceCategory;
    const checkIfUserHasApproved = options?.checkIfUserHasApproved ?? true;

    if (GetFinalPrice(serviceCategory) === 0) {
      return false;
    }

    if (IsUserAuthorizedToApprove()) {
      return true;
    }

    if (checkIfUserHasApproved && serviceCategory?.approved_by_email) {
      return true;
    }

    if (
      GetFinalPrice(serviceCategory) <=
        AppConfig.OFFER.AUTO_APPROVAL_AMOUNT_LESS_THAN &&
      GetTotalDiscount(serviceCategory) === 0
    ) {
      return true;
    }

    if (GetTotalDiscount(serviceCategory) > 0) {
      return false;
    }

    return false;
  };

  const CanApproveOffer = (serviceCategory: ServiceCategory) =>
    CanSendForSigning({ serviceCategory, checkIfUserHasApproved: false });

  const CanSendCategoryForSigning = (serviceCategory: ServiceCategory) =>
    CanSendForSigning({ serviceCategory });

  const CanSendOfferForSigning = () => CanSendForSigning();

  const isNewCustomerAccounting = (offer?: CustomerDeal) => {
    const serviceAreas = (offer ?? currentOffer)?.service_areas;
    if (!serviceAreas) {
      return false;
    }
    const serviceLines = serviceAreas.flatMap((sa) => sa.service_lines);
    const accountingServiceLines = serviceLines.filter(
      (line) => line.name === ACCOUNTING_SERVICE_LINE
    );
    return accountingServiceLines.every((line) => line.new_customer);
  };

  return {
    GetAllCategoriesFlattened,
    GetSigner,
    IsSignerValid,
    CanSendCategoryForSigning,
    CanSendOfferForSigning,
    CanApproveOffer,
    GetCalculatedPrice,
    GetTotalSuggestedPrice,
    GetTotalAdjustments,
    GetFinalPrice,
    GetEstimatedCost,
    GetTotalDiscount,
    GetCategoriesWithMissingPM,
    GetCategoriesWithMissingYear,
    GetServicesGroupedByHeader,
    GetTotalIndexedPrice,
    GetCurrentPricingYear,
    GetAllServiceGroupsFlattened,
    GetAllServiceLinesFlattened,
    GetLowestPossiblePrice,
    isSmallRecurring,
    GetIsPriceAutoAdjusted,
    GetMinPriceAdjustment,
    GetIsDigitalDeliveryMethod,
    GetIsFinalPriceLowerThanLowestPossible,
    GetWarningMessage,
    isNewCustomerAccounting,
    GetMonthlyFinalPrice,
  };
};
