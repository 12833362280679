/* eslint-disable camelcase */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { RootState } from "state";
import { useAppDispatch } from "state/use-app-redux";
import { updateCurrentOffer } from "state/offer/offersSlice";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import {
  SERVICE_CATEGORY_CURRENT_ACCOUNTING_SMALL_FIXED,
  SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY_EF,
  SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY_EF,
} from "views/createNew/offer/wizard/consts/offer-contst";
import { uniqByFilter } from "libs/uniq-filter";
import { getMonthsOfOneYear } from "libs/date/generate-month";
import {
  getServiceCategoryFromEconomyServiceArea,
  Service,
  ServiceCategory,
  ServiceGroupItem,
} from "models/offer/ServiceLine";
import { ServiceValidity } from "models/offer/ServiceValidity";
import { useTranslation } from "hooks/use-translate";
import useValidateSmallRecurringCustomersServices from "hooks/createNew/offer/use-validate-small-recurring-customers-services";
import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import Breadcrumb, { BreadcrumbDefinitionItem } from "components/breadcrumb";
import { ACCOUNTING_SERVICE_LINE } from "constants/servicesConsts";
import { ModalFooter } from "components/modal/ModalFooter";
import { useGetBreadcrumbs } from "views/createNew/offer/components/wizardSection/useGetBreadcrumbs";
import ServiceDetailsHeader from "views/createNew/offer/wizard/components/service-details-header/index";
import { WizardSection } from "views/createNew/offer/components/wizardSection/index";
import { useRoutingForOffer } from "views/createNew/offer/components/wizardSection/useRoutingForOffer";
import ServicesFormData from "views/createNew/offer/wizard/Services/ServicesFormData";
import CurrentAccountingSmallRecurringServiceDetails from "views/createNew/offer/wizard/CurrentAccountingSmallRecurring";
import CurrentAccountingServiceDetails from "views/createNew/offer/wizard/CurrentAccounting/index";
import { useServiceMatrix } from "libs/service-matrix";
import "./currentAccounting.scss";

export type CurrentAccountingForm = ServicesFormData & {
  yearEndYear: string | null;
  yearEndMonth: string;
  finalPrice?: number;
  services: Service[];
};

type Props = {
  serviceLine: string;
  serviceGroup: string;
  serviceCategory: string;
};

export default function CurrentAccountingWrapper({
  serviceLine,
  serviceGroup,
  serviceCategory,
}: Props) {
  const { translate, ts } = useTranslation();
  const { getBreadcrumbs } = useGetBreadcrumbs();
  const { isNewCustomerAccounting } = useServiceMatrix();
  const navigate = useNavigate();
  const { edit } = useParams<{ edit: string }>();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const defaultStartDate = getMonthsOfOneYear()[0];
  const [modalOpen, setOpenModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [pendingChangesModalOpen, setPendingChangesModalOpen] = useState(false);

  const [showTaxObjects, setShowTaxObjects] = useState(false);

  const isSmallRecurring =
    serviceCategory === SERVICE_CATEGORY_CURRENT_ACCOUNTING_SMALL_FIXED;

  const user = useSelector((state: RootState) => state.users.currentUser);
  const serviceAreas = useSelector(
    (state: RootState) => state.offers.offerTemplate.data?.service_areas
  );

  if (!serviceAreas) {
    throw new Error("Missing service areas");
  }

  const { data: currentOffer, status: loadingStatus } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  if (!currentOffer) {
    throw new Error("No active offer");
  }

  const routing = useRoutingForOffer(location.pathname, currentOffer);
  const { service_areas: serviceAreasFromOffer } = currentOffer;

  const { validateServices } = useValidateSmallRecurringCustomersServices();

  const currentAccountingTemplate =
    getServiceCategoryFromEconomyServiceArea(
      serviceLine,
      serviceGroup,
      serviceCategory,
      serviceAreas
    ) ?? new ServiceCategory();

  const currentAccountingInCurrentOffer =
    getServiceCategoryFromEconomyServiceArea(
      serviceLine,
      serviceGroup,
      serviceCategory,
      serviceAreasFromOffer
    ) ?? new ServiceCategory();

  const isEF =
    serviceGroup === SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY_EF ||
    serviceGroup === SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY_EF;

  const {
    start_date,
    end_date,
    project_manager,
    frequency,
    year_end_year,
    year_end_month,
    final_price,
    services: servicesInOffer,
  } = currentAccountingInCurrentOffer;

  const yearEndMountSmallRecurring = isEF ? "12" : year_end_month ?? "";

  const [currentAccountingData, setCurrentAccountingData] =
    useState<CurrentAccountingForm>({
      startDate: start_date || defaultStartDate,
      endDate: end_date,
      projectManager: project_manager || user.email,
      yearEndYear: year_end_year,
      yearEndMonth: isSmallRecurring
        ? yearEndMountSmallRecurring
        : year_end_month || "12",
      frequency: frequency || currentAccountingTemplate.frequency,
      ...(!isSmallRecurring ? { finalPrice: final_price } : {}),
      services: [],
    });

  const [isYearEndMonthValid, setIsYearEndMonthValid] = useState(
    !!currentAccountingData.yearEndMonth
  );

  useEffect(() => {
    setIsYearEndMonthValid(!!currentAccountingData.yearEndMonth);
  }, [currentAccountingData.yearEndMonth]);

  const serviceHeaders = uniqByFilter(
    currentAccountingTemplate.services.map((service) => service.header)
  );

  const activeServices = servicesInOffer.length
    ? servicesInOffer
    : currentAccountingTemplate.services.filter(
        (service) => service.input_form_type_state
      );

  const [currentAccountingServices, setCurrentAccountingServices] = useState<
    Service[]
  >([...activeServices]);

  useEffect(() => {
    setServicesValidity(validateServices(currentAccountingServices));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccountingServices, currentAccountingTemplate.frequency]);

  const servicesForHeader = (header: string) => {
    const filteredServices = currentAccountingTemplate.services.filter(
      (service) => {
        const isMatchingHeader = service.header === header;
        const isNewCustomer = isNewCustomerAccounting();
        const isVisibleForNewCustomer = isNewCustomer
          ? !service.new_customer_input_is_hidden
          : true;

        return isMatchingHeader && isVisibleForNewCustomer;
      }
    );

    return Service.sortServices(filteredServices);
  };

  const groupedServices: ServiceGroupItem[] = serviceHeaders
    .map((header) =>
      header !== ""
        ? {
            header,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            sortingNumber: currentAccountingTemplate.services.find(
              (service) => service.header === header
            )!.pog_header_sorting_number,
            services: servicesForHeader(header),
          }
        : null
    )
    .filter((item): item is ServiceGroupItem => item !== null)
    .sort((a, b) => a.sortingNumber - b.sortingNumber);

  const [selectedHeader, setSelectedHeader] = useState(
    groupedServices[0]?.header
  );

  const [servicesValidity, setServicesValidity] = useState<ServiceValidity[]>(
    []
  );

  const isValid = () => {
    return servicesValidity.every(
      (sv) => sv.isValueValid && sv.isFrequencyValid
    );
  };

  const getServiceLineWithCurrentAccountingData = (
    data = currentAccountingData
  ) => {
    // Save the data to the service line in deal object
    const newCurrentAccountingData: ServiceCategory = {
      ...currentAccountingInCurrentOffer,
      project_manager: data.projectManager,
      start_date: data.startDate,
      frequency: data.frequency,
      year_end_year: data.yearEndYear,
      year_end_month: data.yearEndMonth,
      ...(!isSmallRecurring ? { final_price: data.finalPrice } : {}),
      services: currentAccountingServices,
    };
    const newServiceAreas = ServiceCategory.updateServiceCategoryInServiceArea(
      serviceAreasFromOffer,
      newCurrentAccountingData
    );

    return { service_areas: newServiceAreas };
  };

  const updateServiceCategoryData = (
    formHeaderData?: Partial<CurrentAccountingForm>
  ) => {
    const newCurrentAccountingFormData = {
      ...currentAccountingData,
      ...formHeaderData,
    };
    setCurrentAccountingData(newCurrentAccountingFormData);
    const updatedServiceLine = getServiceLineWithCurrentAccountingData(
      newCurrentAccountingFormData
    );
    dispatch(updateCurrentOffer(updatedServiceLine));
    setIsDirty(true);
  };

  const nexButtonText = isSmallRecurring
    ? translate("SERVICE_PAGE.CALCULATE_PRICE_BUTTON")
    : translate("ADD_FINAL_PRICE_FOR_DEAL");

  const serviceFormHeader = (
    <ServiceDetailsHeader
      startDateOnChange={(value) => {
        updateServiceCategoryData({
          ...currentAccountingData,
          startDate: value,
        });
      }}
      startDate={currentAccountingData.startDate}
      projectManagerOnChange={(personId) => {
        updateServiceCategoryData({
          ...currentAccountingData,
          projectManager: personId ?? "",
        });
      }}
      projectManager={currentAccountingData.projectManager}
      frequencyOnChange={(value) => {
        updateServiceCategoryData({
          ...currentAccountingData,
          frequency: value,
        });
      }}
      frequency={currentAccountingData.frequency}
      yearEndData={currentAccountingData}
      yearEndInCurrentOffer={currentAccountingInCurrentOffer}
      updateServiceCategoryData={(value) => {
        updateServiceCategoryData({
          ...currentAccountingData,
          yearEndMonth: value,
        });
      }}
      showYearEndValidationMessage={isSmallRecurring && !isYearEndMonthValid}
      yearEndSelectionDisabled={isSmallRecurring && isEF}
      requiredYearEndMonth={isSmallRecurring && !isEF}
    />
  );

  const handleOnBeforePrevious = () => {
    if (isDirty) {
      setPendingChangesModalOpen(true);
      return;
    }

    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
  };

  const smallRecurringForm = (
    <CurrentAccountingSmallRecurringServiceDetails
      pendingChangesModalOpen={pendingChangesModalOpen}
      setPendingChangesModalOpen={setPendingChangesModalOpen}
      currentAccountingServices={currentAccountingServices}
      currentAccountingData={currentAccountingData}
      showTaxObjects={showTaxObjects}
      setShowTaxObjects={setShowTaxObjects}
      groupedServices={groupedServices}
      servicesValidity={servicesValidity}
      selectedHeader={selectedHeader}
      setSelectedHeader={setSelectedHeader}
      getServiceLineWithCurrentAccountingData={
        getServiceLineWithCurrentAccountingData
      }
      serviceGroup={serviceGroup}
      setIsDirty={setIsDirty}
      currentAccountingTemplate={currentAccountingTemplate}
      currentAccountingInCurrentOffer={currentAccountingInCurrentOffer}
      setCurrentAccountingServices={setCurrentAccountingServices}
      modalOpen={modalOpen}
      setOpenModal={setOpenModal}
      updateServiceCategoryData={updateServiceCategoryData}
    />
  );

  const currentAccountingForm = (
    <CurrentAccountingServiceDetails
      pendingChangesModalOpen={pendingChangesModalOpen}
      setPendingChangesModalOpen={setPendingChangesModalOpen}
      getServiceLineWithCurrentAccountingData={
        getServiceLineWithCurrentAccountingData
      }
      setIsDirty={setIsDirty}
      currentAccountingServices={currentAccountingServices}
      currentAccountingData={currentAccountingData}
      currentAccountingInCurrentOffer={currentAccountingInCurrentOffer}
      updateServiceCategoryData={updateServiceCategoryData}
      groupedServices={groupedServices}
      servicesValidity={servicesValidity}
      selectedHeader={selectedHeader}
      setSelectedHeader={setSelectedHeader}
      modalOpen={modalOpen}
      setOpenModal={setOpenModal}
      setServicesValidity={setServicesValidity}
      setCurrentAccountingData={setCurrentAccountingData}
      serviceGroup={serviceGroup}
      setCurrentAccountingServices={setCurrentAccountingServices}
    />
  );

  return edit ? (
    <Modal
      className="service-modal"
      isOpen={edit !== undefined}
      onDismiss={() => {
        if (isDirty) {
          setPendingChangesModalOpen(true);
          return;
        }
        setPendingChangesModalOpen(false);
        navigate(-1);
      }}
      size="fit-content-min"
      header={
        <ModalHeader
          headerTitleContent={
            <div className="header-blue-stripe d-flex align-items-center">
              <Breadcrumb
                className="ml-sm"
                items={
                  getBreadcrumbs(
                    ts(ACCOUNTING_SERVICE_LINE),
                    true
                  ) as BreadcrumbDefinitionItem[]
                }
              />
            </div>
          }
        />
      }
      footer={
        <ModalFooter
          labelSubmit="ADD_FINAL_PRICE_FOR_DEAL"
          onSave={() => setOpenModal(true)}
          isDisabled={
            !currentAccountingServices.length ||
            !currentAccountingData.frequency ||
            !isValid()
          }
          isLoading={loadingStatus === LoadingStatusEnum.PENDING}
        />
      }
    >
      <>
        <div className="contentHeader">{serviceFormHeader}</div>
        {isSmallRecurring ? smallRecurringForm : currentAccountingForm}
      </>
    </Modal>
  ) : (
    <WizardSection
      serviceName={ts(ACCOUNTING_SERVICE_LINE)}
      subtitles={[translate("CURRENT_ACCOUNTING_SUBTITLE")]}
      loadingStatus={loadingStatus}
      hideNavigation={showTaxObjects}
      nextButtonText={nexButtonText}
      isNextHidden={
        !currentAccountingServices.length ||
        !currentAccountingData.frequency ||
        !isValid() ||
        !isYearEndMonthValid
      }
      onBeforePrevious={handleOnBeforePrevious}
      isRoutingButton={false}
      onNext={() => setOpenModal(true)}
      hasDivider
      content={serviceFormHeader}
      headerShrink
    >
      {isSmallRecurring ? smallRecurringForm : currentAccountingForm}
    </WizardSection>
  );
}
