import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { useGetCompanyTypesQuery } from "api/ludvigApi";
import { SolidAccordion } from "components/accordion/solidAccordion";
import { OverlaySpinner } from "components/spinner";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { usePogOffer } from "hooks/pogOffers/use-pog-offer";
import { useTranslation } from "hooks/use-translate";
import CompanyInfoDetailsComponent from "shared/CompanyInfo/CompanyInfoDetailsComponent";
import { RootState } from "state";
import { resetSelectedCustomerSource } from "state/offer/offersSlice";
import { resetOffer, setupNewOfferForCustomer } from "state/offer/offersThunks";
import { resetCurrentDeal } from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";
import CustomerContactsList from "views/createNew/offer/components/customerContactsList";
import Offers from "views/createNew/offer/components/offers";
import { WizardSection } from "views/createNew/offer/components/wizardSection";
import PotentialDeals from "../components/potentialDeals";
import CustomerSource from "../CustomerSource";
import { ServicesContext } from "../Services/ServicesContextProvider";
import "./Business.scss";
import { useFilterDealsAndContracts } from "./useFilterDealsAndContracts";
import { useFilterPotentialDeals } from "./useFilterPotentialDeals";

export default function Business() {
  const { translate } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  useGetCompanyTypesQuery();

  const { data: currentOffer, status } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  const offersStatus = useSelector(
    (state: RootState) => state.offers.createdDeals.status
  );
  const { status: customerContactsStatus, data: customerContacts } =
    useSelector((state: RootState) => state.offers.availableCompanyContacts);
  const {
    dealsByOrgNumber: { isLoading: isPotentialDealsLoading },
    currentDeal,
  } = useSelector((state: RootState) => state.sales);

  const { data: companyInfoData } = useSelector(
    (state: RootState) => state.offers.companyInfo
  );

  const [customerSourceIsOpen, setCustomerSourceIsOpen] = useState(false);

  const { startCreatingPogOffer, createPogOfferLoading } = usePogOffer();

  const { resetServicesContext } = useContext(ServicesContext);

  const filteredPotentialDeals = useFilterPotentialDeals();
  const {
    filteredAndSortedContracts,
    filteredDealsContracts,
    filteredAndSortedOffers,
    filteredDealsOffers,
    totalOffersCount,
    totalContractsCount,
  } = useFilterDealsAndContracts();

  const hasServicesSelected =
    (currentOffer?.service_areas[0].service_lines.length ?? 0) > 0;
  const customer = currentOffer?.customer;

  useEffect(() => {
    if (currentOffer?.new_customer && !hasServicesSelected) {
      dispatch(resetOffer());
    }
  }, [hasServicesSelected, currentOffer, dispatch]);

  useEffect(() => {
    if (customer) {
      dispatch(resetCurrentDeal());
      resetServicesContext();
      dispatch(resetSelectedCustomerSource());
      dispatch(setupNewOfferForCustomer(customer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnNext = () => {
    if (currentDeal && !!currentDeal.deal_source && customer) {
      startCreatingPogOffer(currentDeal, customer);
    } else {
      setCustomerSourceIsOpen(true);
    }
  };

  const handleSourceDialogConfirm = () => {
    setCustomerSourceIsOpen(false);
    if (currentDeal && customer) {
      startCreatingPogOffer(currentDeal, customer);
    }
  };

  const isNextButtonHidden = () => {
    return (
      isPotentialDealsLoading ||
      offersStatus === LoadingStatusEnum.PENDING ||
      customerContactsStatus === LoadingStatusEnum.PENDING
    );
  };

  return (
    <WizardSection
      loadingStatus={status}
      hideNavigation={!customer}
      isRoutingButton={false}
      onNext={handleOnNext}
      subtitles={[translate("CUSTOMER_DETAILS_SUBHEADER")]}
      isNextHidden={isNextButtonHidden()}
    >
      {createPogOfferLoading && (
        <OverlaySpinner label={translate("CREATE_POG_OFFER_LOADER")} />
      )}
      <Row className="pt-xl">
        <Col className="bottom-border" />
        <Col className="business-customer-name">
          <h2 className="text-align-center fw-700 text-color-primary">
            {customer?.customer_name}
          </h2>
        </Col>
        <Col className="bottom-border" />
      </Row>
      <Row className="customer-details justify-content-center text-align-center pt-lg">
        <CompanyInfoDetailsComponent companyInfo={companyInfoData} />
      </Row>
      <Row className="pt-xl">
        <div className="d-flex align-items-center pb-lg">
          <div className="w-100">
            <Row className="pt-lg">
              <Col md={12} className="m-auto">
                <SolidAccordion
                  title={
                    <>
                      {translate("BUSINESS_OPPORTUNITIES")}{" "}
                      <span className="disabled">
                        ({filteredPotentialDeals.length})
                      </span>
                    </>
                  }
                  subtitles={
                    isPotentialDealsLoading ||
                    filteredPotentialDeals.length === 0
                      ? []
                      : ["|", translate("POTENTIAL_DEALS_ACCORDION_SUBTITLE")]
                  }
                  isInitiallyOpen
                  loadingStatus={isPotentialDealsLoading ? "pending" : ""} // we only care if its loading in order to display a spinner
                >
                  <PotentialDeals
                    filteredPotentialDeals={filteredPotentialDeals}
                  />
                </SolidAccordion>
                <SolidAccordion
                  title={
                    <>
                      {translate("OFFER_OFFERS_LABEL")}{" "}
                      <span className="disabled">({totalOffersCount})</span>
                    </>
                  }
                  loadingStatus={offersStatus}
                >
                  <Offers
                    filteredAndSortedContracts={filteredAndSortedOffers}
                    filteredDeals={filteredDealsOffers}
                    count={totalOffersCount}
                    noDealsFound={translate("OFFERS_NOT_FOUND")}
                  />
                </SolidAccordion>
                <SolidAccordion
                  title={
                    <>
                      {translate("OFFER_CONTRACTS_LABEL")}{" "}
                      <span className="disabled">({totalContractsCount})</span>
                    </>
                  }
                  loadingStatus={offersStatus}
                >
                  <Offers
                    filteredAndSortedContracts={filteredAndSortedContracts}
                    filteredDeals={filteredDealsContracts}
                    count={totalContractsCount}
                    noDealsFound={translate("CONTRACTS_NOT_FOUND")}
                  />
                </SolidAccordion>
                <SolidAccordion
                  title={
                    <>
                      {translate("CONTACTS")}{" "}
                      <span className="disabled">
                        ({customerContacts.length})
                      </span>
                    </>
                  }
                  loadingStatus={customerContactsStatus}
                >
                  <CustomerContactsList
                    contacts={customerContacts ?? []}
                    showNumberOfItems={3}
                    editable
                  />
                </SolidAccordion>
              </Col>
            </Row>
          </div>
        </div>
      </Row>
      <CustomerSource
        isOpen={customerSourceIsOpen}
        customer={customer}
        handleCancel={() => setCustomerSourceIsOpen(false)}
        handleConfirm={handleSourceDialogConfirm}
      />
    </WizardSection>
  );
}
