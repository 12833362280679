import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { CompanyAPI } from "api/company";
import { CustomersAPI } from "api/customers";
import { authMethod } from "auth";
import { TranslationKey } from "i18n";
import { parseApplicationError } from "errors/errors";
import { normalizeOrgNrAndSSN } from "libs/number-format";
import { CompanyInformation } from "models/offer/Company";
import { DealCustomer } from "models/offer/Customer";
import DealContactDetails from "models/offer/DealContactDetails";
import { RootState } from "state";
import { appendError } from "state/notifications";
import { updateOfferCustomer } from "./offersSlice";
import { fetchDealsSlim } from "./offersThunks";

export enum SearchBy {
  OrgNumber = "org_number",
  CustomerNumber = "customer_number",
}

export type SearchData = {
  searchByParam: SearchBy;
  customerId: string;
};

export type SearchCustomer = {
  searchData: SearchData;
  checkCustomerBlocked: boolean;
};

export type PayloadActionWithError = PayloadAction & {
  error?: {
    message: string;
    name: string;
    stack: string;
  };
};

export const fetchCustomerBilling = createAsyncThunk(
  "offers/FETCH_CUSTOMER_BILLING",
  async (organizationNumber: string) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const response = await CustomersAPI.fetchCustomerBilling(
        token,
        organizationNumber
      );
      return response;
    } catch (error) {
      const parsedError = parseApplicationError(error as Error);
      if (parsedError?.args?.length && parsedError.args[0].includes("404")) {
        console.error("Customer does not have billings ", parsedError);
      } else {
        throw error;
      }
    }
  }
);

export const fetchCustomerContactsByOrgNr = createAsyncThunk(
  "offers/FETCH_CUSTOMER_CONTACTS",
  async (orgNr: string, { rejectWithValue }) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const contacts = await CustomersAPI.fetchCustomerContacts(token, orgNr);

      return contacts;
    } catch (error) {
      return rejectWithValue("FAILED_TO_FETCH_CONTACTS");
    }
  }
);

type CreateOrUpdateRequest = {
  contactData: DealContactDetails;
  orgNum?: string;
  disableErrorNotification?: boolean;
};

export const createOrUpdateCompanyContact = createAsyncThunk(
  "offers/UPDATE_OR_CREATE_CUSTOMER_CONTACT",
  async (
    {
      contactData,
      orgNum,
      disableErrorNotification = false,
    }: CreateOrUpdateRequest,
    { dispatch, getState }
  ) => {
    try {
      const token = await authMethod.getStoredAccessToken();

      let tempOrgNum = orgNum;
      if (!orgNum) {
        const currentOffer = (getState() as RootState).offers.currentOffer
          ?.data;
        if (currentOffer?.customer) {
          tempOrgNum = currentOffer.customer.org_number;
        }
      }

      tempOrgNum = normalizeOrgNrAndSSN(tempOrgNum);

      const contact = await CustomersAPI.createOrUpdateContact(
        token,
        contactData,
        tempOrgNum
      );

      return contact;
    } catch (error) {
      if (!disableErrorNotification) {
        dispatch(
          appendError("FAILED_TO_CREATE_OR_UPDATE_CONTACT", {
            cause: error,
          } as unknown as Error)
        );
      }

      throw error;
    }
  }
);

export const createOrUpdateCustomer = createAsyncThunk(
  "offers/UPDATE_OR_CREATE_CUSTOMER",
  async (dealCustomerData: DealCustomer, { dispatch }) => {
    try {
      const token = await authMethod.getStoredAccessToken();

      const customer = await CustomersAPI.createOrUpdateCustomer(
        token,
        dealCustomerData
      );

      return customer;
    } catch (error) {
      dispatch(
        appendError("FAILED_TO_CREATE_OR_UPDATE_CUSTOMER", {
          cause: error,
        } as unknown as Error)
      );

      throw error;
    }
  }
);

export const fetchCompanyInfoFromSales = createAsyncThunk(
  "offers/FETCH_COMPANY_INFO_BY_ORG_NUMBER",
  async (searchData: SearchData, { rejectWithValue }) => {
    const token = await authMethod.getStoredAccessToken();
    try {
      const company = await CompanyAPI.fetchCompanyFromSales(
        token,
        normalizeOrgNrAndSSN(searchData.customerId),
        searchData.searchByParam
      );
      return company;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadCustomerData = createAsyncThunk(
  "offers/LOAD_CUSTOMER_DATA",
  async (customerOrgNumber: string, { dispatch }) => {
    try {
      // await dispatch(fetchDeals(customerOrgNumber)).unwrap();
      await dispatch(fetchDealsSlim(customerOrgNumber)).unwrap();
      await dispatch(fetchCustomerContactsByOrgNr(customerOrgNumber)).unwrap();
    } catch (error) {
      dispatch(appendError(error as TranslationKey, error as Error));
    }
  }
);

export const fetchCustomerAndAddToOffer = createAsyncThunk(
  "offers/FETCH_CUSTOMER_AND_ADD_TO_OFFER",
  async (customer: Partial<DealCustomer>, { dispatch }) => {
    try {
      dispatch(updateOfferCustomer(customer));

      if (customer.org_number) {
        dispatch(loadCustomerData(customer.org_number));
      } else {
        dispatch(
          appendError("FAILED_TO_FETCH_COMPANY_PARTIAL", {
            cause: "Customer org number was not found",
          } as unknown as Error)
        );
        return;
      }
    } catch (e) {
      dispatch(appendError("FAILED_TO_FETCH_CUSTOMER", e as Error));
    }
  }
);

export const searchForCustomer = createAsyncThunk(
  "offers/SEARCH_CUSTOMER",
  async (
    searchCustomer: SearchCustomer,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const company = await dispatch(
        fetchCompanyInfoFromSales(searchCustomer.searchData)
      ).unwrap();
      const {
        users: { currentUser },
      } = getState() as RootState;

      if (searchCustomer.checkCustomerBlocked) {
        if (!company.org_number) {
          dispatch(
            appendError("MISSING_ORGANISATION_NUMBER", {
              cause: "Customer org number was not found",
            } as unknown as Error)
          );
          return;
        }

        const customerBilling = await dispatch(
          fetchCustomerBilling(company.org_number)
        ).unwrap();

        if (customerBilling?.isBlocked) {
          return "CUSTOMER_BLOCKED";
        }
      }

      const dealCustomer = DealCustomer.fromCompanyInfo(company, currentUser);
      if (dealCustomer) {
        dispatch(fetchCustomerAndAddToOffer(dealCustomer));
        return dealCustomer;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

type UpdateCompanyInfoRequest = {
  customerNumber: string;
  company: Partial<CompanyInformation>;
};

export const updateCompanyInfo = createAsyncThunk(
  "offers/UPDATE_COMPANY_INFO_BY_CUSTOMER_NUMBER",
  async (
    { customerNumber, company }: UpdateCompanyInfoRequest,
    { dispatch }
  ) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      return CompanyAPI.updateCompany(token, customerNumber, company);
    } catch (error) {
      dispatch(
        appendError(
          "MANAGE_CUSTOMERS.FAILED_TO_UPDATE_COMPANY_INFO",
          error as Error
        )
      );
    }
  }
);
