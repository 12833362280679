import { Settings24Regular } from "@fluentui/react-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Tooltip from "components/tooltip/Tooltip";
import { isUserTeamOwner } from "libs/customer-settings-helpers";
import { Customer } from "models/customer";
import { CompanyInformation } from "models/offer/Company";
import { Row } from "react-bootstrap";
import { AppRouteHelper } from "routes";
import CompanyInfoDetailsComponent from "shared/CompanyInfo/CompanyInfoDetailsComponent";
import { RootState } from "state";

type Props = {
  companyInfo: CompanyInformation;
  customer: Customer;
};

export default function CustomerSettingsCompanyInfo({
  companyInfo,
  customer,
}: Props) {
  const navigate = useNavigate();

  const { currentUser } = useSelector((state: RootState) => state.users);

  return (
    <div className="p-lg cs-content">
      <h2>
        {companyInfo?.name}{" "}
        {isUserTeamOwner(customer, currentUser) && (
          <Tooltip content="OPEN_CUSTOMER_SETTINGS">
            <Settings24Regular
              onClick={() =>
                navigate({
                  pathname: AppRouteHelper.getCustomerSettings(
                    companyInfo.customer_number
                  ),
                })
              }
            />
          </Tooltip>
        )}
      </h2>
      <Row className="pt-lg d-flex">
        <CompanyInfoDetailsComponent companyInfo={companyInfo} />
      </Row>
    </div>
  );
}
