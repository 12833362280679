import { UpdateSettingsData } from "models/customerSettings/customerSettingUpdateData";
import AppConfig from "../app-config";
import { BasicInfo } from "../models/customerSettings/customerSettingsBasicInfo";
import { CustomerSettingsContact } from "../models/customerSettings/customerSettingsContact";
import { DetailedInfo } from "../models/customerSettings/customerSettingsDetailedInfo";
import { DetailedInfoCategory } from "../models/customerSettings/customerSettingsDetailedInfoCategory";
import { DetailedInfoSubCategory } from "../models/customerSettings/customerSettingsDetailedInfoSubcategory";
import { PowerOfAttorneyStatus } from "../models/customerSettings/customerSettingsPoweOfAttorneyStatus";
import { PowerOfAttorney } from "../models/customerSettings/customerSettingsPowerofAttorney";
import { PowerOfAttorneySource } from "../models/customerSettings/customerSettingsPowerOfAttorneySource";
import { PowerOfAttorneyType } from "../models/customerSettings/customerSettingsPowerOfAttorneyType";
import { ServiceLine } from "../models/customerSettings/customerSettingsServiceLine";
import { ServiceNote } from "../models/customerSettings/customerSettingsServiceNote";
import { ServiceNoteDb } from "../models/customerSettings/customerSettingsServiceNoteDb";
import { CustomerSettingsServiceLine } from "../models/customerSettings/customerSettingsSettingsServiceLine";
import { getAuthorizationHeader } from "./libs/auth-header";

export function mapContactFromDb(
  contact: Partial<
    Partial<CustomerSettingsContact> & {
      firstName: string;
      lastName: string;
      mobile: string;
    }
  >
) {
  const firstname = contact.firstName;
  const lastname = contact.lastName;
  const mobile = contact.mobile;
  delete contact["firstName"];
  delete contact["lastName"];
  delete contact["mobile"];

  const mapped: CustomerSettingsContact = {
    firstname,
    lastname,
    mobilephone: mobile,
    ...contact,
  } as CustomerSettingsContact;
  return mapped;
}
export function mapContactToDb(contact: Partial<CustomerSettingsContact>) {
  const { firstname } = contact;
  const { lastname } = contact;
  const { mobilephone } = contact;
  delete contact["firstname"];
  delete contact["lastname"];
  delete contact["mobilephone"];
  const mapped: CustomerSettingsContact = {
    firstName: firstname,
    lastName: lastname,
    mobile: mobilephone,
    ...contact,
  } as CustomerSettingsContact;
  return mapped;
}

export default class SettingsAPI {
  static async updateContact(
    token: string,
    update: Partial<CustomerSettingsContact>
  ) {
    const mappedContact = mapContactToDb({ ...update });
    const response = await fetch(
      `${AppConfig.API_URL}/sales/contacts/${update.id}`,
      {
        method: "PATCH",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify(mappedContact),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async fetchBasicInfo(
    token: string,
    customerId: string
  ): Promise<BasicInfo> {
    const response = await fetch(
      `${AppConfig.API_URL}/customers-settings/basicinfo?customer_numbers=${customerId}`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    const responseList = (await response.json()) as BasicInfo[];
    return responseList[0];
  }

  static async fetchDetailedInfo(
    token: string,
    customerId: string,
    serviceLineId?: number
  ): Promise<DetailedInfo[]> {
    const serviceLineQuery = `&serviceline=${serviceLineId}`;
    const response = await fetch(
      `${
        AppConfig.API_URL
      }/customers-settings/customer-specific-info?customer_number=${customerId}${
        serviceLineId ? serviceLineQuery : ""
      }`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return (await response.json()) as DetailedInfo[];
  }

  static async createOrUpdateDetailedInfo(
    token: string,
    detailedInfo: DetailedInfo
  ): Promise<DetailedInfo> {
    const response = await fetch(
      `${AppConfig.API_URL}/customers-settings/customer-specific-info`,
      {
        method: "POST",
        body: JSON.stringify(detailedInfo),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return (await response.json()) as DetailedInfo;
  }

  static async fetchDetailedInfoCategories(
    token: string,
    customerId: string
  ): Promise<DetailedInfoCategory[]> {
    const response = await fetch(
      `${AppConfig.API_URL}/customers-settings/customerdetailscategory`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );
    const pom = (await response.json()) as DetailedInfoCategory[];
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return pom;
  }

  static async fetchDetailedInfoSubCategories(
    token: string,
    customerId: string
  ): Promise<DetailedInfoSubCategory[]> {
    const response = await fetch(
      `${AppConfig.API_URL}/customers-settings/customerdetailssubcategory`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return (await response.json()) as DetailedInfoSubCategory[];
  }

  static async updateBasicInfoSetting(
    token: string,
    setting: Partial<BasicInfo>
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers-settings/basicinfo`,
      {
        method: "POST",
        body: JSON.stringify(setting),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async fetchPowerOfAttorney(
    token: string,
    customerId: string,
    serviceLineId?: number
  ): Promise<PowerOfAttorney[]> {
    const serviceLineQuery = `&serviceline=${serviceLineId}`;
    const response = await fetch(
      `${
        AppConfig.API_URL
      }/customers-settings/powerofattorney?customer_number=${customerId}${
        serviceLineId ? serviceLineQuery : ""
      }`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return (await response.json()) as PowerOfAttorney[];
  }

  static async fetchPowerOfAttorneySources(
    token: string
  ): Promise<PowerOfAttorneySource[]> {
    const response = await fetch(
      `${AppConfig.API_URL}/customers-settings/powerofattorneysource`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return (await response.json()) as PowerOfAttorneySource[];
  }

  static async fetchPowerOfAttorneyTypes(
    token: string
  ): Promise<PowerOfAttorneyType[]> {
    const response = await fetch(
      `${AppConfig.API_URL}/customers-settings/powerofattorneytype`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return (await response.json()) as PowerOfAttorneyType[];
  }

  static async fetchPowerOfAttorneyStatuses(
    token: string
  ): Promise<PowerOfAttorneyStatus[]> {
    const response = await fetch(
      `${AppConfig.API_URL}/customers-settings/status?fields=all`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return (await response.json()) as PowerOfAttorneyStatus[];
  }

  static async createUpdatePowerOfAttorney(
    token: string,
    data: PowerOfAttorney
  ): Promise<PowerOfAttorney> {
    const response = await fetch(
      `${AppConfig.API_URL}/customers-settings/powerofattorney`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return (await response.json()) as PowerOfAttorney;
  }

  static async fetchServiceNotes(
    token: string,
    customerId: string,
    serviceLineId?: number
  ): Promise<ServiceNote[]> {
    const serviceLineQuery = `&serviceline=${serviceLineId}`;
    const response = await fetch(
      `${
        AppConfig.API_URL
      }/customers-settings/servicenote?customer_number=${customerId}${
        serviceLineId ? serviceLineQuery : ""
      }`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return (await response.json()) as ServiceNote[];
  }

  static async createOrUpdateServiceNotes(
    token: string,
    data: Partial<ServiceNoteDb>
  ): Promise<ServiceNoteDb> {
    const response = await fetch(
      `${AppConfig.API_URL}/customers-settings/servicenote`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return (await response.json()) as ServiceNoteDb;
  }

  static async fetchCustomerContacts(
    token: string,
    customerId: string
  ): Promise<CustomerSettingsContact[]> {
    const response = await fetch(
      `${AppConfig.API_URL}/customers_settings/${customerId}/contacts`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return (await response.json()) as CustomerSettingsContact[];
  }

  static async getServiceLines(token: string): Promise<ServiceLine[]> {
    const response = await fetch(
      `${AppConfig.API_URL}/customers-settings/serviceline`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return (await response.json()) as ServiceLine[];
  }
  static async fetchBalanceInvoicePayments(
    token: string,
    customerNumber: string
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerNumber}/balance_invoices_payments`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return await response.json();
  }

  static async fetchCustomerSettings(
    token: string,
    customerId: string,
    userGraphId: string
  ): Promise<CustomerSettingsServiceLine[]> {
    const response = await fetch(
      `${AppConfig.API_URL}/customers-settings/customer-settings/${customerId}?user=${userGraphId}`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return (await response.json()) as CustomerSettingsServiceLine[];
  }

  static async fetchPGU(
    token: string,
    customerNumber: string,
    queryParams?: string
  ) {
    const fetchUrl = queryParams
      ? `${AppConfig.API_URL}/customers/${customerNumber}/pgu?${queryParams}`
      : `${AppConfig.API_URL}/customers/${customerNumber}/pgu`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const data = await response.json();
    return data;
  }

  static async toggleCustomerSetting(
    token: string,
    setting: UpdateSettingsData
  ): Promise<CustomerSettingsServiceLine[]> {
    const response = await fetch(
      `${AppConfig.API_URL}/customers-settings/customer-settings`,
      {
        method: "POST",
        body: JSON.stringify(setting),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return (await response.json()) as CustomerSettingsServiceLine[];
  }

  static async fetchDownloadUrlPgu(
    token: string,
    customerNumber: string,
    queryParamsString?: string
  ) {
    const fetchUrl =
      queryParamsString !== ""
        ? `${AppConfig.API_URL}/customers/${customerNumber}/pgu/excel?${queryParamsString}`
        : `${AppConfig.API_URL}/customers/${customerNumber}/pgu/excel`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const data = await response.json();
    return data;
  }

  static async fetchDownloadUrlBalances(token: string, customerNumber: string) {
    const fetchUrl = `${AppConfig.API_URL}/customers/${customerNumber}/balance_invoices_payments/excel`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const data = await response.json();
    return data;
  }
}
