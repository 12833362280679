import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ContextualMenu from "components/contextualMenu/ContextualMenu";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { ACCOUNTING_SERVICE_LINE } from "constants/servicesConsts";
import { useTranslation } from "hooks/use-translate";
import { DealCustomer } from "models/offer/Customer";
import { CustomerDealSlim } from "models/offer/CustomerDealSlim";
import { SigningMethod } from "models/offer/DealContact";
import {
  ServiceCategory,
  getServiceCategoryFromEconomyServiceArea,
} from "models/offer/ServiceLine";
import { RootState } from "state";
import { appendError } from "state/notifications";
import { setSelectedCustomerSource } from "state/offer/offersSlice";
import { copyDeal, fetchDeal, updateOffer } from "state/offer/offersThunks";
import { AppDispatch } from "state/use-app-redux";
import { OfferRouteHelper } from "views/createNew/offer/routes/offerRoutes";
import CustomerSourceModal from "views/createNew/offer/wizard/CustomerSource/CustomerSourceModal";
import { ServicesContext } from "views/createNew/offer/wizard/Services/ServicesContextProvider";
import { SERVICE_GROUP_CURRENT_ACCOUNTING } from "views/createNew/offer/wizard/consts/offer-contst";

interface ActionsProps {
  deal: CustomerDealSlim;
  customer?: DealCustomer;
  sourceOptions: {
    value: string;
    text: string;
  }[];
  setDeleteOffer?: (shouldDelete: boolean) => void;
}

export function ActionsDropdown({
  deal,
  customer,
  sourceOptions,
  setDeleteOffer,
}: ActionsProps) {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const { status } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );

  const { updateServiceGroups, setTaxObjectsInContext } =
    useContext(ServicesContext);

  const [isSourceModalOpen, setIsSourceModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { service_areas: serviceAreasFromDeal } = deal;

  /**
   * Checks if the deal being loaded has a Current accounting
   * service with a price of 0. If true, it fetches the necessary
   * data and then navigates to the Current accounting screen;
   * otherwise, navigates to the Summary.
   */
  async function loadCurrentDeal() {
    const currentAccountingInDeal =
      getServiceCategoryFromEconomyServiceArea(
        ACCOUNTING_SERVICE_LINE,
        SERVICE_GROUP_CURRENT_ACCOUNTING,
        SERVICE_GROUP_CURRENT_ACCOUNTING,
        serviceAreasFromDeal ?? []
      ) ?? new ServiceCategory();

    if (!customer || !deal.id) {
      dispatch(
        appendError("OFFER_LINK_COPY_FAILED", {
          message: "Offer id or customer number missing",
        } as Error)
      );
      return;
    }

    const editedDeal = await dispatch(
      fetchDeal({ orgId: customer.org_number, dealId: deal.id })
    )
      .unwrap()
      .catch((e) => {
        dispatch(
          appendError("FAILED_TO_FETCH_DEAL", {
            cause: e,
          } as unknown as Error)
        );
      });

    if (editedDeal) {
      editedDeal.service_areas.forEach((serviceArea) => {
        serviceArea.service_lines.forEach((serviceLine) => {
          serviceLine.service_groups.forEach((serviceGroup) => {
            updateServiceGroups(serviceGroup);
            const serviceGroupName = serviceGroup.name;
            const taxObjects = editedDeal.tax_objects;
            setTaxObjectsInContext(serviceGroupName, taxObjects);
          });
        });
      });
    }

    if (currentAccountingInDeal.final_price === 0) {
      navigate(OfferRouteHelper.getServicesCurrentAccounting());
      return;
    }

    navigate(
      `${OfferRouteHelper.getSummary()}/${customer?.org_number}/offer/${
        deal.id
      }`
    );
  }

  const actionOptions = [
    {
      text: translate("DUPLICATE"),
      onClick: () => executeAction("copy"),
    },
  ];

  if (deal.state === "offer") {
    actionOptions.unshift({
      text: translate("DELETE"),
      onClick: () => executeAction("delete"),
    });

    actionOptions.unshift({
      text: translate("EDIT"),
      onClick: () => executeAction("edit"),
    });
  } else if (deal.state === "contract") {
    const isSigningMethodPhysical = deal.contacts.some(
      (contact) => contact.signing_method === SigningMethod.PHYSICAL
    );

    if (isSigningMethodPhysical) {
      actionOptions.unshift({
        text: translate("GOTO_PHYSICAL_METHOD_COMPLETION"),
        onClick: () => executeAction("completion"),
      });
    }
  }

  const copyExistingDeal = async () => {
    if (deal.id) {
      const copiedDeal = await dispatch(copyDeal(deal.id))
        .unwrap()
        .catch((e) => {
          dispatch(
            appendError("FAILED_TO_COPY_DEAL", {
              cause: e,
            } as unknown as Error)
          );
        });

      if (copiedDeal) {
        dispatch(setSelectedCustomerSource(copiedDeal.deal_source as string));

        copiedDeal.service_areas.forEach((serviceArea) => {
          serviceArea.service_lines.forEach((serviceLine) => {
            serviceLine.service_groups.forEach((serviceGroup) => {
              updateServiceGroups(serviceGroup);
              const serviceGroupName = serviceGroup.name;
              const taxObjects = copiedDeal.tax_objects;
              setTaxObjectsInContext(serviceGroupName, taxObjects);
            });
          });
        });

        navigate(OfferRouteHelper.getSummary());
      }
    }
  };

  async function executeAction(
    action: "edit" | "copy" | "completion" | "delete"
  ) {
    if (action === "edit") {
      loadCurrentDeal();
    }

    if (action === "copy") {
      if (!deal.deal_source || deal.deal_source === "") {
        setIsSourceModalOpen(true);
      } else {
        await copyExistingDeal();
      }
    }

    if (action === "completion") {
      navigate(
        OfferRouteHelper.getExistingManualSignKyc(customer?.org_number, deal.id)
      );
    }

    if (action === "delete") {
      setDeleteOffer?.(true);
    }
  }

  const handleModalConfirm = async (dealSource: string) => {
    const orgNumber = customer?.org_number;
    const dealId = deal.id;
    if (orgNumber && dealId) {
      setIsLoading(true);
      const payload = { deal_source: dealSource };
      const response = await dispatch(
        updateOffer({ orgNumber, dealId, payload })
      );
      setIsLoading(false);
      setIsSourceModalOpen(false);
      if (response.meta.requestStatus === "fulfilled") {
        await copyExistingDeal();
      }
    }
  };

  return (
    <>
      <ContextualMenu
        menuItems={actionOptions}
        buttonDisabled={status === LoadingStatusEnum.PENDING}
      />
      {isSourceModalOpen && (
        <CustomerSourceModal
          sourceOptions={sourceOptions}
          handleCancel={() => setIsSourceModalOpen(false)}
          labelCancel={translate("CANCEL")}
          handleConfirm={handleModalConfirm}
          isLoading={isLoading}
        />
      )}
    </>
  );
}
