import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Customer } from "models/customer";
import { BasicInfo } from "models/customerSettings/customerSettingsBasicInfo";
import { CustomerSettingsContact } from "models/customerSettings/customerSettingsContact";
import { DetailedInfoData } from "models/customerSettings/customerSettingsDetailedInfoData";
import { PowerOfAttorneyStatus } from "models/customerSettings/customerSettingsPoweOfAttorneyStatus";
import { PowerOfAttorney } from "models/customerSettings/customerSettingsPowerofAttorney";
import { PowerOfAttorneySource } from "models/customerSettings/customerSettingsPowerOfAttorneySource";
import { PowerOfAttorneyType } from "models/customerSettings/customerSettingsPowerOfAttorneyType";
import { ServiceLine } from "models/customerSettings/customerSettingsServiceLine";
import { ServiceNote } from "models/customerSettings/customerSettingsServiceNote";
import { TaxObjectDetails } from "models/offer/TaxObject";
import { TaxObjectService } from "models/offer/TaxObjectService";
import { CustomerSettingsServiceLine } from "models/customerSettings/customerSettingsSettingsServiceLine";
import { ExternalKYCdata } from "models/customerSettings/customerSettingsExternalKYC";
import { FormAnswerBatch } from "models/activities/activity";
import { LoadingStatus } from "..";
import {
  createCustomerContactCaller,
  createDetailedInfoCaller,
  updateDetailedInfoCaller,
  createPowerOfAttorneyCaller,
  createServiceNotesCaller,
  updateServiceNotesCaller,
  fetchBasicInfoSettingsCaller,
  fetchCustomerCaller,
  fetchCustomerContactsCaller,
  fetchCustomerSettingsCaller,
  toggleCustomerSettingCaller,
  fetchDetailedInfoCaller,
  fetchPowerOfAttorneyCaller,
  fetchPowerOfAttorneySourcesCaller,
  fetchPowerOfAttorneyStatusesCallerr,
  fetchPowerOfAttorneyTypesCaller,
  fetchServiceLinesCaller,
  fetchServiceNotesCaller,
  // fetchTaxObjectsCaller,
  updateBasicInfoSettingCaller,
  updateCustomerContactCaller,
  updatePowerOfAttorneyCaller,
  fetchKYCFormAnswersBatchCaller,
  fetchExternalKYCCaller,
  deleteCustomerContactCaller,
} from "./customerSettingsThunk";

export interface CustomerSettingsState {
  isLoading: boolean;
  customer: {
    status: LoadingStatus;
    data: Partial<Customer>;
  };
  detailedInfo: {
    status: LoadingStatus;
    data: DetailedInfoData;
  };
  serviceLines: {
    status: LoadingStatus;
    data: ServiceLine[];
    currentServiceLine: ServiceLine | undefined;
  };
  basicInfo: {
    status: LoadingStatus;
    data: BasicInfo;
  };
  contacts: {
    status: LoadingStatus;
    data: CustomerSettingsContact[];
  };
  serviceNotes: {
    status: LoadingStatus;
    data: ServiceNote[];
  };
  powerOfAttorney: {
    status: LoadingStatus;
    data: PowerOfAttorney[];
    sources: PowerOfAttorneySource[];
    types: PowerOfAttorneyType[];
    statuses: PowerOfAttorneyStatus[];
  };
  taxObjects: {
    status: LoadingStatus;
    customerTaxObjects: TaxObjectDetails[];
    taxServiceObjects: TaxObjectService[];
  };
  customerSettings: {
    data: CustomerSettingsServiceLine[];
    status: LoadingStatus;
  };
  formKYCAnswersBatch: {
    status: LoadingStatus;
    data: FormAnswerBatch[];
  };
  externalKYC: {
    status: LoadingStatus;
    data: ExternalKYCdata[];
  };
  expandAll: boolean | undefined;
  openCreateModalFor: string | undefined;
  searchTerm: string | undefined;
}

const initialState: CustomerSettingsState = {
  isLoading: false,
  customer: {
    status: "idle",
    data: {},
  },
  detailedInfo: {
    status: "idle",
    data: {
      info: [],
      categories: [],
      customerdetailssubcategory: [],
    },
  },
  serviceLines: {
    status: "idle",
    data: [],
    currentServiceLine: undefined,
  },
  basicInfo: {
    status: "idle",
    data: {} as BasicInfo,
  },
  contacts: {
    status: "idle",
    data: [],
  },
  serviceNotes: {
    status: "idle",
    data: [],
  },
  powerOfAttorney: {
    status: "idle",
    data: [],
    sources: [],
    types: [],
    statuses: [],
  },
  taxObjects: {
    status: "idle",
    customerTaxObjects: [],
    taxServiceObjects: [],
  },
  customerSettings: {
    status: "idle",
    data: [],
  },
  formKYCAnswersBatch: {
    data: [],
    status: "idle",
  },
  externalKYC: {
    status: "idle",
    data: [],
  },
  expandAll: undefined,
  openCreateModalFor: undefined,
  searchTerm: undefined,
};

export const settingsSlice = createSlice({
  name: "customerSettings",
  initialState,
  reducers: {
    setCustomerContacts: (
      state,
      action: PayloadAction<CustomerSettingsContact[]>
    ) => {
      const customerContacts = action.payload;
      state.contacts.data = customerContacts;
      state.contacts.status = "idle";
    },
    setCustomerContactsStatus: (
      state,
      action: PayloadAction<LoadingStatus>
    ) => {
      const status = action.payload;
      state.contacts.status = status;
    },
    setCurrentServiceLine: (
      state,
      action: PayloadAction<ServiceLine | undefined>
    ) => {
      state.serviceLines.currentServiceLine = action.payload;
    },
    resetCurrentServiceLine: (state) => {
      state.serviceLines.currentServiceLine = undefined;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    resetCustomerSettingsState: (state) => {
      state.customer.data = {};
      state.contacts.data = [];
      state.powerOfAttorney.data = [];
      state.serviceNotes.data = [];
      state.externalKYC.data = [];
      state.detailedInfo.data = {
        info: [],
        categories: [],
        customerdetailssubcategory: [],
      };
    },
    toggleExpandAll: (state) => {
      state.expandAll = !state.expandAll;
    },
    resetExpandAll: (state) => {
      state.expandAll = undefined;
    },
    setOpenCreateModalFor: (state, action: PayloadAction<string>) => {
      state.openCreateModalFor = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    resetOpenCreateModalFor: (state) => {
      state.openCreateModalFor = undefined;
    },
    resetSearchTerm: (state) => {
      state.searchTerm = undefined;
    },
    resetCustomerSettingsData: (state) => {
      state.customerSettings.data = [];
    },
  },
  extraReducers(builder) {
    // CUSTOMER
    // fetch
    builder.addCase(fetchCustomerCaller.pending, (state, { payload }) => {
      state.customer.status = "pending";
      state.isLoading = true;
    });
    builder.addCase(fetchCustomerCaller.fulfilled, (state, { payload }) => {
      state.customer.status = "succeeded";
      state.customer.data = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchCustomerCaller.rejected, (state, { payload }) => {
      state.customer.status = "failed";
      state.isLoading = false;
    });

    // CONTACTS
    // fetch
    builder.addCase(fetchCustomerContactsCaller.pending, (state) => {
      state.contacts.data = [];
      state.isLoading = true;
      state.contacts.status = "pending";
    });
    builder.addCase(
      fetchCustomerContactsCaller.fulfilled,
      (state, { payload }) => {
        state.contacts.status = "succeeded";
        state.contacts.data = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(fetchCustomerContactsCaller.rejected, (state) => {
      state.isLoading = false;
      state.contacts.status = "failed";
    });
    // update
    builder.addCase(updateCustomerContactCaller.pending, (state) => {
      state.isLoading = true;
      state.contacts.status = "pending";
    });
    builder.addCase(
      updateCustomerContactCaller.fulfilled,
      (state, { payload }) => {
        state.contacts.status = "succeeded";
        const partialContact = payload;
        if (partialContact.id) {
          const existentContact = state.contacts.data.find(
            (contact) => contact.id === partialContact.id
          );
          const updatedContact = {
            ...existentContact,
            ...partialContact,
          } as CustomerSettingsContact;

          const filteredContacts = state.contacts.data.filter(
            (contact) => contact.id !== partialContact.id
          );
          const updatedContactIndex = state.contacts.data.findIndex(
            (e) => e.id === partialContact.id
          );
          filteredContacts.splice(updatedContactIndex, 0, updatedContact);
          filteredContacts.join();
          state.contacts.data = [...filteredContacts];
        }
        state.isLoading = false;
      }
    );
    builder.addCase(updateCustomerContactCaller.rejected, (state) => {
      state.isLoading = false;
      state.contacts.status = "failed";
    });
    // create
    builder.addCase(createCustomerContactCaller.pending, (state) => {
      state.contacts.status = "pending";
      state.isLoading = true;
    });
    builder.addCase(
      createCustomerContactCaller.fulfilled,
      (state, { payload }) => {
        state.contacts.status = "succeeded";
        state.contacts.data = [...state.contacts.data, payload];
        state.isLoading = false;
      }
    );
    builder.addCase(createCustomerContactCaller.rejected, (state) => {
      state.isLoading = false;
      state.contacts.status = "failed";
    });
    // delete
    builder.addCase(deleteCustomerContactCaller.pending, (state) => {
      state.contacts.status = "pending";
      state.isLoading = true;
    });
    builder.addCase(
      deleteCustomerContactCaller.fulfilled,
      (state, { payload }) => {
        const contacts = [...state.contacts.data];
        const filteredContacts = contacts.filter(
          (contact) => contact.email !== payload.contactEmail
        );
        state.contacts.status = "succeeded";
        state.isLoading = false;
        state.contacts.data = filteredContacts;
      }
    );
    builder.addCase(deleteCustomerContactCaller.rejected, (state) => {
      state.isLoading = false;
      state.contacts.status = "failed";
    });

    // DETAILED INFO
    // fetch
    builder.addCase(fetchDetailedInfoCaller.pending, (state) => {
      state.isLoading = true;
      state.detailedInfo.status = "pending";
    });
    builder.addCase(fetchDetailedInfoCaller.fulfilled, (state, { payload }) => {
      state.detailedInfo.status = "succeeded";
      state.detailedInfo.data = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchDetailedInfoCaller.rejected, (state) => {
      state.isLoading = false;
      state.detailedInfo.status = "failed";
    });
    // create
    builder.addCase(createDetailedInfoCaller.pending, (state) => {
      state.isLoading = true;
      state.detailedInfo.status = "pending";
    });
    builder.addCase(
      createDetailedInfoCaller.fulfilled,
      (state, { payload }) => {
        state.detailedInfo.status = "succeeded";
        state.detailedInfo.data.info = [
          ...state.detailedInfo.data.info,
          payload,
        ];
        state.detailedInfo.data = {
          ...{ info: [payload, state.detailedInfo.data.info] },
          ...state.detailedInfo.data,
        };
        state.isLoading = false;
      }
    );
    builder.addCase(createDetailedInfoCaller.rejected, (state) => {
      state.detailedInfo.status = "failed";
      state.isLoading = false;
    });
    // update
    builder.addCase(updateDetailedInfoCaller.pending, (state) => {
      state.isLoading = true;
      state.detailedInfo.status = "pending";
    });
    builder.addCase(
      updateDetailedInfoCaller.fulfilled,
      (state, { payload }) => {
        state.detailedInfo.status = "succeeded";
        const filteredDetailedInfo = state.detailedInfo.data.info.filter(
          (info) => info.id !== payload.id
        );
        if (payload.enabled) {
          state.detailedInfo.data.info = [...filteredDetailedInfo, payload];
        } else {
          state.detailedInfo.data.info = [...filteredDetailedInfo];
        }
        state.isLoading = false;
      }
    );
    builder.addCase(updateDetailedInfoCaller.rejected, (state) => {
      state.detailedInfo.status = "failed";
      state.isLoading = false;
    });

    // SERVICE NOTES
    // fetch
    builder.addCase(fetchServiceNotesCaller.pending, (state) => {
      state.isLoading = true;
      state.serviceNotes.status = "pending";
    });
    builder.addCase(fetchServiceNotesCaller.fulfilled, (state, { payload }) => {
      state.serviceNotes.status = "succeeded";
      // hide disabled notes
      state.serviceNotes.data = payload.filter(
        (serviceNote) => serviceNote.enabled
      );
      state.isLoading = false;
    });
    builder.addCase(fetchServiceNotesCaller.rejected, (state) => {
      state.serviceNotes.status = "failed";
      state.isLoading = false;
    });
    // create
    builder.addCase(createServiceNotesCaller.pending, (state) => {
      state.isLoading = true;
      state.serviceNotes.status = "pending";
    });
    builder.addCase(
      createServiceNotesCaller.fulfilled,
      (state, { payload }) => {
        state.serviceNotes.status = "succeeded";
        state.serviceNotes.data = [...state.serviceNotes.data, payload];
        state.isLoading = false;
      }
    );
    builder.addCase(createServiceNotesCaller.rejected, (state) => {
      state.serviceNotes.status = "failed";
      state.isLoading = false;
    });
    // update
    builder.addCase(updateServiceNotesCaller.pending, (state) => {
      state.isLoading = true;
      state.serviceNotes.status = "pending";
    });
    builder.addCase(
      updateServiceNotesCaller.fulfilled,
      (state, { payload }) => {
        state.serviceNotes.status = "succeeded";
        const filteredServiceNotes = state.serviceNotes.data.filter(
          (serviceNote) => serviceNote.id !== payload.id
        );
        if (payload.enabled) {
          state.serviceNotes.data = [...filteredServiceNotes, payload];
        } else {
          state.serviceNotes.data = [...filteredServiceNotes];
        }
        state.isLoading = false;
      }
    );
    builder.addCase(updateServiceNotesCaller.rejected, (state) => {
      state.serviceNotes.status = "failed";
      state.isLoading = false;
    });

    // KYC FORMS
    builder.addCase(fetchKYCFormAnswersBatchCaller.pending, (state) => {
      state.isLoading = true;
      state.formKYCAnswersBatch.status = "pending";
    });
    builder.addCase(
      fetchKYCFormAnswersBatchCaller.fulfilled,
      (state, { payload }) => {
        state.formKYCAnswersBatch.data = payload
          .map((batch) => ({
            ...batch,
            answers: batch.answers.sort((a, b) => a.sort_order - b.sort_order),
          }))
          .sort(
            (a, b) => parseInt(b.fiscal_year, 10) - parseInt(a.fiscal_year, 10)
          );
        state.formKYCAnswersBatch.status = "succeeded";
        state.isLoading = false;
      }
    );
    builder.addCase(fetchKYCFormAnswersBatchCaller.rejected, (state) => {
      state.formKYCAnswersBatch.status = "failed";
      state.isLoading = false;
    });

    // EXTERNAL KYC
    builder.addCase(fetchExternalKYCCaller.pending, (state) => {
      state.isLoading = true;
      state.externalKYC.status = "pending";
    });
    builder.addCase(fetchExternalKYCCaller.fulfilled, (state, { payload }) => {
      state.externalKYC.data = payload.sort(
        (a, b) =>
          new Date(b.created_date).getDate() -
          new Date(a.created_date).getDate()
      );
      state.externalKYC.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchExternalKYCCaller.rejected, (state) => {
      state.externalKYC.status = "failed";
      state.isLoading = false;
    });

    // POWER OF ATTORNEY
    // fetch
    builder.addCase(fetchPowerOfAttorneyCaller.pending, (state) => {
      state.powerOfAttorney.data = [];
      state.isLoading = true;
      state.powerOfAttorney.status = "pending";
    });
    builder.addCase(
      fetchPowerOfAttorneyCaller.fulfilled,
      (state, { payload }) => {
        state.powerOfAttorney.status = "succeeded";
        state.powerOfAttorney.data = payload.powerOfAttorney;
        state.isLoading = false;
      }
    );
    builder.addCase(fetchPowerOfAttorneyCaller.rejected, (state) => {
      state.powerOfAttorney.status = "failed";
      state.isLoading = false;
    });
    // POWER OF ATTORNEY
    // sources fetch
    builder.addCase(fetchPowerOfAttorneySourcesCaller.pending, (state) => {
      state.powerOfAttorney.sources = [];
      state.isLoading = true;
      state.powerOfAttorney.status = "pending";
    });
    builder.addCase(
      fetchPowerOfAttorneySourcesCaller.fulfilled,
      (state, { payload }) => {
        state.powerOfAttorney.status = "succeeded";
        state.powerOfAttorney.sources = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(fetchPowerOfAttorneySourcesCaller.rejected, (state) => {
      state.powerOfAttorney.status = "failed";
      state.isLoading = false;
    });
    // POWER OF ATTORNEY
    // types fetch
    builder.addCase(fetchPowerOfAttorneyTypesCaller.pending, (state) => {
      state.powerOfAttorney.types = [];
      state.isLoading = true;
      state.powerOfAttorney.status = "pending";
    });
    builder.addCase(
      fetchPowerOfAttorneyTypesCaller.fulfilled,
      (state, { payload }) => {
        state.powerOfAttorney.status = "succeeded";
        state.powerOfAttorney.types = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(fetchPowerOfAttorneyTypesCaller.rejected, (state) => {
      state.powerOfAttorney.status = "failed";
      state.isLoading = false;
    });

    // POWER OF ATTORNEY
    // statuses fetch
    builder.addCase(fetchPowerOfAttorneyStatusesCallerr.pending, (state) => {
      state.powerOfAttorney.statuses = [];
      state.isLoading = true;
      state.powerOfAttorney.status = "pending";
    });
    builder.addCase(
      fetchPowerOfAttorneyStatusesCallerr.fulfilled,
      (state, { payload }) => {
        state.powerOfAttorney.statuses = payload;
        state.isLoading = true;
        state.powerOfAttorney.status = "succeeded";
      }
    );
    builder.addCase(fetchPowerOfAttorneyStatusesCallerr.rejected, (state) => {
      state.powerOfAttorney.status = "failed";
      state.isLoading = false;
    });
    // create
    builder.addCase(createPowerOfAttorneyCaller.pending, (state) => {
      state.isLoading = true;
      state.powerOfAttorney.status = "pending";
    });
    builder.addCase(
      createPowerOfAttorneyCaller.fulfilled,
      (state, { payload }) => {
        state.powerOfAttorney.status = "succeeded";
        state.powerOfAttorney.data = [...state.powerOfAttorney.data, payload];
        state.isLoading = false;
      }
    );
    builder.addCase(createPowerOfAttorneyCaller.rejected, (state) => {
      state.powerOfAttorney.status = "failed";
      state.isLoading = false;
    });

    // update
    builder.addCase(updatePowerOfAttorneyCaller.pending, (state) => {
      state.isLoading = true;
      state.powerOfAttorney.status = "pending";
    });
    builder.addCase(
      updatePowerOfAttorneyCaller.fulfilled,
      (state, { payload }) => {
        state.powerOfAttorney.status = "succeeded";
        state.isLoading = false;
      }
    );
    builder.addCase(updatePowerOfAttorneyCaller.rejected, (state) => {
      state.powerOfAttorney.status = "failed";
      state.isLoading = false;
    });
    // BASIC INFO
    // fetch
    builder.addCase(fetchBasicInfoSettingsCaller.pending, (state) => {
      state.isLoading = true;
      state.basicInfo.status = "pending";
    });
    builder.addCase(
      fetchBasicInfoSettingsCaller.fulfilled,
      (state, { payload }) => {
        state.basicInfo.status = "succeeded";
        state.basicInfo.data = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(fetchBasicInfoSettingsCaller.rejected, (state) => {
      state.isLoading = false;
      state.basicInfo.status = "failed";
    });
    // updateBasicInfoSettingCaller
    builder.addCase(updateBasicInfoSettingCaller.pending, (state) => {
      state.isLoading = true;
      state.basicInfo.status = "pending";
    });
    builder.addCase(
      updateBasicInfoSettingCaller.fulfilled,
      (state, { payload }) => {
        const updatedSetting = payload;
        state.basicInfo.data = { ...state.basicInfo.data, ...updatedSetting };
        state.basicInfo.status = "succeeded";
        state.isLoading = false;
      }
    );
    builder.addCase(updateBasicInfoSettingCaller.rejected, (state) => {
      state.basicInfo.status = "failed";
      state.isLoading = false;
    });
    // SERVICELINE
    // get
    builder.addCase(fetchServiceLinesCaller.pending, (state) => {
      state.isLoading = true;
      state.serviceLines.status = "pending";
    });
    builder.addCase(fetchServiceLinesCaller.fulfilled, (state, { payload }) => {
      state.serviceLines.data = payload.sort((a, b) => b.id - a.id);
      state.serviceLines.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchServiceLinesCaller.rejected, (state) => {
      state.isLoading = false;
      state.serviceLines.status = "failed";
    });

    // CUSTOMER SETTINGS
    // get
    builder.addCase(fetchCustomerSettingsCaller.pending, (state) => {
      state.isLoading = true;
      state.customerSettings.status = "pending";
    });
    builder.addCase(
      fetchCustomerSettingsCaller.fulfilled,
      (state, { payload }) => {
        state.customerSettings.data = payload;
        state.customerSettings.status = "succeeded";
        state.isLoading = false;
      }
    );
    builder.addCase(fetchCustomerSettingsCaller.rejected, (state) => {
      state.customerSettings.status = "failed";
      state.isLoading = false;
    });
    // update
    builder.addCase(toggleCustomerSettingCaller.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      toggleCustomerSettingCaller.fulfilled,
      (state, { payload }) => {
        state.customerSettings.data = payload;
        state.customerSettings.status = "succeeded";
        state.isLoading = false;
      }
    );
    builder.addCase(toggleCustomerSettingCaller.rejected, (state) => {
      state.customerSettings.status = "failed";
      state.isLoading = false;
    });
  },
});

export const {
  setCustomerContacts,
  setCustomerContactsStatus,
  setCurrentServiceLine,
  resetCurrentServiceLine,
  setIsLoading,
  resetCustomerSettingsState,
  toggleExpandAll,
  resetExpandAll,
  setOpenCreateModalFor,
  setSearchTerm,
  resetOpenCreateModalFor,
  resetSearchTerm,
  resetCustomerSettingsData,
} = settingsSlice.actions;

export default settingsSlice.reducer;
