import {
  TOGGLE_DATE,
  TOGGLE_INT,
  TOGGLE_MULTI_SELECT,
  TOGGLE_SINGLE_SELECT,
  TOGGLE_SINGLE_SELECT_LOOKUP,
  TOGGLE_STRING,
} from "constants/offer/input-form-types";
import { isValidRegex } from "libs/is-valid-regex";
import { Service } from "models/offer/ServiceLine";

export default function useValidateSmallRecurringCustomersServices() {
  const validateNumericInput = (value: string, service: Service) => {
    if (!service.input_form_type_content || !value) {
      return true;
    }

    const splitContent = service.input_form_type_content.split(":");

    if (splitContent.length !== 2) {
      throw new Error(
        "'input_form_type_content' has an unexpected format coming from the Backend"
      );
    }

    const min = splitContent[0];
    const max = splitContent[1];

    let isMinValid = true;
    let isMaxValid = true;

    if (min) {
      const minParsed = parseInt(min, 10);

      if (Number.isNaN(minParsed)) {
        throw new Error(
          "'input_form_type_content' has an unexpected min value coming from the Backend"
        );
      }

      isMinValid = parseInt(value, 10) >= minParsed;
    }

    if (max) {
      const maxParsed = parseInt(max, 10);

      if (Number.isNaN(maxParsed)) {
        throw new Error(
          "'input_form_type_content' has an unexpected max value coming from the Backend"
        );
      }

      isMaxValid = parseInt(value, 10) <= maxParsed;
    }

    return isMinValid && isMaxValid;
  };

  const validateTextInput = (value: string, service: Service) => {
    if (!service.input_form_type_content || !value) {
      return true;
    }

    const regex = isValidRegex(service.input_form_type_content);
    if (!regex) {
      throw new Error(
        "'input_form_type_content' has an unexpected RegExp format coming from the Backend"
      );
    }

    return regex.test(value);
  };

  const validateServices = (services: Service[]) => {
    const servicesValidity = services.map((service) => {
      const isFrequencyValid = service.is_pog_service_frequency
        ? !!service.pog_service_frequency
        : true;

      let isValueValid = true;

      switch (service.input_form_type) {
        case TOGGLE_SINGLE_SELECT:
        case TOGGLE_MULTI_SELECT:
        case TOGGLE_SINGLE_SELECT_LOOKUP:
        case TOGGLE_DATE:
          isValueValid = !!service.input_form_type_value;
          break;

        case TOGGLE_INT:
          if (!service.input_form_type_value) {
            isValueValid = false;
          } else {
            isValueValid = validateNumericInput(
              service.input_form_type_value,
              service
            );
          }
          break;

        case TOGGLE_STRING:
          if (!service.input_form_type_value) {
            isValueValid = false;
          } else {
            isValueValid = validateTextInput(
              service.input_form_type_value,
              service
            );
          }
          break;

        default:
      }

      return {
        serviceMatrixId: service.service_matrix_id,
        serviceHeader: service.header,
        isValueValid,
        isFrequencyValid,
      };
    });

    return servicesValidity;
  };

  return {
    validateNumericInput,
    validateTextInput,
    validateServices,
  };
}
