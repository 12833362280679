import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { useTranslation } from "hooks";

type Props = {
  isOpen: boolean;
  onDismiss: () => void;
  onSave: () => void;
  onCancel: () => void;
  isLoading: boolean;
};

export default function ReviewChangesOnSaveModal({
  isOpen,
  onDismiss,
  onSave,
  onCancel,
  isLoading,
}: Props) {
  const { translate } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      size="medium"
      footer={
        <ModalFooter
          labelSubmit="CONFIRM"
          labelCancel="CANCEL"
          onSave={onSave}
          onCancel={onCancel}
          isLoading={isLoading}
        />
      }
      header={<ModalHeader headerTitleText="WARNING" />}
    >
      <p className="fpx-16">
        {translate("SMALL_RECURRING.CONFIRMATION_MODAL")}
      </p>
    </Modal>
  );
}
