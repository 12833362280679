import {
  ChevronDoubleDownFilled,
  ChevronDoubleUpFilled,
  ReOrderFilled,
} from "@fluentui/react-icons";

import { Priority } from "models/deals/deal";

type PriorityIconProps = {
  priority?: Priority;
  onClick?: () => void;
  size?: string;
};

export function PriorityIcon({
  priority,
  onClick,
  size = "fem-4",
}: PriorityIconProps) {
  const getColorClassName = () => {
    switch (priority) {
      case "low": {
        return "text-color-green-light";
      }
      case "medium": {
        return "text-color-yellow";
      }
      case "high": {
        return "text-color-red";
      }
      default:
        return "";
    }
  };
  const className = `blue-link ${getColorClassName()} ${size}`;
  switch (priority) {
    case "low": {
      return (
        <ChevronDoubleDownFilled
          className={className}
          onClick={onClick && onClick}
        />
      );
    }
    case "medium": {
      return <ReOrderFilled className={className} />;
    }
    case "high": {
      return (
        <ChevronDoubleUpFilled
          className={className}
          onClick={onClick && onClick}
        />
      );
    }
    default:
      return <div className="my-lg" />;
  }
}
