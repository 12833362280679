import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "state";
import { useAppDispatch } from "state/use-app-redux";
import {
  resetPriceAdjustmentAndDiscount,
  updateCurrentOffer,
} from "state/offer/offersSlice";
import { sendOfferForCalculation } from "state/offer/offersThunks";
import {
  Service,
  ServiceArea,
  ServiceCategory,
  ServiceGroupItem,
} from "models/offer/ServiceLine";
import { ServiceValidity } from "models/offer/ServiceValidity";
import { TaxObjectService } from "models/offer/TaxObjectService";
import useSaveTaxObjectsData from "hooks/createNew/offer/use-save-tax-objects-data";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import ServiceFormWithTaxObjects from "views/createNew/offer/components/serviceFormWithTaxObjects/serviceFormWithTaxObjects";
import ReviewChangesOnSaveModal from "views/createNew/offer/wizard/CurrentAccountingSmallRecurring/components/ReviewChangesOnSaveModal";
import { CurrentAccountingForm } from "views/createNew/offer/wizard/CurrentAccountingWrapper/CurrentAccountingWrapper";
import CurrentAccountingServiceDetailsForm from "views/createNew/offer/wizard/CurrentAccountingWrapper/components/CurrentAccountingServiceDetailsForm";
import {
  SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY,
  SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY_EF,
  SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY,
  SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY_EF,
} from "views/createNew/offer/wizard/consts/offer-contst";
import {
  ServicesContext,
  TaxObjects,
} from "views/createNew/offer/wizard/Services/ServicesContextProvider";
import { useRoutingForOffer } from "views/createNew/offer/components/wizardSection/useRoutingForOffer";
import { OfferRouteHelper } from "views/createNew/offer/routes/offerRoutes";

type Props = {
  pendingChangesModalOpen: boolean;
  setPendingChangesModalOpen: (pendingChangesModalOpen: boolean) => void;
  currentAccountingServices: Service[];
  currentAccountingData: CurrentAccountingForm;
  showTaxObjects: boolean;
  setShowTaxObjects: (pendingChangesModalOpen: boolean) => void;
  groupedServices: ServiceGroupItem[];
  servicesValidity: ServiceValidity[];
  selectedHeader: string;
  setSelectedHeader: (selectedHeader: string) => void;
  getServiceLineWithCurrentAccountingData: (data?: CurrentAccountingForm) => {
    service_areas: ServiceArea[];
  };
  serviceGroup: string;
  setIsDirty: (value: React.SetStateAction<boolean>) => void;
  currentAccountingTemplate: ServiceCategory;
  currentAccountingInCurrentOffer: ServiceCategory;
  setCurrentAccountingServices: React.Dispatch<React.SetStateAction<Service[]>>;
  modalOpen: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  updateServiceCategoryData: (
    formHeaderData?: Partial<CurrentAccountingForm>
  ) => void;
};

export default function CurrentAccountingSmallRecurringServiceDetails({
  pendingChangesModalOpen,
  setPendingChangesModalOpen,
  currentAccountingServices,
  currentAccountingData,
  showTaxObjects,
  setShowTaxObjects,
  groupedServices,
  servicesValidity,
  selectedHeader,
  setSelectedHeader,
  getServiceLineWithCurrentAccountingData,
  serviceGroup,
  setIsDirty,
  currentAccountingTemplate,
  currentAccountingInCurrentOffer,
  setCurrentAccountingServices,
  modalOpen,
  setOpenModal,
  updateServiceCategoryData,
}: Props) {
  const [showServiceDetails, setShowServiceDetails] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { edit } = useParams<{ edit: string }>();
  const { updateServiceGroups, updateTaxObjects, taxObjects } =
    useContext(ServicesContext);

  const { data: currentOffer, status: loadingStatus } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  if (!currentOffer) {
    throw new Error("No active offer");
  }
  const initialCurrentOffer = useRef(currentOffer);
  const routing = useRoutingForOffer(location.pathname, currentOffer);

  const getTaxObjects = (newTaxObjects?: TaxObjectService[]) => {
    switch (serviceGroup) {
      case SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY:
        return newTaxObjects
          ? { smallRecurringMonthly: newTaxObjects }
          : taxObjects.smallRecurringMonthly;
      case SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY_EF:
        return newTaxObjects
          ? { smallRecurringMonthlyEF: newTaxObjects }
          : taxObjects.smallRecurringMonthlyEF;
      case SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY:
        return newTaxObjects
          ? { smallRecurringQuarterly: newTaxObjects }
          : taxObjects.smallRecurringQuarterly;
      case SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY_EF:
        return newTaxObjects
          ? { smallRecurringQuarterlyEF: newTaxObjects }
          : taxObjects.smallRecurringQuarterlyEF;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    const updatedTaxObjects = getTaxObjects() as TaxObjectService[];

    if (updatedTaxObjects) {
      dispatch(
        updateCurrentOffer({
          tax_objects: updatedTaxObjects,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, taxObjects]);

  const updateContext = () => {
    const taxObjectsUpdate = getTaxObjects(
      currentOffer.tax_objects
    ) as TaxObjects;

    updateTaxObjects(taxObjectsUpdate);

    const serviceGroupToUpdate =
      getServiceLineWithCurrentAccountingData().service_areas[0].service_lines[0].service_groups.find(
        (group) => group.name === serviceGroup
      );
    if (serviceGroupToUpdate) {
      updateServiceGroups(serviceGroupToUpdate);
    }
  };

  const handleSave = async () => {
    dispatch(updateCurrentOffer(getServiceLineWithCurrentAccountingData()));
    dispatch(resetPriceAdjustmentAndDiscount());
    await dispatch(sendOfferForCalculation());

    updateContext();

    setIsDirty(false);
    setPendingChangesModalOpen(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const handleDiscard = () => {
    dispatch(updateCurrentOffer(initialCurrentOffer.current));
    setIsDirty(false);
    setPendingChangesModalOpen(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const allIncomeTaxServices = Service.getIncomeTaxServices(
    currentAccountingTemplate.services
  );

  const { saveFormData } = useSaveTaxObjectsData({
    serviceCategoryInCurrentOffer: currentAccountingInCurrentOffer,
    formData: currentAccountingData,
    serviceCategoryServices: currentAccountingTemplate.services,
    setIsDirty,
    setCurrentAccountingServices,
  });

  const backBtnLabel = `TAX_OBJECTS.BUTTON.GO_BACK_TO_${serviceGroup.toUpperCase()}`;

  const handleConfirm = async () => {
    updateServiceCategoryData();
    dispatch(resetPriceAdjustmentAndDiscount());
    await dispatch(sendOfferForCalculation());
    updateContext();
    setOpenModal(false);
    navigate(OfferRouteHelper.getSummary());
  };

  return (
    <>
      <ServiceFormWithTaxObjects
        modalOpen={pendingChangesModalOpen}
        setOpenModal={setPendingChangesModalOpen}
        showServiceDetails={showServiceDetails}
        setShowServiceDetails={setShowServiceDetails}
        handleSave={handleSave}
        handleDiscard={handleDiscard}
        isValid={
          !!currentAccountingServices.length &&
          !!currentAccountingData.frequency
        }
        showTaxObjects={showTaxObjects}
        setShowTaxObjects={setShowTaxObjects}
        renderServiceDetailsForm={() => (
          <CurrentAccountingServiceDetailsForm
            groupedServices={groupedServices}
            servicesValidity={servicesValidity}
            selectedHeader={selectedHeader}
            setSelectedHeader={setSelectedHeader}
            currentAccountingServices={currentAccountingServices}
            setCurrentAccountingServices={setCurrentAccountingServices}
            currentAccountingInCurrentOffer={currentAccountingInCurrentOffer}
            serviceGroup={serviceGroup}
            setIsDirty={setIsDirty}
          />
        )}
        incomeTaxServices={allIncomeTaxServices}
        saveFormData={saveFormData}
        label={backBtnLabel}
      />

      {modalOpen && (
        <ReviewChangesOnSaveModal
          isOpen={modalOpen}
          onDismiss={() => setOpenModal(false)}
          onSave={handleConfirm}
          onCancel={() => setOpenModal(false)}
          isLoading={loadingStatus === LoadingStatusEnum.PENDING}
        />
      )}
    </>
  );
}
