import { Warning16Filled } from "@fluentui/react-icons";
import { TooltipInfoIcon } from "components/icon";
import { useTranslation } from "hooks/use-translate";
import { CompanyInformation } from "models/offer/Company";
import { Col } from "react-bootstrap";
import "./CompanyInfoDetailsComponent.scss";

type Props = {
  companyInfo?: CompanyInformation;
};

export default function CompanyInfoDetailsComponent({ companyInfo }: Props) {
  const { translate } = useTranslation();

  if (!companyInfo) {
    return <div>{translate("CUSTOMER_PAGE.NO_COMPANY_DATA")}</div>;
  }

  return (
    <>
      <Col lg="auto">
        {translate("ORG_NUM")}:{" "}
        <span className="fw-semibold">{companyInfo?.org_number}</span>
      </Col>
      <Col lg="auto">
        {translate("CUSTOMER_NUM")}:{" "}
        <span className="fw-semibold">
          {companyInfo?.customer_number || (
            <TooltipInfoIcon
              tooltip={{ content: "MISSING_CUSTOMER_NUMBER" }}
              tooltipIcon={<Warning16Filled className="text-color-red" />}
            />
          )}
        </span>
      </Col>
      <Col lg="auto">
        {translate("ADDRESS")}:{" "}
        <span className="fw-semibold">{companyInfo?.address}</span>
      </Col>
      <Col lg="auto">
        {translate("ZIP")}:{" "}
        <span className="fw-semibold">{companyInfo?.zip}</span>
      </Col>
      <Col lg="auto">
        {translate("CITY")}:{" "}
        <span className="fw-semibold">{companyInfo?.city}</span>
      </Col>
    </>
  );
}
