import { plainToClass } from "class-transformer";
import { ACCOUNTING_SERVICE_LINE } from "constants/servicesConsts";
import { isPlaceholderEmail } from "libs/generate-placeholder-email";
import { useServiceMatrix } from "libs/service-matrix";
import { Deal } from "models/deals/deal";
import { Product } from "models/deals/product";
import { DealCustomer } from "models/offer/Customer";
import { SigningMethod, DealContact } from "models/offer/DealContact";
import { Service, ServiceCategory } from "models/offer/ServiceLine";
import { TaxObjectDetails } from "models/offer/TaxObject";
import { TaxObjectService } from "models/offer/TaxObjectService";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "state";
import {
  createOrUpdateCompanyContact,
  createOrUpdateCustomer,
} from "state/offer/companyThunks";
import {
  addOfferSelectedServiceLine,
  addServiceLineGroup,
  setSelectedCustomerSource,
  updateCurrentOffer,
  updateOfferContact,
  updateOfferCustomer,
} from "state/offer/offersSlice";
import { updateCustomerIncomeTaxData } from "state/offer/offersThunks";
import { searchContact } from "state/sales/actions";
import { useAppDispatch } from "state/use-app-redux";
import { OfferRouteHelper } from "views/createNew/offer/routes/offerRoutes";

export const usePogOffer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { currentUser } = useSelector((state: RootState) => state.users);
  const { data: offerTemplate } = useSelector(
    (state: RootState) => state.offers.offerTemplate
  );
  const { data: products } = useSelector(
    (state: RootState) => state.sales.products
  );

  const [createPogOfferLoading, setCreatePogOfferLoading] = useState(false);

  const { GetAllServiceGroupsFlattened, GetAllServiceLinesFlattened } =
    useServiceMatrix();

  const serviceGroups = GetAllServiceGroupsFlattened(offerTemplate);
  const serviceLines = GetAllServiceLinesFlattened(offerTemplate);

  const getMappedDealProducts = (deal: Deal) => {
    return deal.productIds.map(
      (productId) =>
        products
          ?.find((product: Product) => product.id === productId)
          ?.description?.toLowerCase() ?? ""
    );
  };

  const getServiceCategoryWithDefaultServices = (
    serviceCategory: ServiceCategory
  ) => {
    const initialServices = serviceCategory.services
      .filter((service) => service.input_form_type_state)
      .map((service) => ({ ...service, units: 1 }));

    return { ...serviceCategory, services: initialServices };
  };

  const createIncomeTaxObjectServices = (
    services: Service[],
    customer: DealCustomer
  ) => {
    const incomeTaxServices = services.filter(
      (service) => service.pog_income_tax
    );
    if (incomeTaxServices.length > 0) {
      const companyTaxObject = TaxObjectDetails.getTaxObjectFromCustomerData(
        customer,
        currentUser
      );
      const newTaxObjectsServices: TaxObjectService[] = incomeTaxServices.map(
        (service) => ({
          customer_tax_object: companyTaxObject,
          service_matrix_id: service.service_matrix_id,
        })
      );
      dispatch(
        updateCurrentOffer({
          tax_objects: newTaxObjectsServices ?? [],
        })
      );
      dispatch(
        updateCustomerIncomeTaxData({
          taxDetailsFormData: companyTaxObject,
          newTaxObjectsServices,
        })
      );
    }
  };

  const addServiceLinesAndGroupsToPogOffer = (
    offerDealCustomer: DealCustomer,
    deal: Deal
  ) => {
    const accountingServiceLine = serviceLines.find(
      (serviceLine) => serviceLine.name === ACCOUNTING_SERVICE_LINE
    );
    if (accountingServiceLine) {
      dispatch(addOfferSelectedServiceLine(accountingServiceLine));
    }

    const dealServiceGroups = serviceGroups.filter((group) =>
      getMappedDealProducts(deal).includes(group.name.toLowerCase())
    );

    dealServiceGroups.forEach((serviceGroup) => {
      const groupCategories = serviceGroup.service_categories
        .filter((category) => category.name)
        .map((category) => getServiceCategoryWithDefaultServices(category));

      if (groupCategories.length > 0) {
        const allServices = groupCategories.flatMap((sc) => sc.services);

        createIncomeTaxObjectServices(allServices, offerDealCustomer);

        const newGroup = {
          name: serviceGroup.name,
          service_categories: groupCategories,
          sorting_number: serviceGroup.sorting_number,
          skip_price_calculation: serviceGroup.skip_price_calculation,
        };
        dispatch(addServiceLineGroup(newGroup));
      }
    });
  };

  const createOrUpdateHSContactInPogOffer = async (deal: Deal) => {
    if (!deal.contact.email) {
      return;
    }
    // search the contact by deal's contact email
    const contacts = await dispatch(searchContact(deal.contact.email));
    const contactFound = contacts?.[0];

    const contactData = {
      is_signer: false,
      signing_method: isPlaceholderEmail(deal.contact.email)
        ? SigningMethod.PHYSICAL
        : SigningMethod.BANKID,
      contact: {
        first_name: deal.contact.firstname,
        last_name: deal.contact.lastname,
        social_security_number: contactFound?.social_security_number ?? "",
        mobile_phone: deal.contact.mobilephone,
        phone_number: deal.contact.phone,
        email: deal.contact.email,
        changed_by_username: currentUser.email,
        created_by_username: currentUser.email,
        id: "",
      },
    };

    // create or update pog contact with deal's contact
    const pogContact = await dispatch(
      createOrUpdateCompanyContact({
        contactData: contactData.contact,
        orgNum: deal.organisationsnummer,
      })
    ).unwrap();

    if (pogContact) {
      const refreshedDealContact = new DealContact({
        ...contactData,
        is_signer: !!pogContact.social_security_number,
        contact: pogContact,
      });

      // refresh the contact with new newest data
      dispatch(updateOfferContact(refreshedDealContact));
    }
  };

  const startCreatingPogOffer = async (
    currentDeal: Deal,
    customer: DealCustomer
  ) => {
    const deal = plainToClass(Deal, currentDeal);
    setCreatePogOfferLoading(true);

    try {
      if (customer?.org_number) {
        const customerHasAddressCityAndZip =
          !!customer.postal_town &&
          !!customer.postal_code &&
          !!customer.address;

        addServiceLinesAndGroupsToPogOffer(customer, deal);
        await createOrUpdateHSContactInPogOffer(deal);

        if (deal.id) {
          dispatch(updateCurrentOffer({ hubspot_deal_id: deal.id }));
        }

        dispatch(setSelectedCustomerSource(deal.deal_source));

        if (customerHasAddressCityAndZip) {
          const tempDealCustomerUpdatedByUser = {
            created_by_username:
              customer.created_by_username ?? currentUser.email,
            changed_by_username: currentUser.email,
          };

          await dispatch(
            createOrUpdateCustomer(
              plainToClass(DealCustomer, {
                ...customer,
                ...tempDealCustomerUpdatedByUser,
              })
            )
          );

          navigate(OfferRouteHelper.getContactInfo());
        } else {
          navigate(OfferRouteHelper.getRegisterCompany());
        }

        setCreatePogOfferLoading(false);
      }
    } catch (e) {
      if (deal) {
        dispatch(
          updateCurrentOffer({ hubspot_deal_id: deal.id, new_customer: true })
        );

        dispatch(
          updateOfferCustomer(
            plainToClass(DealCustomer, {
              ...customer,
              changed_by_username: currentUser.email,
              legal_form: deal.company ? "ab" : "pp",
            })
          )
        );

        dispatch(setSelectedCustomerSource(deal.deal_source));
      }

      setCreatePogOfferLoading(false);
      navigate(OfferRouteHelper.getRegisterCompany());
    }
  };

  return {
    startCreatingPogOffer,
    addServiceLinesAndGroupsToPogOffer,
    createIncomeTaxObjectServices,
    createPogOfferLoading,
    getServiceCategoryWithDefaultServices,
    createOrUpdateHSContactInPogOffer,
  };
};
