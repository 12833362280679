import { createAsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import { CompanyAPI } from "api/company";
import { authMethod } from "auth";
import {
  getLatestKYC,
  isKYCDataExpiredForCustomer,
} from "models/offer/kyc";
import { FailedKYCCustomer } from "models/offer/FailedKYCCustomer";
import { KycAPI } from "api/kyc";
import { appendError, appendToastMessage } from "../notifications";
import { setFailedKycData } from "./kycSlice";
import { RootState } from "..";

export const fetchKycCache = createAsyncThunk(
  "offers/fetchingKycCacheData",
  async (orgNum: string) => {
    const token = await authMethod.getStoredAccessToken();
    const response = await CompanyAPI.fetchExistingKycDataForCustomer(
      token,
      orgNum
    );
    return response;
  }
);

type KycQueryData = {
  orgNum: string;
  contactPersonSsn: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  isLimitedCompany: boolean;
};

export const queryKycData = createAsyncThunk(
  "offers/queryKycData",
  async (params: KycQueryData) => {
    const token = await authMethod.getStoredAccessToken();
    const response = await CompanyAPI.queryCompanyKYC(
      token,
      params.orgNum,
      params.contactPersonSsn,
      params.contactPersonFirstName,
      params.contactPersonLastName,
      params.isLimitedCompany
    );
    return response;
  }
);

export const acquireKYCdata = createAsyncThunk(
  "offers/aquireKYCforCustomer",
  async (orgNum: string, { dispatch, getState }) => {
    dispatch(fetchKycCache(orgNum))
      .then(unwrapResult)
      .then((payload) => {
        if (payload.length) {
          const latestKyc = getLatestKYC(payload);
          if (latestKyc && isKYCDataExpiredForCustomer(latestKyc)) {
            queryNewKYCData();
          }
        } else {
          queryNewKYCData();
        }

        function queryNewKYCData() {
          const { offers } = getState() as RootState;

          if (!offers.currentOffer.data) {
            throw new Error("No active offer");
          }

          const signer = offers.currentOffer.data.contacts.find(
            (c) => c.is_signer
          );
          if (!signer) {
            throw new Error("No signer available on the offer");
          }

          const { customer } = offers.currentOffer.data;
          if (signer && customer) {
            const queryData: KycQueryData = {
              orgNum: customer.org_number,
              contactPersonSsn: signer.contact.social_security_number,
              contactPersonFirstName: signer.contact.first_name.replace(
                /\s/g,
                "+"
              ),
              contactPersonLastName: signer.contact.last_name.replace(
                /\s/g,
                "+"
              ),
              isLimitedCompany: customer.legal_form === "ab",
            };
            dispatch(queryKycData(queryData)).catch((reason) => {
              dispatch(
                appendError("FAILED_TO_FETCH_KYC", {
                  cause: "Failed to query KYC data for customer",
                } as unknown as Error)
              );
            });
          }
        }
      })
      .catch((reason) => {
        dispatch(
          appendError("FAILED_TO_FETCH_KYC", {
            cause:
              "Failed to fetch existing KYC data, will try to acquire new data",
          } as unknown as Error)
        );
      });
  }
);

export const getFailedKycData = createAsyncThunk(
  "kyc/getFailedKyc",
  async (filterByUser: string | undefined, { dispatch, rejectWithValue }) => {
    const token = await authMethod.getStoredAccessToken();
    try {
      const response = await KycAPI.getFailedKYCs(token);
      if (filterByUser) {
        const filteredResponse = response.filter(
          (kycData: FailedKYCCustomer) =>
            kycData.created_by && kycData.created_by === filterByUser
        );
        return filteredResponse;
      }
      return response;
    } catch (error) {
      dispatch(
        appendError("KYC_ADMINISTRATION.FAILED_TO_FETCH_KYCS", error as Error)
      );
      return rejectWithValue(error);
    }
  }
);

export const manuallyApproveFailedKYC = createAsyncThunk(
  "kyc/manuallyApproveFailedKyc",
  async (
    {
      envelopeId,
      comment,
      customerName,
    }: { envelopeId: string; comment: string; customerName: string },
    { dispatch, getState }
  ) => {
    const { kyc } = getState() as RootState;
    const token = await authMethod.getStoredAccessToken();
    try {
      await KycAPI.manuallyApproveFailedKYC(token, envelopeId, comment);
      const failedKYCdata = kyc.failedKyc.data;
      const filteredData = failedKYCdata.filter(
        (customer) => customer.envelope_id !== envelopeId
      );
      dispatch(setFailedKycData(filteredData));
      dispatch(
        appendToastMessage(
          "KYC_ADMINISTRATION.SUCCESS_MANUALLY_APPROVE_KYC_FORM",
          "success",
          [customerName]
        )
      );
    } catch (error) {
      dispatch(
        appendError(
          "KYC_ADMINISTRATION.FAILED_TO_MANUALLY_APPROVE_KYC",
          error as Error
        )
      );
    }
  }
);
