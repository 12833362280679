import { getStageDeals } from "helpers/getStageDeal";
import { Deal } from "models/deals/deal";
import { CustomerDealSlim } from "models/offer/CustomerDealSlim";
import { Offer } from "models/offer/SavedOffers";

export function compareDealsByChangedDate(
  order: "desc" | "asc",
  a?: Date,
  b?: Date
): number {
  return order === "desc"
    ? (b?.getTime() ?? 1) - (a?.getTime() ?? 1)
    : (a?.getTime() ?? 1) - (b?.getTime() ?? 1);
}

export function sortDealsByChangedDate(
  deals: CustomerDealSlim[],
  order: "desc" | "asc" = "desc"
) {
  return deals.sort((a, b) =>
    compareDealsByChangedDate(order, a.changed_date, b.changed_date)
  );
}

export function sortOffersByChangedDate(
  deals: Offer[],
  order: "desc" | "asc" = "desc"
) {
  return deals.sort((a, b) =>
    compareDealsByChangedDate(order, new Date(a.modified), new Date(b.modified))
  );
}

export function sortDealsByEnteredCurrentStageDate(deals: Deal[]) {
  const sortedDeals = deals.sort(
    (a, b) =>
      (b.entered_current_stage_date !== null
        ? new Date(b.entered_current_stage_date).getTime()
        : 1) -
      (a.entered_current_stage_date !== null
        ? new Date(a.entered_current_stage_date).getTime()
        : 1)
  );
  const filterStageDeals = getStageDeals(sortedDeals);
  return filterStageDeals;
}
