import AppConfig from "app-config";
import { plainToClass } from "class-transformer";
import { CustomerDeal } from "models/offer/CustomerDeal";
import { CustomerDealServiceAreas } from "models/offer/CustomerDealServiceAreas";
import { Offer } from "models/offer/SavedOffers";
import { plainToPartialClass } from "models/utils";
import { getAuthorizationHeader } from "./libs/auth-header";
import { ResponseError } from "./libs/responseError";

export type PdfUploadDealData = {
  url: string;
  fields: {
    key: string;
    "x-amz-algorithm": string;
    "x-amz-credential": string;
    "x-amz-date": string;
    "x-amz-security-token": string;
    policy: string;
    "x-amz-signature": string;
  };
};

export class OffersAPI {
  static async fetchCustomerOffersByOrgNum(
    token: string,
    customerOrgNum: string
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/offers/${customerOrgNum}`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = (await response.json()) as Offer[];
    return plainToClass(Offer, json, { exposeDefaultValues: true });
  }

  static async fetchOfferTemplate(token: string): Promise<CustomerDeal> {
    const response = await fetch(`${AppConfig.API_URL}/services`, {
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = await response.json();
    return plainToClass(CustomerDeal, json, { exposeDefaultValues: true });
  }

  static async sendOfferForCalculation(
    token: string,
    offer: CustomerDeal
  ): Promise<CustomerDeal> {
    // TODO: Remove this line when the API is ready
    const { contract_texts, ...filteredOffer } = offer;
    const response = await fetch(
      `${AppConfig.API_URL}/deals/create_or_update`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...filteredOffer,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = await response.json();
    return plainToClass(CustomerDeal, json, { exposeDefaultValues: true });
  }

  static async patchOffer(
    token: string,
    orgNr: string,
    dealId: string,
    data: Partial<CustomerDeal>
  ): Promise<Partial<CustomerDeal>> {
    const response = await fetch(
      `${AppConfig.API_URL}/deals/patch/${orgNr}/deal/${dealId}`,
      {
        method: "PATCH",
        headers: {
          ...getAuthorizationHeader(token),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      throw ResponseError(response);
    }

    const json = (await response.json()) as Partial<CustomerDeal>;
    const responseToClass = plainToPartialClass(CustomerDeal, json);
    return responseToClass;
  }

  static async fetchDealPdfUrl(token: string, dealId: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/deals/create_pdf/${dealId}`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return response.text();
  }

  static async fetchKYCUploadURL(
    token: string,
    orgNumber: string,
    dealId: string
  ): Promise<PdfUploadDealData> {
    const response = await fetch(
      `${AppConfig.API_URL}/deals/create_upload_kyc_url/${orgNumber}/${dealId}`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return response.json();
  }

  static async fetchDealUploadUrl(
    token: string,
    id: string
  ): Promise<PdfUploadDealData> {
    const response = await fetch(
      `${AppConfig.API_URL}/deals/create_upload_deal_url/${id}`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return response.json();
  }

  static async fetchDealAttachmentUploadUrl(
    token: string,
    dealId: string
  ): Promise<PdfUploadDealData> {
    const response = await fetch(
      `${AppConfig.API_URL}/deals/create_upload_attachment_url/${dealId}`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return response.json();
  }

  static async uploadFileToAws(uploadData: PdfUploadDealData, file: File) {
    const data = new FormData();

    for (const [key, value] of Object.entries(uploadData.fields)) {
      data.append(key, value);
    }

    data.append("file", file, uploadData.fields.key);

    const response = await fetch(`${uploadData.url}`, {
      method: "POST",
      mode: "cors",
      body: data,
    });

    return response;
  }

  static async fetchCreatedDealsSlim(token: string, orgNumber: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/deals/${orgNumber}/slim`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = (await response.json()) as CustomerDeal[];
    return plainToClass(CustomerDeal, json, { exposeDefaultValues: true });
  }

  static async fetchCreatedDealsServiceAreas(token: string, orgNumber: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/deals/${orgNumber}/service_areas`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = (await response.json()) as CustomerDealServiceAreas[];
    return plainToClass(CustomerDealServiceAreas, json, {
      exposeDefaultValues: true,
    });
  }

  static async fetchCreatedDeal(
    token: string,
    orgNumber: string,
    dealId: string
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/deals/${orgNumber}/deal/${dealId}`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = (await response.json()) as CustomerDeal;
    return plainToClass(CustomerDeal, json, { exposeDefaultValues: true });
  }

  static async copyDeal(token: string, dealId: string) {
    const response = await fetch(`${AppConfig.API_URL}/deals/copy/${dealId}`, {
      method: "POST",
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }
    const json = (await response.json()) as CustomerDeal;
    return json;
  }

  static async deleteDeal(
    token: string,
    dealId: string,
    orgNum: string,
    data: Partial<CustomerDeal>
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/deals/delete/${orgNum}/deal/${dealId}`,
      {
        method: "DELETE",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return response.json();
  }

  static async sendDealForSigning(
    token: string,
    dealId: string,
    lcoUserEmail?: string
  ) {
    let body: any = { deal_id: dealId };

    if (lcoUserEmail) {
      body = { ...body, lco_user_email: lcoUserEmail };
    }

    const response = await fetch(`${AppConfig.API_URL}/deals/sign`, {
      method: "POST",
      headers: {
        ...getAuthorizationHeader(token),
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw Error(await response.json());
    }

    const json = (await response.json()) as CustomerDeal;
    return plainToClass(CustomerDeal, json, { exposeDefaultValues: true });
  }

  static async deleteDealAttachment(token: string, dealId: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/deals/delete_deal_attachment/${dealId}`,
      {
        method: "DELETE",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return response.json();
  }

  static async fetchDealAttachment(token: string, dealId: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/deals/get_deal_attachment_url/${dealId}`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return (await response.json()) as string;
  }
}
