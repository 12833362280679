import { Type } from "class-transformer";
import { User } from "models/user";
import { DealCustomer } from "./Customer";
import { DealContact } from "./DealContact";
import { ServiceArea } from "./ServiceLine";

export class CustomerDealSlim {
  id?: string;

  /**
   * State of the deal
   */
  state:
    | "offer"
    | "contract"
    | "contract_sent"
    | "contract_signed"
    | "contract_rejected"
    | "contract_expired"
    | "deleted";

  /**
   * The selected services for the deal
   */
  service_areas?: ServiceArea[];

  /**
   * The deal's estimated cost
   */
  estimated_cost?: number;

  /**
   * The deal's final price
   */
  final_price?: number;

  /**
   * Contacts
   */
  contacts: DealContact[];

  /**
   * Customer information
   */
  @Type(() => DealCustomer)
  customer?: DealCustomer;

  /**
   * Determine if the customer on the deal is a completely new customer
   */
  new_customer: boolean;

  /**
   * HubSpot deal ID
   */
  hubspot_deal_id?: string;

  /**
   * Email of the employee that created the deal
   */
  changed_by_username?: string;

  /**
   * Email of the employee that last changed the deal
   */
  created_by_username?: string;

  /**
   * Date of the deal creation
   */
  @Type(() => Date)
  created_date?: Date;

  /**
   * Date of the deal last change
   */
  @Type(() => Date)
  changed_date?: Date;

  /**
   * Employee sales manager for the offer
   */
  sales_manager: string;

  /**
   * Hubspot deal source
   */
  deal_source?: string;

  static fromUser(user: User): CustomerDealSlim {
    return {
      changed_by_username: user.email,
      created_by_username: user.email,
      created_date: new Date(),
      changed_date: new Date(),
      state: "offer",
      new_customer: false,
      sales_manager: user.email,
      contacts: [],
    };
  }
}
