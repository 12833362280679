import { Info20Regular } from "@fluentui/react-icons";

import { SolidAccordion } from "components/accordion/solidAccordion";
import { useTranslation } from "hooks/use-translate";
import { numberAsSwedishCurrency } from "libs/number-format";
import { LoadingStatus } from "state";
import FinalPrice from "./FinalPrice";

type FinalPriceSectionProps = {
  title: string;
  loadingStatus: LoadingStatus;
  shouldOpen: boolean;
  lowestPossiblePrice?: number;
};

export default function FinalPriceSection({
  title,
  shouldOpen,
  lowestPossiblePrice,
}: FinalPriceSectionProps) {
  const { translate } = useTranslation();

  const subtitles = [
    translate("PRICE"),
    translate("SUGGESTED_PRICE"),
    translate("DISCOUNT"),
    translate("FINAL_PRICE"),
  ];

  const showLowestPossiblePrice =
    !!lowestPossiblePrice && lowestPossiblePrice > 0;

  return (
    <SolidAccordion
      title={title}
      subtitles={subtitles}
      limitSubtitles={subtitles.length}
      shouldOpen={shouldOpen}
    >
      <>
        {showLowestPossiblePrice && (
          <div className="d-flex align-items-center mb-md ">
            <Info20Regular className="color-blue mr-sm" />
            <span className="text-gray">
              {translate("SUMMARY_PAGE.LOWEST_POSSIBLE_PRICE", [
                numberAsSwedishCurrency(lowestPossiblePrice),
              ])}
            </span>
          </div>
        )}
        <FinalPrice />
      </>
    </SolidAccordion>
  );
}